import { Module } from "./../../classes/mvc/Module";
import { ControllerAutocomplete } from "./ControllerAutocomplete";
import { ModelAutocomplete } from "./ModelAutocomplete";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";
import { Globals } from "../../classes/Globals";

@ModuleConfig({
    moduleName: "Autocomplete",
    style: "autocomplete.scss"
})
export class ModuleAutocomplete extends Module<ModelAutocomplete> {

    public constructor(configuration: Object) {
        super("Autocomplete", configuration);

        var runMultipleInstances: boolean = this.getConfig("run_multiple_instances") == true;
        if (!runMultipleInstances) {

            var searchInput = jQuery("#search_input");
            var outputContainer = jQuery("#search_output");
            var submitButton = jQuery("#search_execute");

            var controller = new ControllerAutocomplete(this.getName(), this.getAccessID(), searchInput, outputContainer, submitButton);
            this.addController(controller);
            this.registerCallableMethod(controller, "set_keyword", controller.setKeyword);

        } else {

            var searchContainers = jQuery("[" + Globals.ATTRIBUTE_PREFIX + "search-container]");
            for (let i = 0; i < searchContainers.length; i++) {

                var searchInput = jQuery(searchContainers[i]).find("[" + Globals.ATTRIBUTE_PREFIX + "search-input]");
                var outputContainer = jQuery(searchContainers[i]).find("[" + Globals.ATTRIBUTE_PREFIX + "search-output]");
                var submitButton = jQuery(searchContainers[i]).find("[" + Globals.ATTRIBUTE_PREFIX + "search-execute]");

                var controller = new ControllerAutocomplete(this.getName(), this.getAccessID(), searchInput, outputContainer, submitButton);
                this.addController(controller);
                this.registerCallableMethod(controller, "set_keyword", controller.setKeyword);
            }

        }
    }

    public run(): void {
        this.runAllControllers();
    }

    public onControllersInitialized(): void {

    }

}