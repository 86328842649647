import { Pair } from "./../../libs/Pair";
import { Arrays } from "../../libs/Arrays";

export class Model{

    private static CURRENT_ID:number = 0;

    private variables:Array<Pair<number, any>>;

    public constructor (){
        this.variables = new Array();
    }

    public handlebarsExport ( id:number ):Object{
        let result:Object = {};

        for (let i = 0; i < this.variables.length; i++) {
            if ( this.variables[i].getKey() == id ){

               result = this.variables[i].getValue();
            }
        }

        return result;
    }

    public clear ( id:number ):void {
        for (let i = 0; i < this.variables.length; i++) {
            if ( this.variables[i].getKey() == id ){
                this.variables = Arrays.removeByIndex( this.variables, i );
            }
        }
    }

    public new ():number{
        Model.CURRENT_ID++;

        this.variables.push( new Pair( Model.CURRENT_ID, {} ) );

        return Model.CURRENT_ID;
    }

    public add ( id:number, key:string, value:any ):void {
        for (let i = 0; i < this.variables.length; i++) {
            if ( this.variables[i].getKey() == id ){
                
                var keys:Array<string> = Object.keys( this.variables[i].getValue() );
                if ( keys.indexOf( key ) < 0 ){
                    this.variables[i].getValue()[ key ] = value;
                }
            }
        }
    }
}