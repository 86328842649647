export abstract class Item {

    private itemvalid:boolean;

    public constructor (){
        this.itemvalid = true;
    }

    public setValid ( itemvalid:boolean ):void{
        this.itemvalid = itemvalid;
    }

    public isItemValid ():boolean{
        return this.itemvalid;
    }
}