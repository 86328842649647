import { ModelBasket } from "./ModelBasket";
import { Controller } from "../../classes/mvc/Controller";
import { modules } from "../../main";

import jQuery = require("jquery");


import "jquery-ui";

declare let currentPageSite: any;
export class ControllerBasket extends Controller<ModelBasket>{

    private setup_dialog_show: any = false;
    private setup_dialog_closetime: any = 3000;
    private cart_button_text_in_cart: any;
    private cart_button_text_add_cart: any;
    private add_class_to_cart_button: any;
    private mode: any;
    private do_refresh_price: boolean;

    public constructor(accessName: string, accessID: number) {
        super(new ModelBasket(), accessName, accessID);
    }

    public initGlobals(): void {
        let dialogShow = this.getModule().getConfig( "dialog_show" );
        let dialogCloseTime = this.getModule().getConfig( "dialog_closetime" );
        let inCartText = this.getModule().getLabel( "cart_button_text_in_cart" );
        let addCartText = this.getModule().getLabel( "cart_button_text_add_cart" );
        let addClass = this.getModule().getConfig( "add_class_to_cart_button" );
        let mode = this.getModule().getConfig( "mode" );
        let refresh_price = this.getModule().getConfig( "refresh_price" );

        if ( dialogShow ) { this.setup_dialog_show = dialogShow }
        if ( dialogCloseTime ) { this.setup_dialog_closetime = dialogCloseTime }
        if ( inCartText ) { this.cart_button_text_in_cart = inCartText }
        if ( addCartText ) { this.cart_button_text_add_cart = addCartText } 
        if ( addClass ) { this.add_class_to_cart_button = addClass }
        if ( mode ) { this.mode = mode }
        if ( refresh_price ) { this.do_refresh_price = refresh_price }
    }

    public run(): void {

        let setup_dialog_show = this.setup_dialog_show;
        let setup_dialog_closetime = this.setup_dialog_closetime;
        let cart_button_text_in_cart = this.cart_button_text_in_cart;
        let cart_button_text_add_cart = this.cart_button_text_add_cart;
        let class_to_add = this.add_class_to_cart_button;
        let mode = this.mode;
        let refresh_price = this.do_refresh_price;

        $(document.body).on('click', '.to_cart_button', function () {
            let art_id = this.value;  // Article-ID
            let element_quantity;
            
            switch (mode) {
                case "datalist":
                    element_quantity = $('[data-ez-datalist-artid="' + art_id + '"]');
                    break;
            
                default:
                    element_quantity = $('.input-group-field');
                    break;
            }

            let quantity = (element_quantity.val());

            let url = '/4DCGI/ezshop?action=JSON_addToKorb' + "&sKontaktID=" + modules.getKontaktID() + "&sKontaktKEY=" + modules.getKontaktKey() + "&sTICKCOUNT=" + modules.getTickcount() + `&artid=${art_id}&menge=${quantity}`;
            let result: any = {};
            /**
		    *
		    * Execute the ajax request
		    */
            jQuery.ajax({
                url: url,
                type: "GET",
                async: false,
                dataType: "json",
                success: function (response: Object) {
                    result = response;
                    if (refresh_price) {
                        let refreshPriceContainer = document.querySelector("[data-ez-art-price-refresh='"+ art_id +"']");
                        let refreshAvailContainer = document.querySelector("[data-ez-art-avail-refresh='"+ art_id +"']");
                        let articleAvailability = result.warenkorb_availability;
                        let newPriceNumber = result.warenkorb_price;
                        let newPriceString = newPriceNumber.toString();

                        newPriceString = newPriceString.replace('.', ',');

                        let output = "<span class='actualprice'>";
                                output += newPriceString + " €";
                            output += "</span>";

                        refreshPriceContainer.innerHTML = output;
                        refreshAvailContainer.innerHTML = articleAvailability;
                        // let newPrice = result.warenkorb_price;
                        // let articleAvailability = result.warenkorb_availability;
                        // let refreshAvailContainer = document.querySelector("[data-ez-art-avail-refresh='"+ art_id +"']");
                        // let refreshPriceContainer = document.querySelector("[data-ez-art-price-refresh='"+ art_id +"']");
                        // let refreshPriceChilds = refreshPriceContainer.children;

                        // if (refreshPriceChilds.length == 1) {
                        //     let baseprice = refreshPriceChilds[0];
                        //     let oldPrice = parseFloat(baseprice.getAttribute('data-ez-art-price'));

                        //     if (newPrice < oldPrice) {
                        //         baseprice.className = "baseprice";

                        //         let spanBP = "<span class='specialprice'>";
                        //             spanBP += newPrice + " €";
                        //         spanBP += "</span>";

                        //         refreshPriceContainer.innerHTML += spanBP;
                        //         refreshAvailContainer.innerHTML = articleAvailability;
                        //     }
                        // } else if (refreshPriceChilds.length == 2) {

                        // }
                    }
                    
                }.bind(this)
            });

            url = `/filesync/warenkorb_preview`;

            jQuery.ajax({
                url: url,
                type: "GET",
                async: false,
                dataType: "html",
                success: function (response: Object) {
                    result = response;
                }.bind(this)
            });

            $("#refresh").html(result);
            if (quantity==="0")
            {
                $(this).html(cart_button_text_add_cart);
                $(this).removeClass(class_to_add);
                $(".dialog_warenkorb_msg").html("Der Artikel entfernt.");
                if ( currentPageSite == "warenkorb.htm" ) {
                    window.location.reload();
                }
            }
            else
            {
                $(this).html(cart_button_text_in_cart);
                $(this).addClass(class_to_add);
                $(".dialog_warenkorb_msg").html("Der Artikel wurde in den Warenkorb gelegt.");
            }
            if (setup_dialog_show) {
                let dialog = $("#dialog_warenkorb");
                dialog.dialog({
                    modal: true,
                    open: function (event, ui) {
                        setTimeout("$('#dialog_warenkorb').dialog('close')", setup_dialog_closetime);
                    }
                });
            }
        });

        // Article to sortiment
        $(document.body).on('click', '.article_to_sortiment', function () {
            let art_nr = $(this).attr("data-ez-datalist-artnr") ;  // Article-ID

            let url = '/4DCGI/ezshop?action=meinsortiment_addarticle' + "&sKontaktID=" + modules.getKontaktID() + "&sKontaktKEY=" + modules.getKontaktKey() + "&sTICKCOUNT=" + modules.getTickcount() + `&artnr=${art_nr}&type=json`;
            let result: any = {};
            /**
		    *
		    * Execute the ajax request
		    */
            jQuery.ajax({
                url: url,
                type: "GET",
                async: false,
                dataType: "json",
                success: function (response: Object) {
                    result = response;
                }.bind(this)
            });

            
            if (result.status==="success") {
                let dialog = $("#dialog_meinsortiment");
                dialog.dialog({
                    modal: true,
                    open: function (event, ui) {
                        setTimeout("$('#dialog_meinsortiment').dialog('close')", setup_dialog_closetime);
                    }
                });
            }
        });
    }
}