import { Pair } from "./Pair";
import { OperatingSystem, UserAgent } from "./../classes/Globals";
import { OperatingSystems } from "./../libs/OperatingSystes";
import { UserAgents } from "./../libs/UserAgents";

export class KeyCode {

    private mapping:Array<Pair<Pair<OperatingSystem, UserAgent>, number>>;

    private operatingSystem:OperatingSystem;
    private userAgent:UserAgent;

    private constructor ( mapping:Array<Pair<Pair<OperatingSystem, UserAgent>, number>> ){
        this.mapping = mapping;

        this.operatingSystem = OperatingSystems.get();
        this .userAgent = UserAgents.getForKeyEvents();
    }

    public static create ( number:number, mapping:Array<Pair<Pair<OperatingSystem, UserAgent>, number>> = new Array() ):KeyCode{
        mapping.push( new Pair( new Pair(OperatingSystem.DEFAULT, UserAgent.DEFAULT), number ) );

        return new KeyCode( mapping );
    }

    public add ( operatingSystem:OperatingSystem, userAgent:UserAgent, keyCode:number ):void {
        this.mapping.push( new Pair( new Pair( operatingSystem, userAgent ), keyCode ) );
    }

    public get ():number{
        var result:number = null;

        for (let i = 0; i < this.mapping.length; i++) {
            var operatingSystem:OperatingSystem = this.mapping[i].getKey().getKey();
            var userAgent:UserAgent = this.mapping[i].getKey().getValue();

            if ( operatingSystem == this.operatingSystem && userAgent == this.userAgent ){
                result = this.mapping[i].getValue();
            }
        }
        
        if ( result == null ){
            for (let i = 0; i < this.mapping.length; i++) {
                var operatingSystem:OperatingSystem = this.mapping[i].getKey().getKey();
                var userAgent:UserAgent = this.mapping[i].getKey().getValue();
    
                if ( operatingSystem == OperatingSystem.DEFAULT && userAgent == this.userAgent ){
                    result = this.mapping[i].getValue();
                }
            }
        }
        if ( result == null ){
            for (let i = 0; i < this.mapping.length; i++) {
                var operatingSystem:OperatingSystem = this.mapping[i].getKey().getKey();
                var userAgent:UserAgent = this.mapping[i].getKey().getValue();

                if ( operatingSystem == OperatingSystem.DEFAULT && userAgent == UserAgent.DEFAULT ){
                    result = this.mapping[i].getValue();
                }
            }
        }

        return result;
    }
}