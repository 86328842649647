import { ModelCaptchaChecker } from "./ModelCaptchaChecker";
import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import { Pair } from "../../libs/Pair";
import { Globals } from "../../classes/Globals";

import randomstring = require("randomstring");

export class ControllerCaptchaChecker extends HTMLController<ModelCaptchaChecker> {

    private static CANVAS:HTMLCanvasElement;
    private static CANVAS_CONTEXT:CanvasRenderingContext2D;

    private static FONTS:Array<Pair<string, number>>;

    private code:string;
    private inputElement:JQuery<HTMLInputElement>;

    private valid:boolean;

    public constructor ( accessName:string, accessID:number, element:JQuery<HTMLElement>) {
        super( new ModelCaptchaChecker(), accessName, accessID, element );
    }

    public initGlobals ():void{
        ControllerCaptchaChecker.FONTS = new Array();
        ControllerCaptchaChecker.FONTS.push( new Pair( "Braggadocio", 30 ) );
        ControllerCaptchaChecker.FONTS.push( new Pair( "Rockwell Extra Bold", 38 ) );
        ControllerCaptchaChecker.FONTS.push( new Pair( "Alien League", 55 ) );
        ControllerCaptchaChecker.FONTS.push( new Pair( "Angelina", 65 ) );
        ControllerCaptchaChecker.FONTS.push( new Pair( "Trattatello", 58 ) );
        ControllerCaptchaChecker.FONTS.push( new Pair( "Courier New", 40 ) );
        ControllerCaptchaChecker.FONTS.push( new Pair( "Bradley Hand", 43 ) );

        ControllerCaptchaChecker.CANVAS = document.createElement( "canvas" ) as HTMLCanvasElement;

        ControllerCaptchaChecker.CANVAS_CONTEXT = ControllerCaptchaChecker.CANVAS.getContext("2d") as CanvasRenderingContext2D;
    }

    public run ():void{
        var font:Pair<string, number> = ControllerCaptchaChecker.FONTS[ Math.floor((Math.random() * ControllerCaptchaChecker.FONTS.length)) ];
        var code:string = randomstring.generate({
            length: 6,
            charset: "alphanumeric"
        });

        this.valid = false;
        this.code = code;

        this.inputElement = this.findIntputElement() as JQuery<HTMLInputElement>;

        if ( this.inputElement.length ){
            ControllerCaptchaChecker.CANVAS.width = 200;
            ControllerCaptchaChecker.CANVAS.height = font.getValue() + 10;

            ControllerCaptchaChecker.CANVAS_CONTEXT.clearRect(0, 0, ControllerCaptchaChecker.CANVAS.width, ControllerCaptchaChecker.CANVAS.height);
            ControllerCaptchaChecker.CANVAS_CONTEXT.font = font.getValue() + "px " + font.getKey();
            ControllerCaptchaChecker.CANVAS_CONTEXT.textBaseline = "middle"; 
            ControllerCaptchaChecker.CANVAS_CONTEXT.fillText(code , ( ControllerCaptchaChecker.CANVAS.width / 2 ) - ( ControllerCaptchaChecker.CANVAS_CONTEXT.measureText( code ).width / 2 ), ( font.getValue() + 10 ) / 2);

            var base64 = ControllerCaptchaChecker.CANVAS.toDataURL();
            var imageElement:JQuery<HTMLImageElement> = jQuery( "<img src='"+ base64 +"'>" );
            
            if ( !this.setOutputElement( imageElement ) ){
                imageElement.insertAfter( this.inputElement );
            }
        } else {
            this.getModule().error( Globals.MODULE_LOADING_ERROR + " kein Input Element (data-ez-input) gefunden wurde" );
        }
    }

    public isValid ():boolean{
        return this.code == this.inputElement.val();
    }

}