import { Module } from "../../classes/mvc/Module";
import { ModelDatalist } from "./ModelDatalist";
import { ControllerDatalist } from "./ControllerDatalist";

export class ModuleDatalist extends Module<ModelDatalist> {

    public constructor( configuration:Object ) {
        super( "Datalist", configuration );

        this.addController( new ControllerDatalist( this.getName(), this.getAccessID() ) );
    }

    public run(): void {
        this.runAllControllers();
    }

    public onControllersInitialized():void {

    }
}