import { Controller } from "./../../classes/mvc/Controller";
import { ModelNavigationSync } from "./ModelNavigationSync";
import jQuery = require( "jquery" );
import { AjaxRequest } from "../../libs/AjaxRequest";
import { Json } from "../../libs/Json";
import { Pair } from "../../libs/Pair";
import { Globals } from "../../classes/Globals";

enum Type {
	IMAGE = "image",
	LINKS = "links"
}

export class ControllerNavigationSync extends Controller<ModelNavigationSync>{

	private static SYNC_URL:string = null;

	private static OUTPUT: string = "nav_output_world";
	private static SYNCS: Array<Pair<string, any>> = new Array();


	/**
	 * 
	 * HTML Structure
	 */
	private static ELEMENT_COLUMN_CONTAINER: string = "";
	private static ELEMENT_COLUMN_CONTENT_IMAGE: string = "<img src='{{{src}}}'>";
	private static ELEMENT_COLUMN_CONTENT_LINKS_CONTAINER: string = "<ul></ul>";
	private static ELEMENT_COLUMN_CONTENT_LINKS_ITEM: string = "<li><a href='{{{link}}}'>{{{name}}}</a></li>";

    public constructor ( accessName:string, accessID:number ){
        super( new ModelNavigationSync(), accessName, accessID );
	}

	public initGlobals ():void{
		var url = this.getModule().getConfig( "url" );
		if ( url != null ){
			ControllerNavigationSync.SYNC_URL = url;
		}

		var output = this.getModule().getConfig( "output" );
		if ( output != null ){
			ControllerNavigationSync.OUTPUT = output;
		}

		var syncs = this.getModule().getConfig( "syncs" );
		if ( syncs != null && syncs.length ){
			for (let i = 0; i < syncs.length; i++) {
				ControllerNavigationSync.SYNCS.push( new Pair( syncs[i], null ) );
			}
		}

		var elementColumnContainer = this.getModule().getComponent( "column_container" );
		if (elementColumnContainer != null){
			ControllerNavigationSync.ELEMENT_COLUMN_CONTAINER = elementColumnContainer;
			this.getModule().addView( "column_container", ControllerNavigationSync.ELEMENT_COLUMN_CONTAINER );
		}

		var elementColumnContentImage = this.getModule().getComponent( "column_content.image" );
		if (elementColumnContentImage != null){
			ControllerNavigationSync.ELEMENT_COLUMN_CONTENT_IMAGE = elementColumnContentImage;
			this.getModule().addView( "column_content_image", ControllerNavigationSync.ELEMENT_COLUMN_CONTENT_IMAGE );
		}

		var elementColumnContentLinksContainer = this.getModule().getComponent( "column_content.links.container" );
		if (elementColumnContentLinksContainer != null){
			ControllerNavigationSync.ELEMENT_COLUMN_CONTENT_LINKS_CONTAINER = elementColumnContentLinksContainer;
			this.getModule().addView( "column_content_links_container", ControllerNavigationSync.ELEMENT_COLUMN_CONTENT_LINKS_CONTAINER );
		}

		var elementColumnContentLinksItem = this.getModule().getComponent( "column_content.links.item" );
		if (elementColumnContentLinksItem != null){
			ControllerNavigationSync.ELEMENT_COLUMN_CONTENT_LINKS_ITEM = elementColumnContentLinksItem;
			this.getModule().addView( "column_content_links_item", ControllerNavigationSync.ELEMENT_COLUMN_CONTENT_LINKS_ITEM );
		}

		for (let i = 0; i < ControllerNavigationSync.SYNCS.length; i++) {
			ControllerNavigationSync.SYNCS[i].setValue( AjaxRequest.getJson( ControllerNavigationSync.SYNC_URL + ControllerNavigationSync.SYNCS[i].getKey() + ".json" ) );
		}
	}

	public run (){
		if (ControllerNavigationSync.SYNC_URL != null && ControllerNavigationSync.SYNC_URL != ""){
			for (let i = 0; i < ControllerNavigationSync.SYNCS.length; i++) {
				this.processWorld( ControllerNavigationSync.SYNCS[i].getKey(), ControllerNavigationSync.SYNCS[i].getValue() );
			}
		} else {
			this.getModule().error( Globals.MODULE_LOADING_ERROR + " die Sync-URL nicht angegeben wurde" );
		}
	}

	private processWorld ( worldName:string, config:any ):void{
		if ( config != null ){

			var content:string = "";
			var columns = Json.getSubobject( config, "rows" );

			if ( columns != null && columns.length ){

				for (let i = 0; i < columns.length; i++) {
					var column = columns[i];

					var type:Type = Json.getSubobject( column, "type" );
					var data:any = Json.getSubobject( column, "data" );

					var processedColumn:string = "";

					switch( type ){
						case Type.IMAGE:
							processedColumn = this.processImage( data );
							break;
						case Type.LINKS:
							processedColumn = this.processLinks( data );
							break;
					}
					
					var modelID = this.getModel().new();

					this.getModel().add( modelID, "length", columns.length );
					this.getModel().add( modelID, "elements", processedColumn );

					processedColumn = this.process( modelID, "column_container" );

					content += processedColumn;
				}

			}

			var outputElements:JQuery<HTMLElement> = jQuery( "." + ControllerNavigationSync.OUTPUT + worldName );
			if ( outputElements.length ){
				for (let i = 0; i < outputElements.length; i++) {
					jQuery(outputElements[i]).html( content );
				}
			}
		}
	}

	private processImage ( data:any ):string{
		var result:string = "";

		if ( data != null && typeof data == "string" ){
			result = this.processOne( "column_content_image", "src", data );
		}

		return result;
	}

	private processLinks ( data:any ):string{
		var result:string = "";

		if ( data != null && typeof data == "object" && data.length ){

			for (let i = 0; i < data.length; i++) {
				var name: string = Json.getSubobject( data[i], "name" );
				var link: string = Json.getSubobject( data[i], "link" );
				var flags: string = Json.getSubobject( data[i], "flags" );

				var classes: string = "";

				if ( flags != null && flags.length ){
					for (let j = 0; j < flags.length; j++) {
						if ( j > 0 ){
							classes += " ";
						}
						classes += flags[j];
					}
				}

				var modelID:number = this.getModel().new();

				this.getModel().add( modelID, "name", name );
				this.getModel().add( modelID, "link", link );
				if ( classes != "" ){
					this.getModel().add( modelID, "classes", classes );
				}

				result += this.process( modelID, "column_content_links_item" );
			}

			result = this.processOne( "column_content_links_container", "elements", result );
		}

		return result;
	}
}