import { Filter } from "./Filter";
import { Number } from "../../../libs/Number";
import { Json } from "../../../libs/Json";
import { findIcon } from "../../../libs/Icons";
import { ControllerSearch } from "../ControllerSearch";
import jQuery = require( "jquery" );

import "jquery-ui";

export class PriceFilter extends Filter {

	private static PRICE_FORMAT_MIN:string = "[min]";
	private static PRICE_FORMAT_MAX:string = "[max]";
	
	private static HEADLINE:string = "";

	private static STRUCTURE:string = "";

	private static DISABLE_PRICE_FILTER:boolean = true;

	private static START_VALUE_PLUS_MINUS:number = 0;

    private currentPriceMax: number;
    private currentPriceMin: number;

    private limitPriceMax: number;
	private limitPriceMin: number;
	
	public constructor ( controller:ControllerSearch ){
		super( controller, "price" );

		this.setHeadline( PriceFilter.HEADLINE );
		this.setIcon( findIcon( "filter_price" ) );

		this.currentPriceMax = null;
		this.currentPriceMin = null;

		this.limitPriceMax = null;
		this.limitPriceMin = null;
	}

	public initGlobals ():void{
		PriceFilter.PRICE_FORMAT_MIN = this.getController().getModule().getConfig( "tabs.shop.filter.price.price_format_min" );
		PriceFilter.PRICE_FORMAT_MAX = this.getController().getModule().getConfig( "tabs.shop.filter.price.price_format_max" );

		PriceFilter.HEADLINE = this.getController().getModule().getLabel( "filter_price_headline" );

		PriceFilter.STRUCTURE = this.getController().getModule().getComponent( "filter.price.content" );
		
		PriceFilter.DISABLE_PRICE_FILTER = this.getController().getModule().getConfig( "disable_price_filter" ) == true;

		PriceFilter.START_VALUE_PLUS_MINUS = this.getController().getModule().getConfig( "tabs.shop.filter.price.start_value_plus_minus" ) || 0;
	}

    public create ( value:Object ):string {
        var filter_output = null;

        if ( value != null && !PriceFilter.DISABLE_PRICE_FILTER) {
			
            filter_output = PriceFilter.STRUCTURE;

            /**
            *
            * Process
            */
            if ( this.limitPriceMin === null ) {
                this.limitPriceMin = Json.getSubobject( value, "pricemin" );
            }
            if ( this.limitPriceMax === null ) {
                this.limitPriceMax = Json.getSubobject( value, "pricemax" );
            }
            var start_value = Number.minusPercent( this.limitPriceMax, 100 -  PriceFilter.START_VALUE_PLUS_MINUS);
            var end_value = Number.minusPercent( this.limitPriceMax, PriceFilter.START_VALUE_PLUS_MINUS );

            if ( this.currentPriceMin === null ) {
                this.currentPriceMin = start_value;
            }
            if ( this.currentPriceMax === null ) {
                this.currentPriceMax = end_value;
            }

        }

        return filter_output;
	}
	
	public execute ():void{
		this.getController().refreshAll();
		this.getController().onContentChange();
	}

    public assignEvents ():void {
        var price_slider = jQuery( "#range_slider_price" );
		if ( price_slider.length ) {
			jQuery( "#range_slider_price" ).slider({
				range: true,
				min: this.limitPriceMin,
				max: this.limitPriceMax,
				values: [ this.currentPriceMin, this.currentPriceMax ], 
				slide: function( event:Event, ui:JQueryUI.SliderOptions ) {

					this.currentPriceMin = ui.values[ 0 ];
					this.currentPriceMax = ui.values[ 1 ];

					this.set_Output();

				}.bind(this),
				change: function ( event:Event, ui:JQueryUI.SliderOptions ){
					this.execute();
				}.bind(this)
			});

			this.set_Output();
		}
    }

    public reset ():void{
        this.currentPriceMin = null;
		this.currentPriceMax = null;
		this.limitPriceMin = null;
		this.limitPriceMax = null;
    }

    /**
	*
	* Output the price filter values
	*/
	private set_Output ():void{
		var price_filter_output_min = jQuery( ".ui-slider-handle:eq(0)" );
		var price_filter_output_max = jQuery( ".ui-slider-handle:eq(1)" );

		if ( price_filter_output_min.length && price_filter_output_max.length ) {

			if ( this.currentPriceMin !== null && this.currentPriceMax != null ) {

				/**
				*
				* Get the price format
				*/
				

				/**
				*
				* Round the result to two decimal places
				*/
				var min_rounded = ( this.currentPriceMin / 100 ).toFixed(2);
				var max_rounded = ( this.currentPriceMax / 100 ).toFixed(2);

				/**
				*
				* Replace the price format with the result
				*/
				var price_format_min:string = PriceFilter.PRICE_FORMAT_MIN.replace( "[min]", min_rounded );
				var price_format_max:string = PriceFilter.PRICE_FORMAT_MAX.replace( "[max]", max_rounded );

				/**
				*
				* Create the tip element if necessary
				*/
				if ( !price_filter_output_min.find( ".tip" ).length ) {
					price_filter_output_min.append( "<div class='tip'></div>" );
				}
				if ( !price_filter_output_max.find( ".tip" ).length ) {
					price_filter_output_max.append( "<div class='tip'></div>" );
				}

				/**
				*
				* Display the result
				*/
				price_filter_output_min.find( ".tip" ).html( price_format_min );
				price_filter_output_max.find( ".tip" ).html( price_format_max );
			}

		}
		
	}

	public buildURL ():string{
		var url:string = "";

		if ( this.currentPriceMin !== null && this.currentPriceMax !== null ) {
			url += "&price_greater_than=" + ( this.currentPriceMin );
			url += "&price_lesser_than=" + ( this.currentPriceMax );
		}

		return url;
	}
}