import { Json } from "./../../libs/Json";
import { Times } from "./../../libs/Times";
import { Item } from "./../../libs/Item";
import { modules } from "../../main";

enum MessageType {
    START_STOP = "start_stop",
    START_DURATION = "start_duration"
}

export class Message extends Item{
    private id:number;
    private message:string;

    private start:Date;
    private stop:Date;

    public constructor( messageObject:Object ){
        super();

        var messages:object = Json.getSubobject( messageObject, "messages" );

        var id:number = Json.getSubobject( messageObject, "id" );
        var type:MessageType = Json.getSubobject( messageObject, "type" );
        var message:string = Json.getSubobject( messages, modules.getLanguageCode() );
        var start:Date = new Date( Json.getSubobject( messageObject, "start" ) );
       
        // this.setValid( id != null && type != null && message != null && start != null );

        if ( id != null && type != null && message != null && start != null ){
            this.id = id;
            this.message = message;
            this.start = start;
            this.stop = start;

            switch(type){
                case MessageType.START_STOP:
                    var stop:Date = new Date( Json.getSubobject( messageObject, "stop" ) );
                    if (stop != null){
                        this.stop = stop;
                    }
                break;
                case MessageType.START_DURATION:
                    var duration:number = Times.getMillis( Json.getSubobject( messageObject, "duration" ) );
                    if (!isNaN(duration)){
                        this.stop = new Date(this.start.getTime() + duration);
                    }
                break;
            }

            this.start.setHours(0, 0, 0, 0);
            this.stop.setHours(0, 0, 0, 0);
        }
    }

    public getMessage():string {
        return this.message;
    }

    public getStart():Date {
        return this.start;
    }

    public getStop():Date{
        return this.stop;
    }

    public getID ():number{
        return this.id;
    }
}