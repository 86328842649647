export class Option {

    private readonly key: string;
    private element:JQuery<HTMLElement>;

    private selected: boolean;
    private disabled: boolean;

    private deactivate:boolean;

    public constructor(key: string) {
        this.key = key;
        this.element = null;

        this.selected = false;
        this.disabled = false;
    }

    public activate ():void{
        this.selected = true;
    }

    public deselect ():void{
        this.selected = false;
    }

    public enable ():void{
        this.disabled = false;
    }

    public disable ():void{
        this.disabled = true;
    }

    public show ():void{
        if (this.selected){
            this.element.addClass( "active" );
        } else {
            this.element.removeClass( "active" );
        }

        if ( this.disabled ){
            this.element.addClass( "disabled" );
        } else {
            this.element.removeClass( "disabled" );
        }
    }
    public getKey ():string{
        return this.key;
    }

    public isSelected ():boolean{
        return this.selected;
    }

    public isDisabled ():boolean{
        return this.disabled;
    }

    public getElement ():JQuery<HTMLElement>{
        return this.element;
    }

    public setSelected ( selected:boolean ):void{
        this.selected = selected;
    }

    public setElement (element:JQuery<HTMLElement>){
        this.element = element;
    }
}