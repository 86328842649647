import { Globals } from "./../classes/Globals";

export class Elements {

    public static showElement ( element:JQuery<HTMLElement>, animation:string = "" ):void{
        if ( element !== undefined && element.length ){
            element.attr( Globals.ATTRIBUTE_PREFIX + "open", "true" );
            this.animate( element, animation );
        }
    }

    public static hideElement ( element:JQuery<HTMLElement>, animation:string = "" ):void{
        if ( element !== undefined && element.length ){
            element.attr( Globals.ATTRIBUTE_PREFIX + "open", "false" );
            this.animate( element, animation );
        }
    }

    public static animate ( element:JQuery<HTMLElement>, animation:string ){
        if ( animation != "" ){
            element.attr( Globals.ATTRIBUTE_PREFIX + "animation", animation );
        }
    }

    public static clearAnimation ( element:JQuery<HTMLElement> ):void{
        element.attr( Globals.ATTRIBUTE_PREFIX + "animation", "" );
    }

    public static isSelectBox (element:JQuery<HTMLElement>):boolean{
        return element.is( "select" );
    }

    public static isCheckBox (element:JQuery<HTMLElement>):boolean{
        return element.is( ":checkbox" );
    }

    public static isRadioBox (element:JQuery<HTMLElement>):boolean{
        return element.is( ":radio" );
    }

    public static setFocusOnClick (){
        var eventValue = "setFocus";
        var elements:any = jQuery( "input:checkbox:not([" + Globals.EVENT_SET_ATTRIBUTE + "='"+ eventValue +"']), input:radio:not([" + Globals.EVENT_SET_ATTRIBUTE + "='"+ eventValue +"'])" );
        
        for (let i = 0; i < elements.length; i++) {
            
            jQuery( elements[i] ).change( function (){
                var element:JQuery<HTMLElement> = jQuery( this );
                if ( !element.is( ":focus" ) ){
                    element.focus();
                }
            });

            jQuery( elements[i] ).attr( Globals.EVENT_SET_ATTRIBUTE, eventValue );
        }
    }

    public static blockSpecialChars (){
        const regex = /((?![a-zA-Z0-9üäöÜÄÖß!§$%&()=?+#,.*’;:_<>@€\s\“\"\'\\\/\-\/\[\]\{\}]).)/g;

        var eventValue = "blockSpecialChars";
        var elements:any = jQuery( "input:password:not([" + Globals.EVENT_SET_ATTRIBUTE + "='"+ eventValue +"'])" );
        
        for (let i = 0; i < elements.length; i++) {
            
            elements[i].addEventListener( "input", function (){
                this.value = this.value.replace( regex, "" );
            });

            jQuery( elements[i] ).on("paste", function ( event:any ){
                if ( event.originalEvent.clipboardData.getData("text/plain").match( regex ) ){

                    alert("Sie können keine Ungültigen Zeichen als Passwort verwenden.");

                    event.preventDefault();
                }
            });

            jQuery( elements[i] ).attr( Globals.EVENT_SET_ATTRIBUTE, eventValue );
        }
    }

    public static addComment ( element:JQuery<HTMLElement>, comment:string ){
        if (element != null && element.length) {
            element.before( "<!-- "+ comment +" -->" );
        }
    }

    public static findElement ( findIn:JQuery<HTMLElement>, event:string ){
        var element = findIn.find( "[" + Globals.ELEMENT_ATTRIBUTE + "='" + event + "']" );

		if (element.length){
			return element;
		} else {
			return null;
		}
    }
}