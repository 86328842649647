import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";
import { ControllerSlider } from "./ControllerSlider";
import { ModelSlider } from "./ModelSlider";
import { ControllerLightbox } from "../Lightbox/ControllerLightbox";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";

@ModuleConfig({
    moduleName: "Slider",
    style: "slider.scss"
})
export class ModuleSlider extends HTMLModule<ModelSlider> {

    public constructor ( configuration:Object ){
        super( "Slider", configuration );
        
        this.registerCallableMethod( this, "jumpTo", this.jumpTo.bind(this) );
        this.registerDependentModule( "Lightbox", ControllerLightbox.prototype );
        this.initAll( ControllerSlider.prototype );
    }

    public run (){
        this.runAllControllers(); 
    }

    public onControllersInitialized ():void {
    
    }

    public jumpTo ( id:string, keyname:string ):boolean{
        var controller:ControllerSlider = this.findController( id ) as ControllerSlider;
        if ( controller != null ){
            controller.jumpToByKeyname( keyname );
            controller.stopSlider();
            return true;
        } else {
            return false;
        }
    }

}