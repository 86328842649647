/* js-yaml 3.11.0 https://github.com/nodeca/js-yaml */(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.jsyaml = f()}})(function(){var define,module,exports;return (function(){function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s}return e})()({1:[function(require,module,exports){
  'use strict';


  var loader = require('./js-yaml/loader');
  var dumper = require('./js-yaml/dumper');
  
  
  function deprecated(name) {
    return function () {
      throw new Error('Function ' + name + ' is deprecated and cannot be used.');
    };
  }
  
  
  module.exports.Type                = require('./js-yaml/type');
  module.exports.Schema              = require('./js-yaml/schema');
  module.exports.FAILSAFE_SCHEMA     = require('./js-yaml/schema/failsafe');
  module.exports.JSON_SCHEMA         = require('./js-yaml/schema/json');
  module.exports.CORE_SCHEMA         = require('./js-yaml/schema/core');
  module.exports.DEFAULT_SAFE_SCHEMA = require('./js-yaml/schema/default_safe');
  module.exports.DEFAULT_FULL_SCHEMA = require('./js-yaml/schema/default_full');
  module.exports.load                = loader.load;
  module.exports.loadAll             = loader.loadAll;
  module.exports.safeLoad            = loader.safeLoad;
  module.exports.safeLoadAll         = loader.safeLoadAll;
  module.exports.dump                = dumper.dump;
  module.exports.safeDump            = dumper.safeDump;
  module.exports.YAMLException       = require('./js-yaml/exception');
  
  // Deprecated schema names from JS-YAML 2.0.x
  module.exports.MINIMAL_SCHEMA = require('./js-yaml/schema/failsafe');
  module.exports.SAFE_SCHEMA    = require('./js-yaml/schema/default_safe');
  module.exports.DEFAULT_SCHEMA = require('./js-yaml/schema/default_full');
  
  // Deprecated functions from JS-YAML 1.x.x
  module.exports.scan           = deprecated('scan');
  module.exports.parse          = deprecated('parse');
  module.exports.compose        = deprecated('compose');
  module.exports.addConstructor = deprecated('addConstructor');
  
  },{"./js-yaml/dumper":3,"./js-yaml/exception":4,"./js-yaml/loader":5,"./js-yaml/schema":7,"./js-yaml/schema/core":8,"./js-yaml/schema/default_full":9,"./js-yaml/schema/default_safe":10,"./js-yaml/schema/failsafe":11,"./js-yaml/schema/json":12,"./js-yaml/type":13}],2:[function(require,module,exports){
  'use strict';
  
  
  function isNothing(subject) {
    return (typeof subject === 'undefined') || (subject === null);
  }
  
  
  function isObject(subject) {
    return (typeof subject === 'object') && (subject !== null);
  }
  
  
  function toArray(sequence) {
    if (Array.isArray(sequence)) return sequence;
    else if (isNothing(sequence)) return [];
  
    return [ sequence ];
  }
  
  
  function extend(target, source) {
    var index, length, key, sourceKeys;
  
    if (source) {
      sourceKeys = Object.keys(source);
  
      for (index = 0, length = sourceKeys.length; index < length; index += 1) {
        key = sourceKeys[index];
        target[key] = source[key];
      }
    }
  
    return target;
  }
  
  
  function repeat(string, count) {
    var result = '', cycle;
  
    for (cycle = 0; cycle < count; cycle += 1) {
      result += string;
    }
  
    return result;
  }
  
  
  function isNegativeZero(number) {
    return (number === 0) && (Number.NEGATIVE_INFINITY === 1 / number);
  }
  
  
  module.exports.isNothing      = isNothing;
  module.exports.isObject       = isObject;
  module.exports.toArray        = toArray;
  module.exports.repeat         = repeat;
  module.exports.isNegativeZero = isNegativeZero;
  module.exports.extend         = extend;
  
  },{}],3:[function(require,module,exports){
  'use strict';
  
  /*eslint-disable no-use-before-define*/
  
  var common              = require('./common');
  var YAMLException       = require('./exception');
  var DEFAULT_FULL_SCHEMA = require('./schema/default_full');
  var DEFAULT_SAFE_SCHEMA = require('./schema/default_safe');
  
  var _toString       = Object.prototype.toString;
  var _hasOwnProperty = Object.prototype.hasOwnProperty;
  
  var CHAR_TAB                  = 0x09; /* Tab */
  var CHAR_LINE_FEED            = 0x0A; /* LF */
  var CHAR_SPACE                = 0x20; /* Space */
  var CHAR_EXCLAMATION          = 0x21; /* ! */
  var CHAR_DOUBLE_QUOTE         = 0x22; /* " */
  var CHAR_SHARP                = 0x23; /* # */
  var CHAR_PERCENT              = 0x25; /* % */
  var CHAR_AMPERSAND            = 0x26; /* & */
  var CHAR_SINGLE_QUOTE         = 0x27; /* ' */
  var CHAR_ASTERISK             = 0x2A; /* * */
  var CHAR_COMMA                = 0x2C; /* , */
  var CHAR_MINUS                = 0x2D; /* - */
  var CHAR_COLON                = 0x3A; /* : */
  var CHAR_GREATER_THAN         = 0x3E; /* > */
  var CHAR_QUESTION             = 0x3F; /* ? */
  var CHAR_COMMERCIAL_AT        = 0x40; /* @ */
  var CHAR_LEFT_SQUARE_BRACKET  = 0x5B; /* [ */
  var CHAR_RIGHT_SQUARE_BRACKET = 0x5D; /* ] */
  var CHAR_GRAVE_ACCENT         = 0x60; /* ` */
  var CHAR_LEFT_CURLY_BRACKET   = 0x7B; /* { */
  var CHAR_VERTICAL_LINE        = 0x7C; /* | */
  var CHAR_RIGHT_CURLY_BRACKET  = 0x7D; /* } */
  
  var ESCAPE_SEQUENCES = {};
  
  ESCAPE_SEQUENCES[0x00]   = '\\0';
  ESCAPE_SEQUENCES[0x07]   = '\\a';
  ESCAPE_SEQUENCES[0x08]   = '\\b';
  ESCAPE_SEQUENCES[0x09]   = '\\t';
  ESCAPE_SEQUENCES[0x0A]   = '\\n';
  ESCAPE_SEQUENCES[0x0B]   = '\\v';
  ESCAPE_SEQUENCES[0x0C]   = '\\f';
  ESCAPE_SEQUENCES[0x0D]   = '\\r';
  ESCAPE_SEQUENCES[0x1B]   = '\\e';
  ESCAPE_SEQUENCES[0x22]   = '\\"';
  ESCAPE_SEQUENCES[0x5C]   = '\\\\';
  ESCAPE_SEQUENCES[0x85]   = '\\N';
  ESCAPE_SEQUENCES[0xA0]   = '\\_';
  ESCAPE_SEQUENCES[0x2028] = '\\L';
  ESCAPE_SEQUENCES[0x2029] = '\\P';
  
  var DEPRECATED_BOOLEANS_SYNTAX = [
    'y', 'Y', 'yes', 'Yes', 'YES', 'on', 'On', 'ON',
    'n', 'N', 'no', 'No', 'NO', 'off', 'Off', 'OFF'
  ];
  
  function compileStyleMap(schema, map) {
    var result, keys, index, length, tag, style, type;
  
    if (map === null) return {};
  
    result = {};
    keys = Object.keys(map);
  
    for (index = 0, length = keys.length; index < length; index += 1) {
      tag = keys[index];
      style = String(map[tag]);
  
      if (tag.slice(0, 2) === '!!') {
        tag = 'tag:yaml.org,2002:' + tag.slice(2);
      }
      type = schema.compiledTypeMap['fallback'][tag];
  
      if (type && _hasOwnProperty.call(type.styleAliases, style)) {
        style = type.styleAliases[style];
      }
  
      result[tag] = style;
    }
  
    return result;
  }
  
  function encodeHex(character) {
    var string, handle, length;
  
    string = character.toString(16).toUpperCase();
  
    if (character <= 0xFF) {
      handle = 'x';
      length = 2;
    } else if (character <= 0xFFFF) {
      handle = 'u';
      length = 4;
    } else if (character <= 0xFFFFFFFF) {
      handle = 'U';
      length = 8;
    } else {
      throw new YAMLException('code point within a string may not be greater than 0xFFFFFFFF');
    }
  
    return '\\' + handle + common.repeat('0', length - string.length) + string;
  }
  
  function State(options) {
    this.schema       = options['schema'] || DEFAULT_FULL_SCHEMA;
    this.indent       = Math.max(1, (options['indent'] || 2));
    this.skipInvalid  = options['skipInvalid'] || false;
    this.flowLevel    = (common.isNothing(options['flowLevel']) ? -1 : options['flowLevel']);
    this.styleMap     = compileStyleMap(this.schema, options['styles'] || null);
    this.sortKeys     = options['sortKeys'] || false;
    this.lineWidth    = options['lineWidth'] || 80;
    this.noRefs       = options['noRefs'] || false;
    this.noCompatMode = options['noCompatMode'] || false;
    this.condenseFlow = options['condenseFlow'] || false;
  
    this.implicitTypes = this.schema.compiledImplicit;
    this.explicitTypes = this.schema.compiledExplicit;
  
    this.tag = null;
    this.result = '';
  
    this.duplicates = [];
    this.usedDuplicates = null;
  }
  
  // Indents every line in a string. Empty lines (\n only) are not indented.
  function indentString(string, spaces) {
    var ind = common.repeat(' ', spaces),
        position = 0,
        next = -1,
        result = '',
        line,
        length = string.length;
  
    while (position < length) {
      next = string.indexOf('\n', position);
      if (next === -1) {
        line = string.slice(position);
        position = length;
      } else {
        line = string.slice(position, next + 1);
        position = next + 1;
      }
  
      if (line.length && line !== '\n') result += ind;
  
      result += line;
    }
  
    return result;
  }
  
  function generateNextLine(state, level) {
    return '\n' + common.repeat(' ', state.indent * level);
  }
  
  function testImplicitResolving(state, str) {
    var index, length, type;
  
    for (index = 0, length = state.implicitTypes.length; index < length; index += 1) {
      type = state.implicitTypes[index];
  
      if (type.resolve(str)) {
        return true;
      }
    }
  
    return false;
  }
  
  // [33] s-white ::= s-space | s-tab
  function isWhitespace(c) {
    return c === CHAR_SPACE || c === CHAR_TAB;
  }
  
  // Returns true if the character can be printed without escaping.
  // From YAML 1.2: "any allowed characters known to be non-printable
  // should also be escaped. [However,] This isn’t mandatory"
  // Derived from nb-char - \t - #x85 - #xA0 - #x2028 - #x2029.
  function isPrintable(c) {
    return  (0x00020 <= c && c <= 0x00007E)
        || ((0x000A1 <= c && c <= 0x00D7FF) && c !== 0x2028 && c !== 0x2029)
        || ((0x0E000 <= c && c <= 0x00FFFD) && c !== 0xFEFF /* BOM */)
        ||  (0x10000 <= c && c <= 0x10FFFF);
  }
  
  // Simplified test for values allowed after the first character in plain style.
  function isPlainSafe(c) {
    // Uses a subset of nb-char - c-flow-indicator - ":" - "#"
    // where nb-char ::= c-printable - b-char - c-byte-order-mark.
    return isPrintable(c) && c !== 0xFEFF
      // - c-flow-indicator
      && c !== CHAR_COMMA
      && c !== CHAR_LEFT_SQUARE_BRACKET
      && c !== CHAR_RIGHT_SQUARE_BRACKET
      && c !== CHAR_LEFT_CURLY_BRACKET
      && c !== CHAR_RIGHT_CURLY_BRACKET
      // - ":" - "#"
      && c !== CHAR_COLON
      && c !== CHAR_SHARP;
  }
  
  // Simplified test for values allowed as the first character in plain style.
  function isPlainSafeFirst(c) {
    // Uses a subset of ns-char - c-indicator
    // where ns-char = nb-char - s-white.
    return isPrintable(c) && c !== 0xFEFF
      && !isWhitespace(c) // - s-white
      // - (c-indicator ::=
      // “-” | “?” | “:” | “,” | “[” | “]” | “{” | “}”
      && c !== CHAR_MINUS
      && c !== CHAR_QUESTION
      && c !== CHAR_COLON
      && c !== CHAR_COMMA
      && c !== CHAR_LEFT_SQUARE_BRACKET
      && c !== CHAR_RIGHT_SQUARE_BRACKET
      && c !== CHAR_LEFT_CURLY_BRACKET
      && c !== CHAR_RIGHT_CURLY_BRACKET
      // | “#” | “&” | “*” | “!” | “|” | “>” | “'” | “"”
      && c !== CHAR_SHARP
      && c !== CHAR_AMPERSAND
      && c !== CHAR_ASTERISK
      && c !== CHAR_EXCLAMATION
      && c !== CHAR_VERTICAL_LINE
      && c !== CHAR_GREATER_THAN
      && c !== CHAR_SINGLE_QUOTE
      && c !== CHAR_DOUBLE_QUOTE
      // | “%” | “@” | “`”)
      && c !== CHAR_PERCENT
      && c !== CHAR_COMMERCIAL_AT
      && c !== CHAR_GRAVE_ACCENT;
  }
  
  var STYLE_PLAIN   = 1,
      STYLE_SINGLE  = 2,
      STYLE_LITERAL = 3,
      STYLE_FOLDED  = 4,
      STYLE_DOUBLE  = 5;
  
  // Determines which scalar styles are possible and returns the preferred style.
  // lineWidth = -1 => no limit.
  // Pre-conditions: str.length > 0.
  // Post-conditions:
  //    STYLE_PLAIN or STYLE_SINGLE => no \n are in the string.
  //    STYLE_LITERAL => no lines are suitable for folding (or lineWidth is -1).
  //    STYLE_FOLDED => a line > lineWidth and can be folded (and lineWidth != -1).
  function chooseScalarStyle(string, singleLineOnly, indentPerLevel, lineWidth, testAmbiguousType) {
    var i;
    var char;
    var hasLineBreak = false;
    var hasFoldableLine = false; // only checked if shouldTrackWidth
    var shouldTrackWidth = lineWidth !== -1;
    var previousLineBreak = -1; // count the first line correctly
    var plain = isPlainSafeFirst(string.charCodeAt(0))
            && !isWhitespace(string.charCodeAt(string.length - 1));
  
    if (singleLineOnly) {
      // Case: no block styles.
      // Check for disallowed characters to rule out plain and single.
      for (i = 0; i < string.length; i++) {
        char = string.charCodeAt(i);
        if (!isPrintable(char)) {
          return STYLE_DOUBLE;
        }
        plain = plain && isPlainSafe(char);
      }
    } else {
      // Case: block styles permitted.
      for (i = 0; i < string.length; i++) {
        char = string.charCodeAt(i);
        if (char === CHAR_LINE_FEED) {
          hasLineBreak = true;
          // Check if any line can be folded.
          if (shouldTrackWidth) {
            hasFoldableLine = hasFoldableLine ||
              // Foldable line = too long, and not more-indented.
              (i - previousLineBreak - 1 > lineWidth &&
               string[previousLineBreak + 1] !== ' ');
            previousLineBreak = i;
          }
        } else if (!isPrintable(char)) {
          return STYLE_DOUBLE;
        }
        plain = plain && isPlainSafe(char);
      }
      // in case the end is missing a \n
      hasFoldableLine = hasFoldableLine || (shouldTrackWidth &&
        (i - previousLineBreak - 1 > lineWidth &&
         string[previousLineBreak + 1] !== ' '));
    }
    // Although every style can represent \n without escaping, prefer block styles
    // for multiline, since they're more readable and they don't add empty lines.
    // Also prefer folding a super-long line.
    if (!hasLineBreak && !hasFoldableLine) {
      // Strings interpretable as another type have to be quoted;
      // e.g. the string 'true' vs. the boolean true.
      return plain && !testAmbiguousType(string)
        ? STYLE_PLAIN : STYLE_SINGLE;
    }
    // Edge case: block indentation indicator can only have one digit.
    if (string[0] === ' ' && indentPerLevel > 9) {
      return STYLE_DOUBLE;
    }
    // At this point we know block styles are valid.
    // Prefer literal style unless we want to fold.
    return hasFoldableLine ? STYLE_FOLDED : STYLE_LITERAL;
  }
  
  // Note: line breaking/folding is implemented for only the folded style.
  // NB. We drop the last trailing newline (if any) of a returned block scalar
  //  since the dumper adds its own newline. This always works:
  //    • No ending newline => unaffected; already using strip "-" chomping.
  //    • Ending newline    => removed then restored.
  //  Importantly, this keeps the "+" chomp indicator from gaining an extra line.
  function writeScalar(state, string, level, iskey) {
    state.dump = (function () {
      if (string.length === 0) {
        return "''";
      }
      if (!state.noCompatMode &&
          DEPRECATED_BOOLEANS_SYNTAX.indexOf(string) !== -1) {
        return "'" + string + "'";
      }
  
      var indent = state.indent * Math.max(1, level); // no 0-indent scalars
      // As indentation gets deeper, let the width decrease monotonically
      // to the lower bound min(state.lineWidth, 40).
      // Note that this implies
      //  state.lineWidth ≤ 40 + state.indent: width is fixed at the lower bound.
      //  state.lineWidth > 40 + state.indent: width decreases until the lower bound.
      // This behaves better than a constant minimum width which disallows narrower options,
      // or an indent threshold which causes the width to suddenly increase.
      var lineWidth = state.lineWidth === -1
        ? -1 : Math.max(Math.min(state.lineWidth, 40), state.lineWidth - indent);
  
      // Without knowing if keys are implicit/explicit, assume implicit for safety.
      var singleLineOnly = iskey
        // No block styles in flow mode.
        || (state.flowLevel > -1 && level >= state.flowLevel);
      function testAmbiguity(string) {
        return testImplicitResolving(state, string);
      }
  
      switch (chooseScalarStyle(string, singleLineOnly, state.indent, lineWidth, testAmbiguity)) {
        case STYLE_PLAIN:
          return string;
        case STYLE_SINGLE:
          return "'" + string.replace(/'/g, "''") + "'";
        case STYLE_LITERAL:
          return '|' + blockHeader(string, state.indent)
            + dropEndingNewline(indentString(string, indent));
        case STYLE_FOLDED:
          return '>' + blockHeader(string, state.indent)
            + dropEndingNewline(indentString(foldString(string, lineWidth), indent));
        case STYLE_DOUBLE:
          return '"' + escapeString(string, lineWidth) + '"';
        default:
          throw new YAMLException('impossible error: invalid scalar style');
      }
    }());
  }
  
  // Pre-conditions: string is valid for a block scalar, 1 <= indentPerLevel <= 9.
  function blockHeader(string, indentPerLevel) {
    var indentIndicator = (string[0] === ' ') ? String(indentPerLevel) : '';
  
    // note the special case: the string '\n' counts as a "trailing" empty line.
    var clip =          string[string.length - 1] === '\n';
    var keep = clip && (string[string.length - 2] === '\n' || string === '\n');
    var chomp = keep ? '+' : (clip ? '' : '-');
  
    return indentIndicator + chomp + '\n';
  }
  
  // (See the note for writeScalar.)
  function dropEndingNewline(string) {
    return string[string.length - 1] === '\n' ? string.slice(0, -1) : string;
  }
  
  // Note: a long line without a suitable break point will exceed the width limit.
  // Pre-conditions: every char in str isPrintable, str.length > 0, width > 0.
  function foldString(string, width) {
    // In folded style, $k$ consecutive newlines output as $k+1$ newlines—
    // unless they're before or after a more-indented line, or at the very
    // beginning or end, in which case $k$ maps to $k$.
    // Therefore, parse each chunk as newline(s) followed by a content line.
    var lineRe = /(\n+)([^\n]*)/g;
  
    // first line (possibly an empty line)
    var result = (function () {
      var nextLF = string.indexOf('\n');
      nextLF = nextLF !== -1 ? nextLF : string.length;
      lineRe.lastIndex = nextLF;
      return foldLine(string.slice(0, nextLF), width);
    }());
    // If we haven't reached the first content line yet, don't add an extra \n.
    var prevMoreIndented = string[0] === '\n' || string[0] === ' ';
    var moreIndented;
  
    // rest of the lines
    var match;
    while ((match = lineRe.exec(string))) {
      var prefix = match[1], line = match[2];
      moreIndented = (line[0] === ' ');
      result += prefix
        + (!prevMoreIndented && !moreIndented && line !== ''
          ? '\n' : '')
        + foldLine(line, width);
      prevMoreIndented = moreIndented;
    }
  
    return result;
  }
  
  // Greedy line breaking.
  // Picks the longest line under the limit each time,
  // otherwise settles for the shortest line over the limit.
  // NB. More-indented lines *cannot* be folded, as that would add an extra \n.
  function foldLine(line, width) {
    if (line === '' || line[0] === ' ') return line;
  
    // Since a more-indented line adds a \n, breaks can't be followed by a space.
    var breakRe = / [^ ]/g; // note: the match index will always be <= length-2.
    var match;
    // start is an inclusive index. end, curr, and next are exclusive.
    var start = 0, end, curr = 0, next = 0;
    var result = '';
  
    // Invariants: 0 <= start <= length-1.
    //   0 <= curr <= next <= max(0, length-2). curr - start <= width.
    // Inside the loop:
    //   A match implies length >= 2, so curr and next are <= length-2.
    while ((match = breakRe.exec(line))) {
      next = match.index;
      // maintain invariant: curr - start <= width
      if (next - start > width) {
        end = (curr > start) ? curr : next; // derive end <= length-2
        result += '\n' + line.slice(start, end);
        // skip the space that was output as \n
        start = end + 1;                    // derive start <= length-1
      }
      curr = next;
    }
  
    // By the invariants, start <= length-1, so there is something left over.
    // It is either the whole string or a part starting from non-whitespace.
    result += '\n';
    // Insert a break if the remainder is too long and there is a break available.
    if (line.length - start > width && curr > start) {
      result += line.slice(start, curr) + '\n' + line.slice(curr + 1);
    } else {
      result += line.slice(start);
    }
  
    return result.slice(1); // drop extra \n joiner
  }
  
  // Escapes a double-quoted string.
  function escapeString(string) {
    var result = '';
    var char, nextChar;
    var escapeSeq;
  
    for (var i = 0; i < string.length; i++) {
      char = string.charCodeAt(i);
      // Check for surrogate pairs (reference Unicode 3.0 section "3.7 Surrogates").
      if (char >= 0xD800 && char <= 0xDBFF/* high surrogate */) {
        nextChar = string.charCodeAt(i + 1);
        if (nextChar >= 0xDC00 && nextChar <= 0xDFFF/* low surrogate */) {
          // Combine the surrogate pair and store it escaped.
          result += encodeHex((char - 0xD800) * 0x400 + nextChar - 0xDC00 + 0x10000);
          // Advance index one extra since we already used that char here.
          i++; continue;
        }
      }
      escapeSeq = ESCAPE_SEQUENCES[char];
      result += !escapeSeq && isPrintable(char)
        ? string[i]
        : escapeSeq || encodeHex(char);
    }
  
    return result;
  }
  
  function writeFlowSequence(state, level, object) {
    var _result = '',
        _tag    = state.tag,
        index,
        length;
  
    for (index = 0, length = object.length; index < length; index += 1) {
      // Write only valid elements.
      if (writeNode(state, level, object[index], false, false)) {
        if (index !== 0) _result += ',' + (!state.condenseFlow ? ' ' : '');
        _result += state.dump;
      }
    }
  
    state.tag = _tag;
    state.dump = '[' + _result + ']';
  }
  
  function writeBlockSequence(state, level, object, compact) {
    var _result = '',
        _tag    = state.tag,
        index,
        length;
  
    for (index = 0, length = object.length; index < length; index += 1) {
      // Write only valid elements.
      if (writeNode(state, level + 1, object[index], true, true)) {
        if (!compact || index !== 0) {
          _result += generateNextLine(state, level);
        }
  
        if (state.dump && CHAR_LINE_FEED === state.dump.charCodeAt(0)) {
          _result += '-';
        } else {
          _result += '- ';
        }
  
        _result += state.dump;
      }
    }
  
    state.tag = _tag;
    state.dump = _result || '[]'; // Empty sequence if no valid values.
  }
  
  function writeFlowMapping(state, level, object) {
    var _result       = '',
        _tag          = state.tag,
        objectKeyList = Object.keys(object),
        index,
        length,
        objectKey,
        objectValue,
        pairBuffer;
  
    for (index = 0, length = objectKeyList.length; index < length; index += 1) {
      pairBuffer = state.condenseFlow ? '"' : '';
  
      if (index !== 0) pairBuffer += ', ';
  
      objectKey = objectKeyList[index];
      objectValue = object[objectKey];
  
      if (!writeNode(state, level, objectKey, false, false)) {
        continue; // Skip this pair because of invalid key;
      }
  
      if (state.dump.length > 1024) pairBuffer += '? ';
  
      pairBuffer += state.dump + (state.condenseFlow ? '"' : '') + ':' + (state.condenseFlow ? '' : ' ');
  
      if (!writeNode(state, level, objectValue, false, false)) {
        continue; // Skip this pair because of invalid value.
      }
  
      pairBuffer += state.dump;
  
      // Both key and value are valid.
      _result += pairBuffer;
    }
  
    state.tag = _tag;
    state.dump = '{' + _result + '}';
  }
  
  function writeBlockMapping(state, level, object, compact) {
    var _result       = '',
        _tag          = state.tag,
        objectKeyList = Object.keys(object),
        index,
        length,
        objectKey,
        objectValue,
        explicitPair,
        pairBuffer;
  
    // Allow sorting keys so that the output file is deterministic
    if (state.sortKeys === true) {
      // Default sorting
      objectKeyList.sort();
    } else if (typeof state.sortKeys === 'function') {
      // Custom sort function
      objectKeyList.sort(state.sortKeys);
    } else if (state.sortKeys) {
      // Something is wrong
      throw new YAMLException('sortKeys must be a boolean or a function');
    }
  
    for (index = 0, length = objectKeyList.length; index < length; index += 1) {
      pairBuffer = '';
  
      if (!compact || index !== 0) {
        pairBuffer += generateNextLine(state, level);
      }
  
      objectKey = objectKeyList[index];
      objectValue = object[objectKey];
  
      if (!writeNode(state, level + 1, objectKey, true, true, true)) {
        continue; // Skip this pair because of invalid key.
      }
  
      explicitPair = (state.tag !== null && state.tag !== '?') ||
                     (state.dump && state.dump.length > 1024);
  
      if (explicitPair) {
        if (state.dump && CHAR_LINE_FEED === state.dump.charCodeAt(0)) {
          pairBuffer += '?';
        } else {
          pairBuffer += '? ';
        }
      }
  
      pairBuffer += state.dump;
  
      if (explicitPair) {
        pairBuffer += generateNextLine(state, level);
      }
  
      if (!writeNode(state, level + 1, objectValue, true, explicitPair)) {
        continue; // Skip this pair because of invalid value.
      }
  
      if (state.dump && CHAR_LINE_FEED === state.dump.charCodeAt(0)) {
        pairBuffer += ':';
      } else {
        pairBuffer += ': ';
      }
  
      pairBuffer += state.dump;
  
      // Both key and value are valid.
      _result += pairBuffer;
    }
  
    state.tag = _tag;
    state.dump = _result || '{}'; // Empty mapping if no valid pairs.
  }
  
  function detectType(state, object, explicit) {
    var _result, typeList, index, length, type, style;
  
    typeList = explicit ? state.explicitTypes : state.implicitTypes;
  
    for (index = 0, length = typeList.length; index < length; index += 1) {
      type = typeList[index];
  
      if ((type.instanceOf  || type.predicate) &&
          (!type.instanceOf || ((typeof object === 'object') && (object instanceof type.instanceOf))) &&
          (!type.predicate  || type.predicate(object))) {
  
        state.tag = explicit ? type.tag : '?';
  
        if (type.represent) {
          style = state.styleMap[type.tag] || type.defaultStyle;
  
          if (_toString.call(type.represent) === '[object Function]') {
            _result = type.represent(object, style);
          } else if (_hasOwnProperty.call(type.represent, style)) {
            _result = type.represent[style](object, style);
          } else {
            throw new YAMLException('!<' + type.tag + '> tag resolver accepts not "' + style + '" style');
          }
  
          state.dump = _result;
        }
  
        return true;
      }
    }
  
    return false;
  }
  
  // Serializes `object` and writes it to global `result`.
  // Returns true on success, or false on invalid object.
  //
  function writeNode(state, level, object, block, compact, iskey) {
    state.tag = null;
    state.dump = object;
  
    if (!detectType(state, object, false)) {
      detectType(state, object, true);
    }
  
    var type = _toString.call(state.dump);
  
    if (block) {
      block = (state.flowLevel < 0 || state.flowLevel > level);
    }
  
    var objectOrArray = type === '[object Object]' || type === '[object Array]',
        duplicateIndex,
        duplicate;
  
    if (objectOrArray) {
      duplicateIndex = state.duplicates.indexOf(object);
      duplicate = duplicateIndex !== -1;
    }
  
    if ((state.tag !== null && state.tag !== '?') || duplicate || (state.indent !== 2 && level > 0)) {
      compact = false;
    }
  
    if (duplicate && state.usedDuplicates[duplicateIndex]) {
      state.dump = '*ref_' + duplicateIndex;
    } else {
      if (objectOrArray && duplicate && !state.usedDuplicates[duplicateIndex]) {
        state.usedDuplicates[duplicateIndex] = true;
      }
      if (type === '[object Object]') {
        if (block && (Object.keys(state.dump).length !== 0)) {
          writeBlockMapping(state, level, state.dump, compact);
          if (duplicate) {
            state.dump = '&ref_' + duplicateIndex + state.dump;
          }
        } else {
          writeFlowMapping(state, level, state.dump);
          if (duplicate) {
            state.dump = '&ref_' + duplicateIndex + ' ' + state.dump;
          }
        }
      } else if (type === '[object Array]') {
        if (block && (state.dump.length !== 0)) {
          writeBlockSequence(state, level, state.dump, compact);
          if (duplicate) {
            state.dump = '&ref_' + duplicateIndex + state.dump;
          }
        } else {
          writeFlowSequence(state, level, state.dump);
          if (duplicate) {
            state.dump = '&ref_' + duplicateIndex + ' ' + state.dump;
          }
        }
      } else if (type === '[object String]') {
        if (state.tag !== '?') {
          writeScalar(state, state.dump, level, iskey);
        }
      } else {
        if (state.skipInvalid) return false;
        throw new YAMLException('unacceptable kind of an object to dump ' + type);
      }
  
      if (state.tag !== null && state.tag !== '?') {
        state.dump = '!<' + state.tag + '> ' + state.dump;
      }
    }
  
    return true;
  }
  
  function getDuplicateReferences(object, state) {
    var objects = [],
        duplicatesIndexes = [],
        index,
        length;
  
    inspectNode(object, objects, duplicatesIndexes);
  
    for (index = 0, length = duplicatesIndexes.length; index < length; index += 1) {
      state.duplicates.push(objects[duplicatesIndexes[index]]);
    }
    state.usedDuplicates = new Array(length);
  }
  
  function inspectNode(object, objects, duplicatesIndexes) {
    var objectKeyList,
        index,
        length;
  
    if (object !== null && typeof object === 'object') {
      index = objects.indexOf(object);
      if (index !== -1) {
        if (duplicatesIndexes.indexOf(index) === -1) {
          duplicatesIndexes.push(index);
        }
      } else {
        objects.push(object);
  
        if (Array.isArray(object)) {
          for (index = 0, length = object.length; index < length; index += 1) {
            inspectNode(object[index], objects, duplicatesIndexes);
          }
        } else {
          objectKeyList = Object.keys(object);
  
          for (index = 0, length = objectKeyList.length; index < length; index += 1) {
            inspectNode(object[objectKeyList[index]], objects, duplicatesIndexes);
          }
        }
      }
    }
  }
  
  function dump(input, options) {
    options = options || {};
  
    var state = new State(options);
  
    if (!state.noRefs) getDuplicateReferences(input, state);
  
    if (writeNode(state, 0, input, true, true)) return state.dump + '\n';
  
    return '';
  }
  
  function safeDump(input, options) {
    return dump(input, common.extend({ schema: DEFAULT_SAFE_SCHEMA }, options));
  }
  
  module.exports.dump     = dump;
  module.exports.safeDump = safeDump;
  
  },{"./common":2,"./exception":4,"./schema/default_full":9,"./schema/default_safe":10}],4:[function(require,module,exports){
  // YAML error class. http://stackoverflow.com/questions/8458984
  //
  'use strict';
  
  function YAMLException(reason, mark) {
    // Super constructor
    Error.call(this);
  
    this.name = 'YAMLException';
    this.reason = reason;
    this.mark = mark;
    this.message = (this.reason || '(unknown reason)') + (this.mark ? ' ' + this.mark.toString() : '');
  
    // Include stack trace in error object
    if (Error.captureStackTrace) {
      // Chrome and NodeJS
      Error.captureStackTrace(this, this.constructor);
    } else {
      // FF, IE 10+ and Safari 6+. Fallback for others
      this.stack = (new Error()).stack || '';
    }
  }
  
  
  // Inherit from Error
  YAMLException.prototype = Object.create(Error.prototype);
  YAMLException.prototype.constructor = YAMLException;
  
  
  YAMLException.prototype.toString = function toString(compact) {
    var result = this.name + ': ';
  
    result += this.reason || '(unknown reason)';
  
    if (!compact && this.mark) {
      result += ' ' + this.mark.toString();
    }
  
    return result;
  };
  
  
  module.exports = YAMLException;
  
  },{}],5:[function(require,module,exports){
  'use strict';
  
  /*eslint-disable max-len,no-use-before-define*/
  
  var common              = require('./common');
  var YAMLException       = require('./exception');
  var Mark                = require('./mark');
  var DEFAULT_SAFE_SCHEMA = require('./schema/default_safe');
  var DEFAULT_FULL_SCHEMA = require('./schema/default_full');
  
  
  var _hasOwnProperty = Object.prototype.hasOwnProperty;
  
  
  var CONTEXT_FLOW_IN   = 1;
  var CONTEXT_FLOW_OUT  = 2;
  var CONTEXT_BLOCK_IN  = 3;
  var CONTEXT_BLOCK_OUT = 4;
  
  
  var CHOMPING_CLIP  = 1;
  var CHOMPING_STRIP = 2;
  var CHOMPING_KEEP  = 3;
  
  
  var PATTERN_NON_PRINTABLE         = /[\x00-\x08\x0B\x0C\x0E-\x1F\x7F-\x84\x86-\x9F\uFFFE\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF]/;
  var PATTERN_NON_ASCII_LINE_BREAKS = /[\x85\u2028\u2029]/;
  var PATTERN_FLOW_INDICATORS       = /[,\[\]\{\}]/;
  var PATTERN_TAG_HANDLE            = /^(?:!|!!|![a-z\-]+!)$/i;
  var PATTERN_TAG_URI               = /^(?:!|[^,\[\]\{\}])(?:%[0-9a-f]{2}|[0-9a-z\-#;\/\?:@&=\+\$,_\.!~\*'\(\)\[\]])*$/i;
  
  
  function is_EOL(c) {
    return (c === 0x0A/* LF */) || (c === 0x0D/* CR */);
  }
  
  function is_WHITE_SPACE(c) {
    return (c === 0x09/* Tab */) || (c === 0x20/* Space */);
  }
  
  function is_WS_OR_EOL(c) {
    return (c === 0x09/* Tab */) ||
           (c === 0x20/* Space */) ||
           (c === 0x0A/* LF */) ||
           (c === 0x0D/* CR */);
  }
  
  function is_FLOW_INDICATOR(c) {
    return c === 0x2C/* , */ ||
           c === 0x5B/* [ */ ||
           c === 0x5D/* ] */ ||
           c === 0x7B/* { */ ||
           c === 0x7D/* } */;
  }
  
  function fromHexCode(c) {
    var lc;
  
    if ((0x30/* 0 */ <= c) && (c <= 0x39/* 9 */)) {
      return c - 0x30;
    }
  
    /*eslint-disable no-bitwise*/
    lc = c | 0x20;
  
    if ((0x61/* a */ <= lc) && (lc <= 0x66/* f */)) {
      return lc - 0x61 + 10;
    }
  
    return -1;
  }
  
  function escapedHexLen(c) {
    if (c === 0x78/* x */) { return 2; }
    if (c === 0x75/* u */) { return 4; }
    if (c === 0x55/* U */) { return 8; }
    return 0;
  }
  
  function fromDecimalCode(c) {
    if ((0x30/* 0 */ <= c) && (c <= 0x39/* 9 */)) {
      return c - 0x30;
    }
  
    return -1;
  }
  
  function simpleEscapeSequence(c) {
    /* eslint-disable indent */
    return (c === 0x30/* 0 */) ? '\x00' :
          (c === 0x61/* a */) ? '\x07' :
          (c === 0x62/* b */) ? '\x08' :
          (c === 0x74/* t */) ? '\x09' :
          (c === 0x09/* Tab */) ? '\x09' :
          (c === 0x6E/* n */) ? '\x0A' :
          (c === 0x76/* v */) ? '\x0B' :
          (c === 0x66/* f */) ? '\x0C' :
          (c === 0x72/* r */) ? '\x0D' :
          (c === 0x65/* e */) ? '\x1B' :
          (c === 0x20/* Space */) ? ' ' :
          (c === 0x22/* " */) ? '\x22' :
          (c === 0x2F/* / */) ? '/' :
          (c === 0x5C/* \ */) ? '\x5C' :
          (c === 0x4E/* N */) ? '\x85' :
          (c === 0x5F/* _ */) ? '\xA0' :
          (c === 0x4C/* L */) ? '\u2028' :
          (c === 0x50/* P */) ? '\u2029' : '';
  }
  
  function charFromCodepoint(c) {
    if (c <= 0xFFFF) {
      return String.fromCharCode(c);
    }
    // Encode UTF-16 surrogate pair
    // https://en.wikipedia.org/wiki/UTF-16#Code_points_U.2B010000_to_U.2B10FFFF
    return String.fromCharCode(
      ((c - 0x010000) >> 10) + 0xD800,
      ((c - 0x010000) & 0x03FF) + 0xDC00
    );
  }
  
  var simpleEscapeCheck = new Array(256); // integer, for fast access
  var simpleEscapeMap = new Array(256);
  for (var i = 0; i < 256; i++) {
    simpleEscapeCheck[i] = simpleEscapeSequence(i) ? 1 : 0;
    simpleEscapeMap[i] = simpleEscapeSequence(i);
  }
  
  
  function State(input, options) {
    this.input = input;
  
    this.filename  = options['filename']  || null;
    this.schema    = options['schema']    || DEFAULT_FULL_SCHEMA;
    this.onWarning = options['onWarning'] || null;
    this.legacy    = options['legacy']    || false;
    this.json      = options['json']      || false;
    this.listener  = options['listener']  || null;
  
    this.implicitTypes = this.schema.compiledImplicit;
    this.typeMap       = this.schema.compiledTypeMap;
  
    this.length     = input.length;
    this.position   = 0;
    this.line       = 0;
    this.lineStart  = 0;
    this.lineIndent = 0;
  
    this.documents = [];
  
    /*
    this.version;
    this.checkLineBreaks;
    this.tagMap;
    this.anchorMap;
    this.tag;
    this.anchor;
    this.kind;
    this.result;*/
  
  }
  
  
  function generateError(state, message) {
    return new YAMLException(
      message,
      new Mark(state.filename, state.input, state.position, state.line, (state.position - state.lineStart)));
  }
  
  function throwError(state, message) {
    throw generateError(state, message);
  }
  
  function throwWarning(state, message) {
    if (state.onWarning) {
      state.onWarning.call(null, generateError(state, message));
    }
  }
  
  
  var directiveHandlers = {
  
    YAML: function handleYamlDirective(state, name, args) {
  
      var match, major, minor;
  
      if (state.version !== null) {
        throwError(state, 'duplication of %YAML directive');
      }
  
      if (args.length !== 1) {
        throwError(state, 'YAML directive accepts exactly one argument');
      }
  
      match = /^([0-9]+)\.([0-9]+)$/.exec(args[0]);
  
      if (match === null) {
        throwError(state, 'ill-formed argument of the YAML directive');
      }
  
      major = parseInt(match[1], 10);
      minor = parseInt(match[2], 10);
  
      if (major !== 1) {
        throwError(state, 'unacceptable YAML version of the document');
      }
  
      state.version = args[0];
      state.checkLineBreaks = (minor < 2);
  
      if (minor !== 1 && minor !== 2) {
        throwWarning(state, 'unsupported YAML version of the document');
      }
    },
  
    TAG: function handleTagDirective(state, name, args) {
  
      var handle, prefix;
  
      if (args.length !== 2) {
        throwError(state, 'TAG directive accepts exactly two arguments');
      }
  
      handle = args[0];
      prefix = args[1];
  
      if (!PATTERN_TAG_HANDLE.test(handle)) {
        throwError(state, 'ill-formed tag handle (first argument) of the TAG directive');
      }
  
      if (_hasOwnProperty.call(state.tagMap, handle)) {
        throwError(state, 'there is a previously declared suffix for "' + handle + '" tag handle');
      }
  
      if (!PATTERN_TAG_URI.test(prefix)) {
        throwError(state, 'ill-formed tag prefix (second argument) of the TAG directive');
      }
  
      state.tagMap[handle] = prefix;
    }
  };
  
  
  function captureSegment(state, start, end, checkJson) {
    var _position, _length, _character, _result;
  
    if (start < end) {
      _result = state.input.slice(start, end);
  
      if (checkJson) {
        for (_position = 0, _length = _result.length; _position < _length; _position += 1) {
          _character = _result.charCodeAt(_position);
          if (!(_character === 0x09 ||
                (0x20 <= _character && _character <= 0x10FFFF))) {
            throwError(state, 'expected valid JSON character');
          }
        }
      } else if (PATTERN_NON_PRINTABLE.test(_result)) {
        throwError(state, 'the stream contains non-printable characters');
      }
  
      state.result += _result;
    }
  }
  
  function mergeMappings(state, destination, source, overridableKeys) {
    var sourceKeys, key, index, quantity;
  
    if (!common.isObject(source)) {
      throwError(state, 'cannot merge mappings; the provided source object is unacceptable');
    }
  
    sourceKeys = Object.keys(source);
  
    for (index = 0, quantity = sourceKeys.length; index < quantity; index += 1) {
      key = sourceKeys[index];
  
      if (!_hasOwnProperty.call(destination, key)) {
        destination[key] = source[key];
        overridableKeys[key] = true;
      }
    }
  }
  
  function storeMappingPair(state, _result, overridableKeys, keyTag, keyNode, valueNode, startLine, startPos) {
    var index, quantity;
  
    keyNode = String(keyNode);
  
    if (_result === null) {
      _result = {};
    }
  
    if (keyTag === 'tag:yaml.org,2002:merge') {
      if (Array.isArray(valueNode)) {
        for (index = 0, quantity = valueNode.length; index < quantity; index += 1) {
          mergeMappings(state, _result, valueNode[index], overridableKeys);
        }
      } else {
        mergeMappings(state, _result, valueNode, overridableKeys);
      }
    } else {
      if (!state.json &&
          !_hasOwnProperty.call(overridableKeys, keyNode) &&
          _hasOwnProperty.call(_result, keyNode)) {
        state.line = startLine || state.line;
        state.position = startPos || state.position;
        throwError(state, 'duplicated mapping key');
      }
      _result[keyNode] = valueNode;
      delete overridableKeys[keyNode];
    }
  
    return _result;
  }
  
  function readLineBreak(state) {
    var ch;
  
    ch = state.input.charCodeAt(state.position);
  
    if (ch === 0x0A/* LF */) {
      state.position++;
    } else if (ch === 0x0D/* CR */) {
      state.position++;
      if (state.input.charCodeAt(state.position) === 0x0A/* LF */) {
        state.position++;
      }
    } else {
      throwError(state, 'a line break is expected');
    }
  
    state.line += 1;
    state.lineStart = state.position;
  }
  
  function skipSeparationSpace(state, allowComments, checkIndent) {
    var lineBreaks = 0,
        ch = state.input.charCodeAt(state.position);
  
    while (ch !== 0) {
      while (is_WHITE_SPACE(ch)) {
        ch = state.input.charCodeAt(++state.position);
      }
  
      if (allowComments && ch === 0x23/* # */) {
        do {
          ch = state.input.charCodeAt(++state.position);
        } while (ch !== 0x0A/* LF */ && ch !== 0x0D/* CR */ && ch !== 0);
      }
  
      if (is_EOL(ch)) {
        readLineBreak(state);
  
        ch = state.input.charCodeAt(state.position);
        lineBreaks++;
        state.lineIndent = 0;
  
        while (ch === 0x20/* Space */) {
          state.lineIndent++;
          ch = state.input.charCodeAt(++state.position);
        }
      } else {
        break;
      }
    }
  
    if (checkIndent !== -1 && lineBreaks !== 0 && state.lineIndent < checkIndent) {
      throwWarning(state, 'deficient indentation');
    }
  
    return lineBreaks;
  }
  
  function testDocumentSeparator(state) {
    var _position = state.position,
        ch;
  
    ch = state.input.charCodeAt(_position);
  
    // Condition state.position === state.lineStart is tested
    // in parent on each call, for efficiency. No needs to test here again.
    if ((ch === 0x2D/* - */ || ch === 0x2E/* . */) &&
        ch === state.input.charCodeAt(_position + 1) &&
        ch === state.input.charCodeAt(_position + 2)) {
  
      _position += 3;
  
      ch = state.input.charCodeAt(_position);
  
      if (ch === 0 || is_WS_OR_EOL(ch)) {
        return true;
      }
    }
  
    return false;
  }
  
  function writeFoldedLines(state, count) {
    if (count === 1) {
      state.result += ' ';
    } else if (count > 1) {
      state.result += common.repeat('\n', count - 1);
    }
  }
  
  
  function readPlainScalar(state, nodeIndent, withinFlowCollection) {
    var preceding,
        following,
        captureStart,
        captureEnd,
        hasPendingContent,
        _line,
        _lineStart,
        _lineIndent,
        _kind = state.kind,
        _result = state.result,
        ch;
  
    ch = state.input.charCodeAt(state.position);
  
    if (is_WS_OR_EOL(ch)      ||
        is_FLOW_INDICATOR(ch) ||
        ch === 0x23/* # */    ||
        ch === 0x26/* & */    ||
        ch === 0x2A/* * */    ||
        ch === 0x21/* ! */    ||
        ch === 0x7C/* | */    ||
        ch === 0x3E/* > */    ||
        ch === 0x27/* ' */    ||
        ch === 0x22/* " */    ||
        ch === 0x25/* % */    ||
        ch === 0x40/* @ */    ||
        ch === 0x60/* ` */) {
      return false;
    }
  
    if (ch === 0x3F/* ? */ || ch === 0x2D/* - */) {
      following = state.input.charCodeAt(state.position + 1);
  
      if (is_WS_OR_EOL(following) ||
          withinFlowCollection && is_FLOW_INDICATOR(following)) {
        return false;
      }
    }
  
    state.kind = 'scalar';
    state.result = '';
    captureStart = captureEnd = state.position;
    hasPendingContent = false;
  
    while (ch !== 0) {
      if (ch === 0x3A/* : */) {
        following = state.input.charCodeAt(state.position + 1);
  
        if (is_WS_OR_EOL(following) ||
            withinFlowCollection && is_FLOW_INDICATOR(following)) {
          break;
        }
  
      } else if (ch === 0x23/* # */) {
        preceding = state.input.charCodeAt(state.position - 1);
  
        if (is_WS_OR_EOL(preceding)) {
          break;
        }
  
      } else if ((state.position === state.lineStart && testDocumentSeparator(state)) ||
                 withinFlowCollection && is_FLOW_INDICATOR(ch)) {
        break;
  
      } else if (is_EOL(ch)) {
        _line = state.line;
        _lineStart = state.lineStart;
        _lineIndent = state.lineIndent;
        skipSeparationSpace(state, false, -1);
  
        if (state.lineIndent >= nodeIndent) {
          hasPendingContent = true;
          ch = state.input.charCodeAt(state.position);
          continue;
        } else {
          state.position = captureEnd;
          state.line = _line;
          state.lineStart = _lineStart;
          state.lineIndent = _lineIndent;
          break;
        }
      }
  
      if (hasPendingContent) {
        captureSegment(state, captureStart, captureEnd, false);
        writeFoldedLines(state, state.line - _line);
        captureStart = captureEnd = state.position;
        hasPendingContent = false;
      }
  
      if (!is_WHITE_SPACE(ch)) {
        captureEnd = state.position + 1;
      }
  
      ch = state.input.charCodeAt(++state.position);
    }
  
    captureSegment(state, captureStart, captureEnd, false);
  
    if (state.result) {
      return true;
    }
  
    state.kind = _kind;
    state.result = _result;
    return false;
  }
  
  function readSingleQuotedScalar(state, nodeIndent) {
    var ch,
        captureStart, captureEnd;
  
    ch = state.input.charCodeAt(state.position);
  
    if (ch !== 0x27/* ' */) {
      return false;
    }
  
    state.kind = 'scalar';
    state.result = '';
    state.position++;
    captureStart = captureEnd = state.position;
  
    while ((ch = state.input.charCodeAt(state.position)) !== 0) {
      if (ch === 0x27/* ' */) {
        captureSegment(state, captureStart, state.position, true);
        ch = state.input.charCodeAt(++state.position);
  
        if (ch === 0x27/* ' */) {
          captureStart = state.position;
          state.position++;
          captureEnd = state.position;
        } else {
          return true;
        }
  
      } else if (is_EOL(ch)) {
        captureSegment(state, captureStart, captureEnd, true);
        writeFoldedLines(state, skipSeparationSpace(state, false, nodeIndent));
        captureStart = captureEnd = state.position;
  
      } else if (state.position === state.lineStart && testDocumentSeparator(state)) {
        throwError(state, 'unexpected end of the document within a single quoted scalar');
  
      } else {
        state.position++;
        captureEnd = state.position;
      }
    }
  
    throwError(state, 'unexpected end of the stream within a single quoted scalar');
  }
  
  function readDoubleQuotedScalar(state, nodeIndent) {
    var captureStart,
        captureEnd,
        hexLength,
        hexResult,
        tmp,
        ch;
  
    ch = state.input.charCodeAt(state.position);
  
    if (ch !== 0x22/* " */) {
      return false;
    }
  
    state.kind = 'scalar';
    state.result = '';
    state.position++;
    captureStart = captureEnd = state.position;
  
    while ((ch = state.input.charCodeAt(state.position)) !== 0) {
      if (ch === 0x22/* " */) {
        captureSegment(state, captureStart, state.position, true);
        state.position++;
        return true;
  
      } else if (ch === 0x5C/* \ */) {
        captureSegment(state, captureStart, state.position, true);
        ch = state.input.charCodeAt(++state.position);
  
        if (is_EOL(ch)) {
          skipSeparationSpace(state, false, nodeIndent);
  
          // TODO: rework to inline fn with no type cast?
        } else if (ch < 256 && simpleEscapeCheck[ch]) {
          state.result += simpleEscapeMap[ch];
          state.position++;
  
        } else if ((tmp = escapedHexLen(ch)) > 0) {
          hexLength = tmp;
          hexResult = 0;
  
          for (; hexLength > 0; hexLength--) {
            ch = state.input.charCodeAt(++state.position);
  
            if ((tmp = fromHexCode(ch)) >= 0) {
              hexResult = (hexResult << 4) + tmp;
  
            } else {
              throwError(state, 'expected hexadecimal character');
            }
          }
  
          state.result += charFromCodepoint(hexResult);
  
          state.position++;
  
        } else {
          throwError(state, 'unknown escape sequence');
        }
  
        captureStart = captureEnd = state.position;
  
      } else if (is_EOL(ch)) {
        captureSegment(state, captureStart, captureEnd, true);
        writeFoldedLines(state, skipSeparationSpace(state, false, nodeIndent));
        captureStart = captureEnd = state.position;
  
      } else if (state.position === state.lineStart && testDocumentSeparator(state)) {
        throwError(state, 'unexpected end of the document within a double quoted scalar');
  
      } else {
        state.position++;
        captureEnd = state.position;
      }
    }
  
    throwError(state, 'unexpected end of the stream within a double quoted scalar');
  }
  
  function readFlowCollection(state, nodeIndent) {
    var readNext = true,
        _line,
        _tag     = state.tag,
        _result,
        _anchor  = state.anchor,
        following,
        terminator,
        isPair,
        isExplicitPair,
        isMapping,
        overridableKeys = {},
        keyNode,
        keyTag,
        valueNode,
        ch;
  
    ch = state.input.charCodeAt(state.position);
  
    if (ch === 0x5B/* [ */) {
      terminator = 0x5D;/* ] */
      isMapping = false;
      _result = [];
    } else if (ch === 0x7B/* { */) {
      terminator = 0x7D;/* } */
      isMapping = true;
      _result = {};
    } else {
      return false;
    }
  
    if (state.anchor !== null) {
      state.anchorMap[state.anchor] = _result;
    }
  
    ch = state.input.charCodeAt(++state.position);
  
    while (ch !== 0) {
      skipSeparationSpace(state, true, nodeIndent);
  
      ch = state.input.charCodeAt(state.position);
  
      if (ch === terminator) {
        state.position++;
        state.tag = _tag;
        state.anchor = _anchor;
        state.kind = isMapping ? 'mapping' : 'sequence';
        state.result = _result;
        return true;
      } else if (!readNext) {
        throwError(state, 'missed comma between flow collection entries');
      }
  
      keyTag = keyNode = valueNode = null;
      isPair = isExplicitPair = false;
  
      if (ch === 0x3F/* ? */) {
        following = state.input.charCodeAt(state.position + 1);
  
        if (is_WS_OR_EOL(following)) {
          isPair = isExplicitPair = true;
          state.position++;
          skipSeparationSpace(state, true, nodeIndent);
        }
      }
  
      _line = state.line;
      composeNode(state, nodeIndent, CONTEXT_FLOW_IN, false, true);
      keyTag = state.tag;
      keyNode = state.result;
      skipSeparationSpace(state, true, nodeIndent);
  
      ch = state.input.charCodeAt(state.position);
  
      if ((isExplicitPair || state.line === _line) && ch === 0x3A/* : */) {
        isPair = true;
        ch = state.input.charCodeAt(++state.position);
        skipSeparationSpace(state, true, nodeIndent);
        composeNode(state, nodeIndent, CONTEXT_FLOW_IN, false, true);
        valueNode = state.result;
      }
  
      if (isMapping) {
        storeMappingPair(state, _result, overridableKeys, keyTag, keyNode, valueNode);
      } else if (isPair) {
        _result.push(storeMappingPair(state, null, overridableKeys, keyTag, keyNode, valueNode));
      } else {
        _result.push(keyNode);
      }
  
      skipSeparationSpace(state, true, nodeIndent);
  
      ch = state.input.charCodeAt(state.position);
  
      if (ch === 0x2C/* , */) {
        readNext = true;
        ch = state.input.charCodeAt(++state.position);
      } else {
        readNext = false;
      }
    }
  
    throwError(state, 'unexpected end of the stream within a flow collection');
  }
  
  function readBlockScalar(state, nodeIndent) {
    var captureStart,
        folding,
        chomping       = CHOMPING_CLIP,
        didReadContent = false,
        detectedIndent = false,
        textIndent     = nodeIndent,
        emptyLines     = 0,
        atMoreIndented = false,
        tmp,
        ch;
  
    ch = state.input.charCodeAt(state.position);
  
    if (ch === 0x7C/* | */) {
      folding = false;
    } else if (ch === 0x3E/* > */) {
      folding = true;
    } else {
      return false;
    }
  
    state.kind = 'scalar';
    state.result = '';
  
    while (ch !== 0) {
      ch = state.input.charCodeAt(++state.position);
  
      if (ch === 0x2B/* + */ || ch === 0x2D/* - */) {
        if (CHOMPING_CLIP === chomping) {
          chomping = (ch === 0x2B/* + */) ? CHOMPING_KEEP : CHOMPING_STRIP;
        } else {
          throwError(state, 'repeat of a chomping mode identifier');
        }
  
      } else if ((tmp = fromDecimalCode(ch)) >= 0) {
        if (tmp === 0) {
          throwError(state, 'bad explicit indentation width of a block scalar; it cannot be less than one');
        } else if (!detectedIndent) {
          textIndent = nodeIndent + tmp - 1;
          detectedIndent = true;
        } else {
          throwError(state, 'repeat of an indentation width identifier');
        }
  
      } else {
        break;
      }
    }
  
    if (is_WHITE_SPACE(ch)) {
      do { ch = state.input.charCodeAt(++state.position); }
      while (is_WHITE_SPACE(ch));
  
      if (ch === 0x23/* # */) {
        do { ch = state.input.charCodeAt(++state.position); }
        while (!is_EOL(ch) && (ch !== 0));
      }
    }
  
    while (ch !== 0) {
      readLineBreak(state);
      state.lineIndent = 0;
  
      ch = state.input.charCodeAt(state.position);
  
      while ((!detectedIndent || state.lineIndent < textIndent) &&
             (ch === 0x20/* Space */)) {
        state.lineIndent++;
        ch = state.input.charCodeAt(++state.position);
      }
  
      if (!detectedIndent && state.lineIndent > textIndent) {
        textIndent = state.lineIndent;
      }
  
      if (is_EOL(ch)) {
        emptyLines++;
        continue;
      }
  
      // End of the scalar.
      if (state.lineIndent < textIndent) {
  
        // Perform the chomping.
        if (chomping === CHOMPING_KEEP) {
          state.result += common.repeat('\n', didReadContent ? 1 + emptyLines : emptyLines);
        } else if (chomping === CHOMPING_CLIP) {
          if (didReadContent) { // i.e. only if the scalar is not empty.
            state.result += '\n';
          }
        }
  
        // Break this `while` cycle and go to the funciton's epilogue.
        break;
      }
  
      // Folded style: use fancy rules to handle line breaks.
      if (folding) {
  
        // Lines starting with white space characters (more-indented lines) are not folded.
        if (is_WHITE_SPACE(ch)) {
          atMoreIndented = true;
          // except for the first content line (cf. Example 8.1)
          state.result += common.repeat('\n', didReadContent ? 1 + emptyLines : emptyLines);
  
        // End of more-indented block.
        } else if (atMoreIndented) {
          atMoreIndented = false;
          state.result += common.repeat('\n', emptyLines + 1);
  
        // Just one line break - perceive as the same line.
        } else if (emptyLines === 0) {
          if (didReadContent) { // i.e. only if we have already read some scalar content.
            state.result += ' ';
          }
  
        // Several line breaks - perceive as different lines.
        } else {
          state.result += common.repeat('\n', emptyLines);
        }
  
      // Literal style: just add exact number of line breaks between content lines.
      } else {
        // Keep all line breaks except the header line break.
        state.result += common.repeat('\n', didReadContent ? 1 + emptyLines : emptyLines);
      }
  
      didReadContent = true;
      detectedIndent = true;
      emptyLines = 0;
      captureStart = state.position;
  
      while (!is_EOL(ch) && (ch !== 0)) {
        ch = state.input.charCodeAt(++state.position);
      }
  
      captureSegment(state, captureStart, state.position, false);
    }
  
    return true;
  }
  
  function readBlockSequence(state, nodeIndent) {
    var _line,
        _tag      = state.tag,
        _anchor   = state.anchor,
        _result   = [],
        following,
        detected  = false,
        ch;
  
    if (state.anchor !== null) {
      state.anchorMap[state.anchor] = _result;
    }
  
    ch = state.input.charCodeAt(state.position);
  
    while (ch !== 0) {
  
      if (ch !== 0x2D/* - */) {
        break;
      }
  
      following = state.input.charCodeAt(state.position + 1);
  
      if (!is_WS_OR_EOL(following)) {
        break;
      }
  
      detected = true;
      state.position++;
  
      if (skipSeparationSpace(state, true, -1)) {
        if (state.lineIndent <= nodeIndent) {
          _result.push(null);
          ch = state.input.charCodeAt(state.position);
          continue;
        }
      }
  
      _line = state.line;
      composeNode(state, nodeIndent, CONTEXT_BLOCK_IN, false, true);
      _result.push(state.result);
      skipSeparationSpace(state, true, -1);
  
      ch = state.input.charCodeAt(state.position);
  
      if ((state.line === _line || state.lineIndent > nodeIndent) && (ch !== 0)) {
        throwError(state, 'bad indentation of a sequence entry');
      } else if (state.lineIndent < nodeIndent) {
        break;
      }
    }
  
    if (detected) {
      state.tag = _tag;
      state.anchor = _anchor;
      state.kind = 'sequence';
      state.result = _result;
      return true;
    }
    return false;
  }
  
  function readBlockMapping(state, nodeIndent, flowIndent) {
    var following,
        allowCompact,
        _line,
        _pos,
        _tag          = state.tag,
        _anchor       = state.anchor,
        _result       = {},
        overridableKeys = {},
        keyTag        = null,
        keyNode       = null,
        valueNode     = null,
        atExplicitKey = false,
        detected      = false,
        ch;
  
    if (state.anchor !== null) {
      state.anchorMap[state.anchor] = _result;
    }
  
    ch = state.input.charCodeAt(state.position);
  
    while (ch !== 0) {
      following = state.input.charCodeAt(state.position + 1);
      _line = state.line; // Save the current line.
      _pos = state.position;
  
      //
      // Explicit notation case. There are two separate blocks:
      // first for the key (denoted by "?") and second for the value (denoted by ":")
      //
      if ((ch === 0x3F/* ? */ || ch === 0x3A/* : */) && is_WS_OR_EOL(following)) {
  
        if (ch === 0x3F/* ? */) {
          if (atExplicitKey) {
            storeMappingPair(state, _result, overridableKeys, keyTag, keyNode, null);
            keyTag = keyNode = valueNode = null;
          }
  
          detected = true;
          atExplicitKey = true;
          allowCompact = true;
  
        } else if (atExplicitKey) {
          // i.e. 0x3A/* : */ === character after the explicit key.
          atExplicitKey = false;
          allowCompact = true;
  
        } else {
          throwError(state, 'incomplete explicit mapping pair; a key node is missed; or followed by a non-tabulated empty line');
        }
  
        state.position += 1;
        ch = following;
  
      //
      // Implicit notation case. Flow-style node as the key first, then ":", and the value.
      //
      } else if (composeNode(state, flowIndent, CONTEXT_FLOW_OUT, false, true)) {
  
        if (state.line === _line) {
          ch = state.input.charCodeAt(state.position);
  
          while (is_WHITE_SPACE(ch)) {
            ch = state.input.charCodeAt(++state.position);
          }
  
          if (ch === 0x3A/* : */) {
            ch = state.input.charCodeAt(++state.position);
  
            if (!is_WS_OR_EOL(ch)) {
              throwError(state, 'a whitespace character is expected after the key-value separator within a block mapping');
            }
  
            if (atExplicitKey) {
              storeMappingPair(state, _result, overridableKeys, keyTag, keyNode, null);
              keyTag = keyNode = valueNode = null;
            }
  
            detected = true;
            atExplicitKey = false;
            allowCompact = false;
            keyTag = state.tag;
            keyNode = state.result;
  
          } else if (detected) {
            throwError(state, 'can not read an implicit mapping pair; a colon is missed');
  
          } else {
            state.tag = _tag;
            state.anchor = _anchor;
            return true; // Keep the result of `composeNode`.
          }
  
        } else if (detected) {
          throwError(state, 'can not read a block mapping entry; a multiline key may not be an implicit key');
  
        } else {
          state.tag = _tag;
          state.anchor = _anchor;
          return true; // Keep the result of `composeNode`.
        }
  
      } else {
        break; // Reading is done. Go to the epilogue.
      }
  
      //
      // Common reading code for both explicit and implicit notations.
      //
      if (state.line === _line || state.lineIndent > nodeIndent) {
        if (composeNode(state, nodeIndent, CONTEXT_BLOCK_OUT, true, allowCompact)) {
          if (atExplicitKey) {
            keyNode = state.result;
          } else {
            valueNode = state.result;
          }
        }
  
        if (!atExplicitKey) {
          storeMappingPair(state, _result, overridableKeys, keyTag, keyNode, valueNode, _line, _pos);
          keyTag = keyNode = valueNode = null;
        }
  
        skipSeparationSpace(state, true, -1);
        ch = state.input.charCodeAt(state.position);
      }
  
      if (state.lineIndent > nodeIndent && (ch !== 0)) {
        throwError(state, 'bad indentation of a mapping entry');
      } else if (state.lineIndent < nodeIndent) {
        break;
      }
    }
  
    //
    // Epilogue.
    //
  
    // Special case: last mapping's node contains only the key in explicit notation.
    if (atExplicitKey) {
      storeMappingPair(state, _result, overridableKeys, keyTag, keyNode, null);
    }
  
    // Expose the resulting mapping.
    if (detected) {
      state.tag = _tag;
      state.anchor = _anchor;
      state.kind = 'mapping';
      state.result = _result;
    }
  
    return detected;
  }
  
  function readTagProperty(state) {
    var _position,
        isVerbatim = false,
        isNamed    = false,
        tagHandle,
        tagName,
        ch;
  
    ch = state.input.charCodeAt(state.position);
  
    if (ch !== 0x21/* ! */) return false;
  
    if (state.tag !== null) {
      throwError(state, 'duplication of a tag property');
    }
  
    ch = state.input.charCodeAt(++state.position);
  
    if (ch === 0x3C/* < */) {
      isVerbatim = true;
      ch = state.input.charCodeAt(++state.position);
  
    } else if (ch === 0x21/* ! */) {
      isNamed = true;
      tagHandle = '!!';
      ch = state.input.charCodeAt(++state.position);
  
    } else {
      tagHandle = '!';
    }
  
    _position = state.position;
  
    if (isVerbatim) {
      do { ch = state.input.charCodeAt(++state.position); }
      while (ch !== 0 && ch !== 0x3E/* > */);
  
      if (state.position < state.length) {
        tagName = state.input.slice(_position, state.position);
        ch = state.input.charCodeAt(++state.position);
      } else {
        throwError(state, 'unexpected end of the stream within a verbatim tag');
      }
    } else {
      while (ch !== 0 && !is_WS_OR_EOL(ch)) {
  
        if (ch === 0x21/* ! */) {
          if (!isNamed) {
            tagHandle = state.input.slice(_position - 1, state.position + 1);
  
            if (!PATTERN_TAG_HANDLE.test(tagHandle)) {
              throwError(state, 'named tag handle cannot contain such characters');
            }
  
            isNamed = true;
            _position = state.position + 1;
          } else {
            throwError(state, 'tag suffix cannot contain exclamation marks');
          }
        }
  
        ch = state.input.charCodeAt(++state.position);
      }
  
      tagName = state.input.slice(_position, state.position);
  
      if (PATTERN_FLOW_INDICATORS.test(tagName)) {
        throwError(state, 'tag suffix cannot contain flow indicator characters');
      }
    }
  
    if (tagName && !PATTERN_TAG_URI.test(tagName)) {
      throwError(state, 'tag name cannot contain such characters: ' + tagName);
    }
  
    if (isVerbatim) {
      state.tag = tagName;
  
    } else if (_hasOwnProperty.call(state.tagMap, tagHandle)) {
      state.tag = state.tagMap[tagHandle] + tagName;
  
    } else if (tagHandle === '!') {
      state.tag = '!' + tagName;
  
    } else if (tagHandle === '!!') {
      state.tag = 'tag:yaml.org,2002:' + tagName;
  
    } else {
      throwError(state, 'undeclared tag handle "' + tagHandle + '"');
    }
  
    return true;
  }
  
  function readAnchorProperty(state) {
    var _position,
        ch;
  
    ch = state.input.charCodeAt(state.position);
  
    if (ch !== 0x26/* & */) return false;
  
    if (state.anchor !== null) {
      throwError(state, 'duplication of an anchor property');
    }
  
    ch = state.input.charCodeAt(++state.position);
    _position = state.position;
  
    while (ch !== 0 && !is_WS_OR_EOL(ch) && !is_FLOW_INDICATOR(ch)) {
      ch = state.input.charCodeAt(++state.position);
    }
  
    if (state.position === _position) {
      throwError(state, 'name of an anchor node must contain at least one character');
    }
  
    state.anchor = state.input.slice(_position, state.position);
    return true;
  }
  
  function readAlias(state) {
    var _position, alias,
        ch;
  
    ch = state.input.charCodeAt(state.position);
  
    if (ch !== 0x2A/* * */) return false;
  
    ch = state.input.charCodeAt(++state.position);
    _position = state.position;
  
    while (ch !== 0 && !is_WS_OR_EOL(ch) && !is_FLOW_INDICATOR(ch)) {
      ch = state.input.charCodeAt(++state.position);
    }
  
    if (state.position === _position) {
      throwError(state, 'name of an alias node must contain at least one character');
    }
  
    alias = state.input.slice(_position, state.position);
  
    if (!state.anchorMap.hasOwnProperty(alias)) {
      throwError(state, 'unidentified alias "' + alias + '"');
    }
  
    state.result = state.anchorMap[alias];
    skipSeparationSpace(state, true, -1);
    return true;
  }
  
  function composeNode(state, parentIndent, nodeContext, allowToSeek, allowCompact) {
    var allowBlockStyles,
        allowBlockScalars,
        allowBlockCollections,
        indentStatus = 1, // 1: this>parent, 0: this=parent, -1: this<parent
        atNewLine  = false,
        hasContent = false,
        typeIndex,
        typeQuantity,
        type,
        flowIndent,
        blockIndent;
  
    if (state.listener !== null) {
      state.listener('open', state);
    }
  
    state.tag    = null;
    state.anchor = null;
    state.kind   = null;
    state.result = null;
  
    allowBlockStyles = allowBlockScalars = allowBlockCollections =
      CONTEXT_BLOCK_OUT === nodeContext ||
      CONTEXT_BLOCK_IN  === nodeContext;
  
    if (allowToSeek) {
      if (skipSeparationSpace(state, true, -1)) {
        atNewLine = true;
  
        if (state.lineIndent > parentIndent) {
          indentStatus = 1;
        } else if (state.lineIndent === parentIndent) {
          indentStatus = 0;
        } else if (state.lineIndent < parentIndent) {
          indentStatus = -1;
        }
      }
    }
  
    if (indentStatus === 1) {
      while (readTagProperty(state) || readAnchorProperty(state)) {
        if (skipSeparationSpace(state, true, -1)) {
          atNewLine = true;
          allowBlockCollections = allowBlockStyles;
  
          if (state.lineIndent > parentIndent) {
            indentStatus = 1;
          } else if (state.lineIndent === parentIndent) {
            indentStatus = 0;
          } else if (state.lineIndent < parentIndent) {
            indentStatus = -1;
          }
        } else {
          allowBlockCollections = false;
        }
      }
    }
  
    if (allowBlockCollections) {
      allowBlockCollections = atNewLine || allowCompact;
    }
  
    if (indentStatus === 1 || CONTEXT_BLOCK_OUT === nodeContext) {
      if (CONTEXT_FLOW_IN === nodeContext || CONTEXT_FLOW_OUT === nodeContext) {
        flowIndent = parentIndent;
      } else {
        flowIndent = parentIndent + 1;
      }
  
      blockIndent = state.position - state.lineStart;
  
      if (indentStatus === 1) {
        if (allowBlockCollections &&
            (readBlockSequence(state, blockIndent) ||
             readBlockMapping(state, blockIndent, flowIndent)) ||
            readFlowCollection(state, flowIndent)) {
          hasContent = true;
        } else {
          if ((allowBlockScalars && readBlockScalar(state, flowIndent)) ||
              readSingleQuotedScalar(state, flowIndent) ||
              readDoubleQuotedScalar(state, flowIndent)) {
            hasContent = true;
  
          } else if (readAlias(state)) {
            hasContent = true;
  
            if (state.tag !== null || state.anchor !== null) {
              throwError(state, 'alias node should not have any properties');
            }
  
          } else if (readPlainScalar(state, flowIndent, CONTEXT_FLOW_IN === nodeContext)) {
            hasContent = true;
  
            if (state.tag === null) {
              state.tag = '?';
            }
          }
  
          if (state.anchor !== null) {
            state.anchorMap[state.anchor] = state.result;
          }
        }
      } else if (indentStatus === 0) {
        // Special case: block sequences are allowed to have same indentation level as the parent.
        // http://www.yaml.org/spec/1.2/spec.html#id2799784
        hasContent = allowBlockCollections && readBlockSequence(state, blockIndent);
      }
    }
  
    if (state.tag !== null && state.tag !== '!') {
      if (state.tag === '?') {
        for (typeIndex = 0, typeQuantity = state.implicitTypes.length; typeIndex < typeQuantity; typeIndex += 1) {
          type = state.implicitTypes[typeIndex];
  
          // Implicit resolving is not allowed for non-scalar types, and '?'
          // non-specific tag is only assigned to plain scalars. So, it isn't
          // needed to check for 'kind' conformity.
  
          if (type.resolve(state.result)) { // `state.result` updated in resolver if matched
            state.result = type.construct(state.result);
            state.tag = type.tag;
            if (state.anchor !== null) {
              state.anchorMap[state.anchor] = state.result;
            }
            break;
          }
        }
      } else if (_hasOwnProperty.call(state.typeMap[state.kind || 'fallback'], state.tag)) {
        type = state.typeMap[state.kind || 'fallback'][state.tag];
  
        if (state.result !== null && type.kind !== state.kind) {
          throwError(state, 'unacceptable node kind for !<' + state.tag + '> tag; it should be "' + type.kind + '", not "' + state.kind + '"');
        }
  
        if (!type.resolve(state.result)) { // `state.result` updated in resolver if matched
          throwError(state, 'cannot resolve a node with !<' + state.tag + '> explicit tag');
        } else {
          state.result = type.construct(state.result);
          if (state.anchor !== null) {
            state.anchorMap[state.anchor] = state.result;
          }
        }
      } else {
        throwError(state, 'unknown tag !<' + state.tag + '>');
      }
    }
  
    if (state.listener !== null) {
      state.listener('close', state);
    }
    return state.tag !== null ||  state.anchor !== null || hasContent;
  }
  
  function readDocument(state) {
    var documentStart = state.position,
        _position,
        directiveName,
        directiveArgs,
        hasDirectives = false,
        ch;
  
    state.version = null;
    state.checkLineBreaks = state.legacy;
    state.tagMap = {};
    state.anchorMap = {};
  
    while ((ch = state.input.charCodeAt(state.position)) !== 0) {
      skipSeparationSpace(state, true, -1);
  
      ch = state.input.charCodeAt(state.position);
  
      if (state.lineIndent > 0 || ch !== 0x25/* % */) {
        break;
      }
  
      hasDirectives = true;
      ch = state.input.charCodeAt(++state.position);
      _position = state.position;
  
      while (ch !== 0 && !is_WS_OR_EOL(ch)) {
        ch = state.input.charCodeAt(++state.position);
      }
  
      directiveName = state.input.slice(_position, state.position);
      directiveArgs = [];
  
      if (directiveName.length < 1) {
        throwError(state, 'directive name must not be less than one character in length');
      }
  
      while (ch !== 0) {
        while (is_WHITE_SPACE(ch)) {
          ch = state.input.charCodeAt(++state.position);
        }
  
        if (ch === 0x23/* # */) {
          do { ch = state.input.charCodeAt(++state.position); }
          while (ch !== 0 && !is_EOL(ch));
          break;
        }
  
        if (is_EOL(ch)) break;
  
        _position = state.position;
  
        while (ch !== 0 && !is_WS_OR_EOL(ch)) {
          ch = state.input.charCodeAt(++state.position);
        }
  
        directiveArgs.push(state.input.slice(_position, state.position));
      }
  
      if (ch !== 0) readLineBreak(state);
  
      if (_hasOwnProperty.call(directiveHandlers, directiveName)) {
        directiveHandlers[directiveName](state, directiveName, directiveArgs);
      } else {
        throwWarning(state, 'unknown document directive "' + directiveName + '"');
      }
    }
  
    skipSeparationSpace(state, true, -1);
  
    if (state.lineIndent === 0 &&
        state.input.charCodeAt(state.position)     === 0x2D/* - */ &&
        state.input.charCodeAt(state.position + 1) === 0x2D/* - */ &&
        state.input.charCodeAt(state.position + 2) === 0x2D/* - */) {
      state.position += 3;
      skipSeparationSpace(state, true, -1);
  
    } else if (hasDirectives) {
      throwError(state, 'directives end mark is expected');
    }
  
    composeNode(state, state.lineIndent - 1, CONTEXT_BLOCK_OUT, false, true);
    skipSeparationSpace(state, true, -1);
  
    if (state.checkLineBreaks &&
        PATTERN_NON_ASCII_LINE_BREAKS.test(state.input.slice(documentStart, state.position))) {
      throwWarning(state, 'non-ASCII line breaks are interpreted as content');
    }
  
    state.documents.push(state.result);
  
    if (state.position === state.lineStart && testDocumentSeparator(state)) {
  
      if (state.input.charCodeAt(state.position) === 0x2E/* . */) {
        state.position += 3;
        skipSeparationSpace(state, true, -1);
      }
      return;
    }
  
    if (state.position < (state.length - 1)) {
      throwError(state, 'end of the stream or a document separator is expected');
    } else {
      return;
    }
  }
  
  
  function loadDocuments(input, options) {
    input = String(input);
    options = options || {};
  
    if (input.length !== 0) {
  
      // Add tailing `\n` if not exists
      if (input.charCodeAt(input.length - 1) !== 0x0A/* LF */ &&
          input.charCodeAt(input.length - 1) !== 0x0D/* CR */) {
        input += '\n';
      }
  
      // Strip BOM
      if (input.charCodeAt(0) === 0xFEFF) {
        input = input.slice(1);
      }
    }
  
    var state = new State(input, options);
  
    // Use 0 as string terminator. That significantly simplifies bounds check.
    state.input += '\0';
  
    while (state.input.charCodeAt(state.position) === 0x20/* Space */) {
      state.lineIndent += 1;
      state.position += 1;
    }
  
    while (state.position < (state.length - 1)) {
      readDocument(state);
    }
  
    return state.documents;
  }
  
  
  function loadAll(input, iterator, options) {
    var documents = loadDocuments(input, options), index, length;
  
    if (typeof iterator !== 'function') {
      return documents;
    }
  
    for (index = 0, length = documents.length; index < length; index += 1) {
      iterator(documents[index]);
    }
  }
  
  
  function load(input, options) {
    var documents = loadDocuments(input, options);
  
    if (documents.length === 0) {
      /*eslint-disable no-undefined*/
      return undefined;
    } else if (documents.length === 1) {
      return documents[0];
    }
    throw new YAMLException('expected a single document in the stream, but found more');
  }
  
  
  function safeLoadAll(input, output, options) {
    if (typeof output === 'function') {
      loadAll(input, output, common.extend({ schema: DEFAULT_SAFE_SCHEMA }, options));
    } else {
      return loadAll(input, common.extend({ schema: DEFAULT_SAFE_SCHEMA }, options));
    }
  }
  
  
  function safeLoad(input, options) {
    return load(input, common.extend({ schema: DEFAULT_SAFE_SCHEMA }, options));
  }
  
  
  module.exports.loadAll     = loadAll;
  module.exports.load        = load;
  module.exports.safeLoadAll = safeLoadAll;
  module.exports.safeLoad    = safeLoad;
  
  },{"./common":2,"./exception":4,"./mark":6,"./schema/default_full":9,"./schema/default_safe":10}],6:[function(require,module,exports){
  'use strict';
  
  
  var common = require('./common');
  
  
  function Mark(name, buffer, position, line, column) {
    this.name     = name;
    this.buffer   = buffer;
    this.position = position;
    this.line     = line;
    this.column   = column;
  }
  
  
  Mark.prototype.getSnippet = function getSnippet(indent, maxLength) {
    var head, start, tail, end, snippet;
  
    if (!this.buffer) return null;
  
    indent = indent || 4;
    maxLength = maxLength || 75;
  
    head = '';
    start = this.position;
  
    while (start > 0 && '\x00\r\n\x85\u2028\u2029'.indexOf(this.buffer.charAt(start - 1)) === -1) {
      start -= 1;
      if (this.position - start > (maxLength / 2 - 1)) {
        head = ' ... ';
        start += 5;
        break;
      }
    }
  
    tail = '';
    end = this.position;
  
    while (end < this.buffer.length && '\x00\r\n\x85\u2028\u2029'.indexOf(this.buffer.charAt(end)) === -1) {
      end += 1;
      if (end - this.position > (maxLength / 2 - 1)) {
        tail = ' ... ';
        end -= 5;
        break;
      }
    }
  
    snippet = this.buffer.slice(start, end);
  
    return common.repeat(' ', indent) + head + snippet + tail + '\n' +
           common.repeat(' ', indent + this.position - start + head.length) + '^';
  };
  
  
  Mark.prototype.toString = function toString(compact) {
    var snippet, where = '';
  
    if (this.name) {
      where += 'in "' + this.name + '" ';
    }
  
    where += 'at line ' + (this.line + 1) + ', column ' + (this.column + 1);
  
    if (!compact) {
      snippet = this.getSnippet();
  
      if (snippet) {
        where += ':\n' + snippet;
      }
    }
  
    return where;
  };
  
  
  module.exports = Mark;
  
  },{"./common":2}],7:[function(require,module,exports){
  'use strict';
  
  /*eslint-disable max-len*/
  
  var common        = require('./common');
  var YAMLException = require('./exception');
  var Type          = require('./type');
  
  
  function compileList(schema, name, result) {
    var exclude = [];
  
    schema.include.forEach(function (includedSchema) {
      result = compileList(includedSchema, name, result);
    });
  
    schema[name].forEach(function (currentType) {
      result.forEach(function (previousType, previousIndex) {
        if (previousType.tag === currentType.tag && previousType.kind === currentType.kind) {
          exclude.push(previousIndex);
        }
      });
  
      result.push(currentType);
    });
  
    return result.filter(function (type, index) {
      return exclude.indexOf(index) === -1;
    });
  }
  
  
  function compileMap(/* lists... */) {
    var result = {
          scalar: {},
          sequence: {},
          mapping: {},
          fallback: {}
        }, index, length;
  
    function collectType(type) {
      result[type.kind][type.tag] = result['fallback'][type.tag] = type;
    }
  
    for (index = 0, length = arguments.length; index < length; index += 1) {
      arguments[index].forEach(collectType);
    }
    return result;
  }
  
  
  function Schema(definition) {
    this.include  = definition.include  || [];
    this.implicit = definition.implicit || [];
    this.explicit = definition.explicit || [];
  
    this.implicit.forEach(function (type) {
      if (type.loadKind && type.loadKind !== 'scalar') {
        throw new YAMLException('There is a non-scalar type in the implicit list of a schema. Implicit resolving of such types is not supported.');
      }
    });
  
    this.compiledImplicit = compileList(this, 'implicit', []);
    this.compiledExplicit = compileList(this, 'explicit', []);
    this.compiledTypeMap  = compileMap(this.compiledImplicit, this.compiledExplicit);
  }
  
  
  Schema.DEFAULT = null;
  
  
  Schema.create = function createSchema() {
    var schemas, types;
  
    switch (arguments.length) {
      case 1:
        schemas = Schema.DEFAULT;
        types = arguments[0];
        break;
  
      case 2:
        schemas = arguments[0];
        types = arguments[1];
        break;
  
      default:
        throw new YAMLException('Wrong number of arguments for Schema.create function');
    }
  
    schemas = common.toArray(schemas);
    types = common.toArray(types);
  
    if (!schemas.every(function (schema) { return schema instanceof Schema; })) {
      throw new YAMLException('Specified list of super schemas (or a single Schema object) contains a non-Schema object.');
    }
  
    if (!types.every(function (type) { return type instanceof Type; })) {
      throw new YAMLException('Specified list of YAML types (or a single Type object) contains a non-Type object.');
    }
  
    return new Schema({
      include: schemas,
      explicit: types
    });
  };
  
  
  module.exports = Schema;
  
  },{"./common":2,"./exception":4,"./type":13}],8:[function(require,module,exports){
  // Standard YAML's Core schema.
  // http://www.yaml.org/spec/1.2/spec.html#id2804923
  //
  // NOTE: JS-YAML does not support schema-specific tag resolution restrictions.
  // So, Core schema has no distinctions from JSON schema is JS-YAML.
  
  
  'use strict';
  
  
  var Schema = require('../schema');
  
  
  module.exports = new Schema({
    include: [
      require('./json')
    ]
  });
  
  },{"../schema":7,"./json":12}],9:[function(require,module,exports){
  // JS-YAML's default schema for `load` function.
  // It is not described in the YAML specification.
  //
  // This schema is based on JS-YAML's default safe schema and includes
  // JavaScript-specific types: !!js/undefined, !!js/regexp and !!js/function.
  //
  // Also this schema is used as default base schema at `Schema.create` function.
  
  
  'use strict';
  
  
  var Schema = require('../schema');
  
  
  module.exports = Schema.DEFAULT = new Schema({
    include: [
      require('./default_safe')
    ],
    explicit: [
      require('../type/js/undefined'),
      require('../type/js/regexp'),
      require('../type/js/function')
    ]
  });
  
  },{"../schema":7,"../type/js/function":18,"../type/js/regexp":19,"../type/js/undefined":20,"./default_safe":10}],10:[function(require,module,exports){
  // JS-YAML's default schema for `safeLoad` function.
  // It is not described in the YAML specification.
  //
  // This schema is based on standard YAML's Core schema and includes most of
  // extra types described at YAML tag repository. (http://yaml.org/type/)
  
  
  'use strict';
  
  
  var Schema = require('../schema');
  
  
  module.exports = new Schema({
    include: [
      require('./core')
    ],
    implicit: [
      require('../type/timestamp'),
      require('../type/merge')
    ],
    explicit: [
      require('../type/binary'),
      require('../type/omap'),
      require('../type/pairs'),
      require('../type/set')
    ]
  });
  
  },{"../schema":7,"../type/binary":14,"../type/merge":22,"../type/omap":24,"../type/pairs":25,"../type/set":27,"../type/timestamp":29,"./core":8}],11:[function(require,module,exports){
  // Standard YAML's Failsafe schema.
  // http://www.yaml.org/spec/1.2/spec.html#id2802346
  
  
  'use strict';
  
  
  var Schema = require('../schema');
  
  
  module.exports = new Schema({
    explicit: [
      require('../type/str'),
      require('../type/seq'),
      require('../type/map')
    ]
  });
  
  },{"../schema":7,"../type/map":21,"../type/seq":26,"../type/str":28}],12:[function(require,module,exports){
  // Standard YAML's JSON schema.
  // http://www.yaml.org/spec/1.2/spec.html#id2803231
  //
  // NOTE: JS-YAML does not support schema-specific tag resolution restrictions.
  // So, this schema is not such strict as defined in the YAML specification.
  // It allows numbers in binary notaion, use `Null` and `NULL` as `null`, etc.
  
  
  'use strict';
  
  
  var Schema = require('../schema');
  
  
  module.exports = new Schema({
    include: [
      require('./failsafe')
    ],
    implicit: [
      require('../type/null'),
      require('../type/bool'),
      require('../type/int'),
      require('../type/float')
    ]
  });
  
  },{"../schema":7,"../type/bool":15,"../type/float":16,"../type/int":17,"../type/null":23,"./failsafe":11}],13:[function(require,module,exports){
  'use strict';
  
  var YAMLException = require('./exception');
  
  var TYPE_CONSTRUCTOR_OPTIONS = [
    'kind',
    'resolve',
    'construct',
    'instanceOf',
    'predicate',
    'represent',
    'defaultStyle',
    'styleAliases'
  ];
  
  var YAML_NODE_KINDS = [
    'scalar',
    'sequence',
    'mapping'
  ];
  
  function compileStyleAliases(map) {
    var result = {};
  
    if (map !== null) {
      Object.keys(map).forEach(function (style) {
        map[style].forEach(function (alias) {
          result[String(alias)] = style;
        });
      });
    }
  
    return result;
  }
  
  function Type(tag, options) {
    options = options || {};
  
    Object.keys(options).forEach(function (name) {
      if (TYPE_CONSTRUCTOR_OPTIONS.indexOf(name) === -1) {
        throw new YAMLException('Unknown option "' + name + '" is met in definition of "' + tag + '" YAML type.');
      }
    });
  
    // TODO: Add tag format check.
    this.tag          = tag;
    this.kind         = options['kind']         || null;
    this.resolve      = options['resolve']      || function () { return true; };
    this.construct    = options['construct']    || function (data) { return data; };
    this.instanceOf   = options['instanceOf']   || null;
    this.predicate    = options['predicate']    || null;
    this.represent    = options['represent']    || null;
    this.defaultStyle = options['defaultStyle'] || null;
    this.styleAliases = compileStyleAliases(options['styleAliases'] || null);
  
    if (YAML_NODE_KINDS.indexOf(this.kind) === -1) {
      throw new YAMLException('Unknown kind "' + this.kind + '" is specified for "' + tag + '" YAML type.');
    }
  }
  
  module.exports = Type;
  
  },{"./exception":4}],14:[function(require,module,exports){
  'use strict';
  
  /*eslint-disable no-bitwise*/
  
  var NodeBuffer;
  
  try {
    // A trick for browserified version, to not include `Buffer` shim
    var _require = require;
    NodeBuffer = _require('buffer').Buffer;
  } catch (__) {}
  
  var Type       = require('../type');
  
  
  // [ 64, 65, 66 ] -> [ padding, CR, LF ]
  var BASE64_MAP = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=\n\r';
  
  
  function resolveYamlBinary(data) {
    if (data === null) return false;
  
    var code, idx, bitlen = 0, max = data.length, map = BASE64_MAP;
  
    // Convert one by one.
    for (idx = 0; idx < max; idx++) {
      code = map.indexOf(data.charAt(idx));
  
      // Skip CR/LF
      if (code > 64) continue;
  
      // Fail on illegal characters
      if (code < 0) return false;
  
      bitlen += 6;
    }
  
    // If there are any bits left, source was corrupted
    return (bitlen % 8) === 0;
  }
  
  function constructYamlBinary(data) {
    var idx, tailbits,
        input = data.replace(/[\r\n=]/g, ''), // remove CR/LF & padding to simplify scan
        max = input.length,
        map = BASE64_MAP,
        bits = 0,
        result = [];
  
    // Collect by 6*4 bits (3 bytes)
  
    for (idx = 0; idx < max; idx++) {
      if ((idx % 4 === 0) && idx) {
        result.push((bits >> 16) & 0xFF);
        result.push((bits >> 8) & 0xFF);
        result.push(bits & 0xFF);
      }
  
      bits = (bits << 6) | map.indexOf(input.charAt(idx));
    }
  
    // Dump tail
  
    tailbits = (max % 4) * 6;
  
    if (tailbits === 0) {
      result.push((bits >> 16) & 0xFF);
      result.push((bits >> 8) & 0xFF);
      result.push(bits & 0xFF);
    } else if (tailbits === 18) {
      result.push((bits >> 10) & 0xFF);
      result.push((bits >> 2) & 0xFF);
    } else if (tailbits === 12) {
      result.push((bits >> 4) & 0xFF);
    }
  
    // Wrap into Buffer for NodeJS and leave Array for browser
    if (NodeBuffer) {
      // Support node 6.+ Buffer API when available
      return NodeBuffer.from ? NodeBuffer.from(result) : new NodeBuffer(result);
    }
  
    return result;
  }
  
  function representYamlBinary(object /*, style*/) {
    var result = '', bits = 0, idx, tail,
        max = object.length,
        map = BASE64_MAP;
  
    // Convert every three bytes to 4 ASCII characters.
  
    for (idx = 0; idx < max; idx++) {
      if ((idx % 3 === 0) && idx) {
        result += map[(bits >> 18) & 0x3F];
        result += map[(bits >> 12) & 0x3F];
        result += map[(bits >> 6) & 0x3F];
        result += map[bits & 0x3F];
      }
  
      bits = (bits << 8) + object[idx];
    }
  
    // Dump tail
  
    tail = max % 3;
  
    if (tail === 0) {
      result += map[(bits >> 18) & 0x3F];
      result += map[(bits >> 12) & 0x3F];
      result += map[(bits >> 6) & 0x3F];
      result += map[bits & 0x3F];
    } else if (tail === 2) {
      result += map[(bits >> 10) & 0x3F];
      result += map[(bits >> 4) & 0x3F];
      result += map[(bits << 2) & 0x3F];
      result += map[64];
    } else if (tail === 1) {
      result += map[(bits >> 2) & 0x3F];
      result += map[(bits << 4) & 0x3F];
      result += map[64];
      result += map[64];
    }
  
    return result;
  }
  
  function isBinary(object) {
    return NodeBuffer && NodeBuffer.isBuffer(object);
  }
  
  module.exports = new Type('tag:yaml.org,2002:binary', {
    kind: 'scalar',
    resolve: resolveYamlBinary,
    construct: constructYamlBinary,
    predicate: isBinary,
    represent: representYamlBinary
  });
  
  },{"../type":13}],15:[function(require,module,exports){
  'use strict';
  
  var Type = require('../type');
  
  function resolveYamlBoolean(data) {
    if (data === null) return false;
  
    var max = data.length;
  
    return (max === 4 && (data === 'true' || data === 'True' || data === 'TRUE')) ||
           (max === 5 && (data === 'false' || data === 'False' || data === 'FALSE'));
  }
  
  function constructYamlBoolean(data) {
    return data === 'true' ||
           data === 'True' ||
           data === 'TRUE';
  }
  
  function isBoolean(object) {
    return Object.prototype.toString.call(object) === '[object Boolean]';
  }
  
  module.exports = new Type('tag:yaml.org,2002:bool', {
    kind: 'scalar',
    resolve: resolveYamlBoolean,
    construct: constructYamlBoolean,
    predicate: isBoolean,
    represent: {
      lowercase: function (object) { return object ? 'true' : 'false'; },
      uppercase: function (object) { return object ? 'TRUE' : 'FALSE'; },
      camelcase: function (object) { return object ? 'True' : 'False'; }
    },
    defaultStyle: 'lowercase'
  });
  
  },{"../type":13}],16:[function(require,module,exports){
  'use strict';
  
  var common = require('../common');
  var Type   = require('../type');
  
  var YAML_FLOAT_PATTERN = new RegExp(
    // 2.5e4, 2.5 and integers
    '^(?:[-+]?(?:0|[1-9][0-9_]*)(?:\\.[0-9_]*)?(?:[eE][-+]?[0-9]+)?' +
    // .2e4, .2
    // special case, seems not from spec
    '|\\.[0-9_]+(?:[eE][-+]?[0-9]+)?' +
    // 20:59
    '|[-+]?[0-9][0-9_]*(?::[0-5]?[0-9])+\\.[0-9_]*' +
    // .inf
    '|[-+]?\\.(?:inf|Inf|INF)' +
    // .nan
    '|\\.(?:nan|NaN|NAN))$');
  
  function resolveYamlFloat(data) {
    if (data === null) return false;
  
    if (!YAML_FLOAT_PATTERN.test(data) ||
        // Quick hack to not allow integers end with `_`
        // Probably should update regexp & check speed
        data[data.length - 1] === '_') {
      return false;
    }
  
    return true;
  }
  
  function constructYamlFloat(data) {
    var value, sign, base, digits;
  
    value  = data.replace(/_/g, '').toLowerCase();
    sign   = value[0] === '-' ? -1 : 1;
    digits = [];
  
    if ('+-'.indexOf(value[0]) >= 0) {
      value = value.slice(1);
    }
  
    if (value === '.inf') {
      return (sign === 1) ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
  
    } else if (value === '.nan') {
      return NaN;
  
    } else if (value.indexOf(':') >= 0) {
      value.split(':').forEach(function (v) {
        digits.unshift(parseFloat(v, 10));
      });
  
      value = 0.0;
      base = 1;
  
      digits.forEach(function (d) {
        value += d * base;
        base *= 60;
      });
  
      return sign * value;
  
    }
    return sign * parseFloat(value, 10);
  }
  
  
  var SCIENTIFIC_WITHOUT_DOT = /^[-+]?[0-9]+e/;
  
  function representYamlFloat(object, style) {
    var res;
  
    if (isNaN(object)) {
      switch (style) {
        case 'lowercase': return '.nan';
        case 'uppercase': return '.NAN';
        case 'camelcase': return '.NaN';
      }
    } else if (Number.POSITIVE_INFINITY === object) {
      switch (style) {
        case 'lowercase': return '.inf';
        case 'uppercase': return '.INF';
        case 'camelcase': return '.Inf';
      }
    } else if (Number.NEGATIVE_INFINITY === object) {
      switch (style) {
        case 'lowercase': return '-.inf';
        case 'uppercase': return '-.INF';
        case 'camelcase': return '-.Inf';
      }
    } else if (common.isNegativeZero(object)) {
      return '-0.0';
    }
  
    res = object.toString(10);
  
    // JS stringifier can build scientific format without dots: 5e-100,
    // while YAML requres dot: 5.e-100. Fix it with simple hack
  
    return SCIENTIFIC_WITHOUT_DOT.test(res) ? res.replace('e', '.e') : res;
  }
  
  function isFloat(object) {
    return (Object.prototype.toString.call(object) === '[object Number]') &&
           (object % 1 !== 0 || common.isNegativeZero(object));
  }
  
  module.exports = new Type('tag:yaml.org,2002:float', {
    kind: 'scalar',
    resolve: resolveYamlFloat,
    construct: constructYamlFloat,
    predicate: isFloat,
    represent: representYamlFloat,
    defaultStyle: 'lowercase'
  });
  
  },{"../common":2,"../type":13}],17:[function(require,module,exports){
  'use strict';
  
  var common = require('../common');
  var Type   = require('../type');
  
  function isHexCode(c) {
    return ((0x30/* 0 */ <= c) && (c <= 0x39/* 9 */)) ||
           ((0x41/* A */ <= c) && (c <= 0x46/* F */)) ||
           ((0x61/* a */ <= c) && (c <= 0x66/* f */));
  }
  
  function isOctCode(c) {
    return ((0x30/* 0 */ <= c) && (c <= 0x37/* 7 */));
  }
  
  function isDecCode(c) {
    return ((0x30/* 0 */ <= c) && (c <= 0x39/* 9 */));
  }
  
  function resolveYamlInteger(data) {
    if (data === null) return false;
  
    var max = data.length,
        index = 0,
        hasDigits = false,
        ch;
  
    if (!max) return false;
  
    ch = data[index];
  
    // sign
    if (ch === '-' || ch === '+') {
      ch = data[++index];
    }
  
    if (ch === '0') {
      // 0
      if (index + 1 === max) return true;
      ch = data[++index];
  
      // base 2, base 8, base 16
  
      if (ch === 'b') {
        // base 2
        index++;
  
        for (; index < max; index++) {
          ch = data[index];
          if (ch === '_') continue;
          if (ch !== '0' && ch !== '1') return false;
          hasDigits = true;
        }
        return hasDigits && ch !== '_';
      }
  
  
      if (ch === 'x') {
        // base 16
        index++;
  
        for (; index < max; index++) {
          ch = data[index];
          if (ch === '_') continue;
          if (!isHexCode(data.charCodeAt(index))) return false;
          hasDigits = true;
        }
        return hasDigits && ch !== '_';
      }
  
      // base 8
      for (; index < max; index++) {
        ch = data[index];
        if (ch === '_') continue;
        if (!isOctCode(data.charCodeAt(index))) return false;
        hasDigits = true;
      }
      return hasDigits && ch !== '_';
    }
  
    // base 10 (except 0) or base 60
  
    // value should not start with `_`;
    if (ch === '_') return false;
  
    for (; index < max; index++) {
      ch = data[index];
      if (ch === '_') continue;
      if (ch === ':') break;
      if (!isDecCode(data.charCodeAt(index))) {
        return false;
      }
      hasDigits = true;
    }
  
    // Should have digits and should not end with `_`
    if (!hasDigits || ch === '_') return false;
  
    // if !base60 - done;
    if (ch !== ':') return true;
  
    // base60 almost not used, no needs to optimize
    return /^(:[0-5]?[0-9])+$/.test(data.slice(index));
  }
  
  function constructYamlInteger(data) {
    var value = data, sign = 1, ch, base, digits = [];
  
    if (value.indexOf('_') !== -1) {
      value = value.replace(/_/g, '');
    }
  
    ch = value[0];
  
    if (ch === '-' || ch === '+') {
      if (ch === '-') sign = -1;
      value = value.slice(1);
      ch = value[0];
    }
  
    if (value === '0') return 0;
  
    if (ch === '0') {
      if (value[1] === 'b') return sign * parseInt(value.slice(2), 2);
      if (value[1] === 'x') return sign * parseInt(value, 16);
      return sign * parseInt(value, 8);
    }
  
    if (value.indexOf(':') !== -1) {
      value.split(':').forEach(function (v) {
        digits.unshift(parseInt(v, 10));
      });
  
      value = 0;
      base = 1;
  
      digits.forEach(function (d) {
        value += (d * base);
        base *= 60;
      });
  
      return sign * value;
  
    }
  
    return sign * parseInt(value, 10);
  }
  
  function isInteger(object) {
    return (Object.prototype.toString.call(object)) === '[object Number]' &&
           (object % 1 === 0 && !common.isNegativeZero(object));
  }
  
  module.exports = new Type('tag:yaml.org,2002:int', {
    kind: 'scalar',
    resolve: resolveYamlInteger,
    construct: constructYamlInteger,
    predicate: isInteger,
    represent: {
      binary:      function (obj) { return obj >= 0 ? '0b' + obj.toString(2) : '-0b' + obj.toString(2).slice(1); },
      octal:       function (obj) { return obj >= 0 ? '0'  + obj.toString(8) : '-0'  + obj.toString(8).slice(1); },
      decimal:     function (obj) { return obj.toString(10); },
      /* eslint-disable max-len */
      hexadecimal: function (obj) { return obj >= 0 ? '0x' + obj.toString(16).toUpperCase() :  '-0x' + obj.toString(16).toUpperCase().slice(1); }
    },
    defaultStyle: 'decimal',
    styleAliases: {
      binary:      [ 2,  'bin' ],
      octal:       [ 8,  'oct' ],
      decimal:     [ 10, 'dec' ],
      hexadecimal: [ 16, 'hex' ]
    }
  });
  
  },{"../common":2,"../type":13}],18:[function(require,module,exports){
  'use strict';
  
  var esprima;
  
  // Browserified version does not have esprima
  //
  // 1. For node.js just require module as deps
  // 2. For browser try to require mudule via external AMD system.
  //    If not found - try to fallback to window.esprima. If not
  //    found too - then fail to parse.
  //
  try {
    // workaround to exclude package from browserify list.
    var _require = require;
    esprima = _require('esprima');
  } catch (_) {
    /*global window */
    if (typeof window !== 'undefined') esprima = window.esprima;
  }
  
  var Type = require('../../type');
  
  function resolveJavascriptFunction(data) {
    if (data === null) return false;
  
    try {
      var source = '(' + data + ')',
          ast    = esprima.parse(source, { range: true });
  
      if (ast.type                    !== 'Program'             ||
          ast.body.length             !== 1                     ||
          ast.body[0].type            !== 'ExpressionStatement' ||
          (ast.body[0].expression.type !== 'ArrowFunctionExpression' &&
            ast.body[0].expression.type !== 'FunctionExpression')) {
        return false;
      }
  
      return true;
    } catch (err) {
      return false;
    }
  }
  
  function constructJavascriptFunction(data) {
    /*jslint evil:true*/
  
    var source = '(' + data + ')',
        ast    = esprima.parse(source, { range: true }),
        params = [],
        body;
  
    if (ast.type                    !== 'Program'             ||
        ast.body.length             !== 1                     ||
        ast.body[0].type            !== 'ExpressionStatement' ||
        (ast.body[0].expression.type !== 'ArrowFunctionExpression' &&
          ast.body[0].expression.type !== 'FunctionExpression')) {
      throw new Error('Failed to resolve function');
    }
  
    ast.body[0].expression.params.forEach(function (param) {
      params.push(param.name);
    });
  
    body = ast.body[0].expression.body.range;
  
    // Esprima's ranges include the first '{' and the last '}' characters on
    // function expressions. So cut them out.
    /*eslint-disable no-new-func*/
    return new Function(params, source.slice(body[0] + 1, body[1] - 1));
  }
  
  function representJavascriptFunction(object /*, style*/) {
    return object.toString();
  }
  
  function isFunction(object) {
    return Object.prototype.toString.call(object) === '[object Function]';
  }
  
  module.exports = new Type('tag:yaml.org,2002:js/function', {
    kind: 'scalar',
    resolve: resolveJavascriptFunction,
    construct: constructJavascriptFunction,
    predicate: isFunction,
    represent: representJavascriptFunction
  });
  
  },{"../../type":13}],19:[function(require,module,exports){
  'use strict';
  
  var Type = require('../../type');
  
  function resolveJavascriptRegExp(data) {
    if (data === null) return false;
    if (data.length === 0) return false;
  
    var regexp = data,
        tail   = /\/([gim]*)$/.exec(data),
        modifiers = '';
  
    // if regexp starts with '/' it can have modifiers and must be properly closed
    // `/foo/gim` - modifiers tail can be maximum 3 chars
    if (regexp[0] === '/') {
      if (tail) modifiers = tail[1];
  
      if (modifiers.length > 3) return false;
      // if expression starts with /, is should be properly terminated
      if (regexp[regexp.length - modifiers.length - 1] !== '/') return false;
    }
  
    return true;
  }
  
  function constructJavascriptRegExp(data) {
    var regexp = data,
        tail   = /\/([gim]*)$/.exec(data),
        modifiers = '';
  
    // `/foo/gim` - tail can be maximum 4 chars
    if (regexp[0] === '/') {
      if (tail) modifiers = tail[1];
      regexp = regexp.slice(1, regexp.length - modifiers.length - 1);
    }
  
    return new RegExp(regexp, modifiers);
  }
  
  function representJavascriptRegExp(object /*, style*/) {
    var result = '/' + object.source + '/';
  
    if (object.global) result += 'g';
    if (object.multiline) result += 'm';
    if (object.ignoreCase) result += 'i';
  
    return result;
  }
  
  function isRegExp(object) {
    return Object.prototype.toString.call(object) === '[object RegExp]';
  }
  
  module.exports = new Type('tag:yaml.org,2002:js/regexp', {
    kind: 'scalar',
    resolve: resolveJavascriptRegExp,
    construct: constructJavascriptRegExp,
    predicate: isRegExp,
    represent: representJavascriptRegExp
  });
  
  },{"../../type":13}],20:[function(require,module,exports){
  'use strict';
  
  var Type = require('../../type');
  
  function resolveJavascriptUndefined() {
    return true;
  }
  
  function constructJavascriptUndefined() {
    /*eslint-disable no-undefined*/
    return undefined;
  }
  
  function representJavascriptUndefined() {
    return '';
  }
  
  function isUndefined(object) {
    return typeof object === 'undefined';
  }
  
  module.exports = new Type('tag:yaml.org,2002:js/undefined', {
    kind: 'scalar',
    resolve: resolveJavascriptUndefined,
    construct: constructJavascriptUndefined,
    predicate: isUndefined,
    represent: representJavascriptUndefined
  });
  
  },{"../../type":13}],21:[function(require,module,exports){
  'use strict';
  
  var Type = require('../type');
  
  module.exports = new Type('tag:yaml.org,2002:map', {
    kind: 'mapping',
    construct: function (data) { return data !== null ? data : {}; }
  });
  
  },{"../type":13}],22:[function(require,module,exports){
  'use strict';
  
  var Type = require('../type');
  
  function resolveYamlMerge(data) {
    return data === '<<' || data === null;
  }
  
  module.exports = new Type('tag:yaml.org,2002:merge', {
    kind: 'scalar',
    resolve: resolveYamlMerge
  });
  
  },{"../type":13}],23:[function(require,module,exports){
  'use strict';
  
  var Type = require('../type');
  
  function resolveYamlNull(data) {
    if (data === null) return true;
  
    var max = data.length;
  
    return (max === 1 && data === '~') ||
           (max === 4 && (data === 'null' || data === 'Null' || data === 'NULL'));
  }
  
  function constructYamlNull() {
    return null;
  }
  
  function isNull(object) {
    return object === null;
  }
  
  module.exports = new Type('tag:yaml.org,2002:null', {
    kind: 'scalar',
    resolve: resolveYamlNull,
    construct: constructYamlNull,
    predicate: isNull,
    represent: {
      canonical: function () { return '~';    },
      lowercase: function () { return 'null'; },
      uppercase: function () { return 'NULL'; },
      camelcase: function () { return 'Null'; }
    },
    defaultStyle: 'lowercase'
  });
  
  },{"../type":13}],24:[function(require,module,exports){
  'use strict';
  
  var Type = require('../type');
  
  var _hasOwnProperty = Object.prototype.hasOwnProperty;
  var _toString       = Object.prototype.toString;
  
  function resolveYamlOmap(data) {
    if (data === null) return true;
  
    var objectKeys = [], index, length, pair, pairKey, pairHasKey,
        object = data;
  
    for (index = 0, length = object.length; index < length; index += 1) {
      pair = object[index];
      pairHasKey = false;
  
      if (_toString.call(pair) !== '[object Object]') return false;
  
      for (pairKey in pair) {
        if (_hasOwnProperty.call(pair, pairKey)) {
          if (!pairHasKey) pairHasKey = true;
          else return false;
        }
      }
  
      if (!pairHasKey) return false;
  
      if (objectKeys.indexOf(pairKey) === -1) objectKeys.push(pairKey);
      else return false;
    }
  
    return true;
  }
  
  function constructYamlOmap(data) {
    return data !== null ? data : [];
  }
  
  module.exports = new Type('tag:yaml.org,2002:omap', {
    kind: 'sequence',
    resolve: resolveYamlOmap,
    construct: constructYamlOmap
  });
  
  },{"../type":13}],25:[function(require,module,exports){
  'use strict';
  
  var Type = require('../type');
  
  var _toString = Object.prototype.toString;
  
  function resolveYamlPairs(data) {
    if (data === null) return true;
  
    var index, length, pair, keys, result,
        object = data;
  
    result = new Array(object.length);
  
    for (index = 0, length = object.length; index < length; index += 1) {
      pair = object[index];
  
      if (_toString.call(pair) !== '[object Object]') return false;
  
      keys = Object.keys(pair);
  
      if (keys.length !== 1) return false;
  
      result[index] = [ keys[0], pair[keys[0]] ];
    }
  
    return true;
  }
  
  function constructYamlPairs(data) {
    if (data === null) return [];
  
    var index, length, pair, keys, result,
        object = data;
  
    result = new Array(object.length);
  
    for (index = 0, length = object.length; index < length; index += 1) {
      pair = object[index];
  
      keys = Object.keys(pair);
  
      result[index] = [ keys[0], pair[keys[0]] ];
    }
  
    return result;
  }
  
  module.exports = new Type('tag:yaml.org,2002:pairs', {
    kind: 'sequence',
    resolve: resolveYamlPairs,
    construct: constructYamlPairs
  });
  
  },{"../type":13}],26:[function(require,module,exports){
  'use strict';
  
  var Type = require('../type');
  
  module.exports = new Type('tag:yaml.org,2002:seq', {
    kind: 'sequence',
    construct: function (data) { return data !== null ? data : []; }
  });
  
  },{"../type":13}],27:[function(require,module,exports){
  'use strict';
  
  var Type = require('../type');
  
  var _hasOwnProperty = Object.prototype.hasOwnProperty;
  
  function resolveYamlSet(data) {
    if (data === null) return true;
  
    var key, object = data;
  
    for (key in object) {
      if (_hasOwnProperty.call(object, key)) {
        if (object[key] !== null) return false;
      }
    }
  
    return true;
  }
  
  function constructYamlSet(data) {
    return data !== null ? data : {};
  }
  
  module.exports = new Type('tag:yaml.org,2002:set', {
    kind: 'mapping',
    resolve: resolveYamlSet,
    construct: constructYamlSet
  });
  
  },{"../type":13}],28:[function(require,module,exports){
  'use strict';
  
  var Type = require('../type');
  
  module.exports = new Type('tag:yaml.org,2002:str', {
    kind: 'scalar',
    construct: function (data) { return data !== null ? data : ''; }
  });
  
  },{"../type":13}],29:[function(require,module,exports){
  'use strict';
  
  var Type = require('../type');
  
  var YAML_DATE_REGEXP = new RegExp(
    '^([0-9][0-9][0-9][0-9])'          + // [1] year
    '-([0-9][0-9])'                    + // [2] month
    '-([0-9][0-9])$');                   // [3] day
  
  var YAML_TIMESTAMP_REGEXP = new RegExp(
    '^([0-9][0-9][0-9][0-9])'          + // [1] year
    '-([0-9][0-9]?)'                   + // [2] month
    '-([0-9][0-9]?)'                   + // [3] day
    '(?:[Tt]|[ \\t]+)'                 + // ...
    '([0-9][0-9]?)'                    + // [4] hour
    ':([0-9][0-9])'                    + // [5] minute
    ':([0-9][0-9])'                    + // [6] second
    '(?:\\.([0-9]*))?'                 + // [7] fraction
    '(?:[ \\t]*(Z|([-+])([0-9][0-9]?)' + // [8] tz [9] tz_sign [10] tz_hour
    '(?::([0-9][0-9]))?))?$');           // [11] tz_minute
  
  function resolveYamlTimestamp(data) {
    if (data === null) return false;
    if (YAML_DATE_REGEXP.exec(data) !== null) return true;
    if (YAML_TIMESTAMP_REGEXP.exec(data) !== null) return true;
    return false;
  }
  
  function constructYamlTimestamp(data) {
    var match, year, month, day, hour, minute, second, fraction = 0,
        delta = null, tz_hour, tz_minute, date;
  
    match = YAML_DATE_REGEXP.exec(data);
    if (match === null) match = YAML_TIMESTAMP_REGEXP.exec(data);
  
    if (match === null) throw new Error('Date resolve error');
  
    // match: [1] year [2] month [3] day
  
    year = +(match[1]);
    month = +(match[2]) - 1; // JS month starts with 0
    day = +(match[3]);
  
    if (!match[4]) { // no hour
      return new Date(Date.UTC(year, month, day));
    }
  
    // match: [4] hour [5] minute [6] second [7] fraction
  
    hour = +(match[4]);
    minute = +(match[5]);
    second = +(match[6]);
  
    if (match[7]) {
      fraction = match[7].slice(0, 3);
      while (fraction.length < 3) { // milli-seconds
        fraction += '0';
      }
      fraction = +fraction;
    }
  
    // match: [8] tz [9] tz_sign [10] tz_hour [11] tz_minute
  
    if (match[9]) {
      tz_hour = +(match[10]);
      tz_minute = +(match[11] || 0);
      delta = (tz_hour * 60 + tz_minute) * 60000; // delta in mili-seconds
      if (match[9] === '-') delta = -delta;
    }
  
    date = new Date(Date.UTC(year, month, day, hour, minute, second, fraction));
  
    if (delta) date.setTime(date.getTime() - delta);
  
    return date;
  }
  
  function representYamlTimestamp(object /*, style*/) {
    return object.toISOString();
  }
  
  module.exports = new Type('tag:yaml.org,2002:timestamp', {
    kind: 'scalar',
    resolve: resolveYamlTimestamp,
    construct: constructYamlTimestamp,
    instanceOf: Date,
    represent: representYamlTimestamp
  });
  
  },{"../type":13}],"/":[function(require,module,exports){
  'use strict';
  
  
  var yaml = require('./lib/js-yaml.js');
  
  
  module.exports = yaml;
  
  },{"./lib/js-yaml.js":1}]},{},[])("/")
  });