export class Time {

    private key:string;
    private factor:number;

    public constructor ( key:string, factor:number ){
        this.key = key;
        this.factor = factor;
    }

    public multiply ( times:number ):number{
        return times * this.factor;
    }

    public divide ( millis:number ):number{
        return millis / this.factor;
    }

    public getKey():string{
        return this.key
    }

}