import jQuery = require( "jquery" );
import { modules } from "../main";

declare let window:any;

export class Windows {

    public static instance:Windows;

    private static timer:any = null;
    private static callbacks:Array<() => {}> = new Array();

    private constructor (){
        jQuery(window).on('resize', function(e) {
            clearTimeout(Windows.timer);
            Windows.timer = setTimeout(function (){

                for (let i = 0; i < Windows.callbacks.length; i++) {
                    Windows.callbacks[i]();
                }

            }, 200);
        
        });
    }

    public static start ():void {
        Windows.instance = new Windows();
    }

    public onResize ( callback:() => {} ){
        if ( Windows.instance !== undefined ){
            Windows.callbacks.push( callback );
        }
    }

    public static callGlobalFunction ( functionName:string, throwError:boolean, ...args:any[] ):any{
        return this.callGlobalFunctionWithCaller( functionName, throwError, this, args );
    }

    public static callGlobalFunctionWithCaller ( functionName:string, throwError:boolean, caller:any, ...args:any[] ):any{
        var result:any = null;
 
         try {
             if ( this.globalFunctionExsists( functionName ) ){
                 var functionReturn = window[ functionName ].apply( caller, args );
 
                 if ( functionReturn !== undefined ){
                    result = functionReturn;
                 }
             }
         } catch ( e ){
             if( throwError ){
                 modules.error("Folgende Globale Event-Funktion konnte nicht aufgerufen werden: " + functionName, false);
             }
         }
 
         return result;
     }

    public static globalFunctionExsists ( functionName:string ):boolean{
        return typeof window[ functionName ] === "function";
    }
}