import { ModelNumberInput } from "./ModelNumberInput";
import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import jQuery = require( "jquery" );
import { Globals } from "../../classes/Globals";

export class ControllerNumberInput extends HTMLController<ModelNumberInput>{

    private static DEFAULT_MAX:number = 100;
    private static DEFAULT_MIN:number = 1;
    private static DEFAULT_STEP:number = 1;

    private input:JQuery<HTMLInputElement>;

    private max:number;
    private min:number;
    private step:number;

    private plusInterval:any;
    private minusInterval:any;

    public constructor ( accessName:string, accessID:number, element:JQuery<HTMLElement>){
        super( new ModelNumberInput(), accessName, accessID, element );

        this.plusInterval = 0;
        this.minusInterval = 0;
    }

    public initGlobals ():void{
        let defaultMax = Number( this.getModule().getConfig( "default_max" ) );
		let defaultMin = Number( this.getModule().getConfig( "default_min" ) );
        let defaultStep = Number( this.getModule().getConfig( "default_step" ) );
        
        if ( !isNaN( defaultMax ) ) { ControllerNumberInput.DEFAULT_MAX = defaultMax; }
		if ( !isNaN( defaultMin ) ) { ControllerNumberInput.DEFAULT_MIN = defaultMin; }
        if ( !isNaN( defaultStep ) ) { ControllerNumberInput.DEFAULT_STEP = defaultStep; }
    }

    public run ():void{    
        let plus_button:JQuery<HTMLElement> = this.getItem( "plus" );
        let minus_button:JQuery<HTMLElement> = this.getItem( "minus" );
    
        this.input = this.getItem( "field" ) as JQuery<HTMLInputElement>;
    
        if ( this.input.length && plus_button.length && minus_button.length ) {
            
            var max:string = this.getParam( "max" );
            var min:string = this.getParam( "min" );
            var step:string = this.getParam( "step" );

            var inputValue:string = String( this.input.val() );
            
            this.max = ( max == null ? NaN : Number( max ) );
            this.min = ( min == null ? NaN : Number( min ) );
            this.step = ( step == null ? NaN : Number( step ) );

            var val = ( inputValue == "" ? NaN : Number( inputValue ) );

            if ( isNaN( this.max ) ) { this.max = ControllerNumberInput.DEFAULT_MAX; }
            if ( isNaN( this.min ) )  { this.min = ControllerNumberInput.DEFAULT_MIN; }
            if ( isNaN( this.step ) ) { this.step = ControllerNumberInput.DEFAULT_STEP; }
            
            if ( isNaN( val ) || val < this.min || val > this.max ) { this.input.val( this.min ); }
    
            plus_button.on( "mousedown", this.startPlus.bind(this) );
            minus_button.on( "mousedown", this.startMinus.bind(this) );

            jQuery( document ).on( "mouseup", function (){
                this.stopMinus();
                this.stopPlus();
            }.bind(this));

        } else {
            this.getModule().error( Globals.MODULE_LOADING_ERROR + " nicht alle der folgenden Items gefunden wurden: plus, minus und field" );
        }
    }

    /**
     * 
     * PLUS
     */

    private startPlus ():void{
        this.plus();
        this.plusInterval = setInterval ( this.plus.bind(this), 200);
    }

    private stopPlus ():void{
        clearInterval( this.plusInterval );
        this.plusInterval = 0;
    }

    private plus ():void{
        var val:number = Number( this.input.val() );
    
        if ( val + this.step <= this.max ) {
            this.input.val( val + this.step );
        }
    }

    /**
     * 
     * MINUS
     */

    private startMinus ():void{
        this.minus();
        this.minusInterval = setInterval ( this.minus.bind(this), 200);
    }

    private stopMinus ():void{
        clearInterval( this.minusInterval );
        this.minusInterval = 0;
    }
    
    private minus ():void{
        var val:number = Number( this.input.val() );
    
        if ( val - this.step >= this.min ) {
            this.input.val( val - this.step );
        }
    }


}