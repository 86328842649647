import { Pair } from "./../../../libs/Pair";
import { Controller } from "./../Controller";
import { Model } from "./../Model";
import { Strings } from "../../../libs/Strings";
import { Globals } from "./../../Globals";
import jQuery = require( "jquery" );
import { Elements } from "../../../libs/Elements";

export abstract class HTMLController<ModelType extends Model> extends Controller<ModelType>{
	private element:JQuery<HTMLElement>;
	
	private params:Array<Pair<string, string>>;

	private singleItems:Array<Pair<string, JQuery<HTMLElement>>>;
	private multiItems:Array<Pair<string, Array<JQuery<HTMLElement>>>>;

	public constructor ( model:ModelType, accessName:string, accessID:number, element:JQuery<HTMLElement> ){
		super( model, accessName, accessID );

		this.element = element;

		this.params = new Array();
		this.singleItems = new Array();
		this.multiItems = new Array();

		this.init();
	}

	private init ():void{
		this.element.attr( Globals.MODULE_ID_KEY, this.getID() );

		this.findParams();
		this.findItems();
	}

	private findParams ():void{
		for (let i = 0; i < this.element.get(0).attributes.length; i++) {
			var attribute:any = this.element.get(0).attributes[i];

			if( attribute.specified && Strings.startsWith( attribute.name, Globals.PARAMETER_ATTRIBUTE_KEY ) ) {
				this.params.push( new Pair( attribute.name.replace( Globals.PARAMETER_ATTRIBUTE_KEY, "" ), attribute.value ) );
			}
			
		}
	}

	private findItems ():void{
		var elements:any = this.element.find( "[" + Globals.ITEM_ATTRIBUTE_KEY + "]" );
		
		for (let i = 0; i < elements.length; i++) {
			let element:JQuery<HTMLElement> = jQuery( elements[i] );
			let element_key:string = element.attr( Globals.ITEM_ATTRIBUTE_KEY );

			let multiItemExists:boolean = false;
			let singleItemExists:boolean = false; 

			for (let j = 0; j < this.multiItems.length; j++) {
				if ( this.multiItems[j].getKey() == element_key ){
					this.multiItems[j].getValue().push( element );

					multiItemExists = true;
					
					break;
				}
			}

			if ( !multiItemExists ){
				for (let k = 0; k < this.singleItems.length; k++) {
					if ( this.singleItems[k].getKey() == element_key ){

						var array:Array<JQuery<HTMLElement>> = new Array();

						array.push( this.singleItems[k].getValue() );
						array.push( element );
						
						this.multiItems.push( new Pair( element_key, array ) );
						this.singleItems.splice( k, 1 );

						singleItemExists = true;
						
						break;
					}
				}

				if ( !singleItemExists ){
					this.singleItems.push( new Pair( element_key, element ) );
				}
			} 
		}
	}

 	/******************************************************************/
  	/************************ OUTPUT ELEMENTS *************************/
   	/******************************************************************/
	public setOutputElement ( output:JQuery<HTMLElement>, key:string = "" ):boolean{
		var outputElement:JQuery<HTMLElement> = this.findOutputElement( key );
		if ( output != null && output.length && outputElement != null ){

			if ( output.length > 1 ){
				outputElement.append( output );
			} else {
				outputElement.replaceWith( output );
				output.attr( Globals.OUTPUT_ATTRIBUTE_KEY, key );
			}

			return true;
		} else {
			return false;
		}
	}

	public findOutputElement ( key:string = "" ):JQuery<HTMLElement>{
		var element = this.element.find( "["+ Globals.OUTPUT_ATTRIBUTE_KEY +"='"+ key +"']" );
		
		if (element.length){
			return element;
		} else {
			return null;
		}
	}

	public findOutputElements ( key:string = "" ):Array<JQuery<HTMLElement>>{
		var result:Array<JQuery<HTMLElement>> = new Array();
		var elements = this.element.find( "["+ Globals.OUTPUT_ATTRIBUTE_KEY +"='"+ key +"']" );
		
		if (elements.length){
			for (let i = 0; i < elements.length; i++) {
				result.push( jQuery( elements[i] ) );
			}
		}

		return result;
	}

	public getOutputOption (element:JQuery<HTMLElement>, key:string):string{
		var attribute = element.attr( Globals.ATTRIBUTE_PREFIX + "output-option-" + key );
		if (typeof attribute !== "undefined"){
			return attribute;
		} else {
			return null;
		}
	}

	/*****************************************************************/
  	/************************ INPUT ELEMENTS *************************/
   	/*****************************************************************/
	public findIntputElement ( key:string = "" ):JQuery<HTMLElement>{
		var element = this.element.find( "["+ Globals.INPUT_ATTRIBUTE_KEY +"='"+ key +"']" );
		
		if (element.length){
			return element;
		} else {
			return null;
		}
	}

	public findElement ( event:string ): JQuery<HTMLElement>{
		return Elements.findElement( this.element, event );
	}

	public getElement ():JQuery<HTMLElement>{
		return this.element;
	}

	public getItem ( key:string ):JQuery<HTMLElement>{
		let result:JQuery<HTMLElement> = jQuery();

		this.singleItems.forEach(item => {
			if ( item.getKey() == key ){
				result = item.getValue();
			}
		});

		return result;
	}

	public getItems ( key:string ):Array<JQuery<HTMLElement>>{
		let result:Array<JQuery<HTMLElement>> = new Array();

		this.multiItems.forEach(item => {
			if ( item.getKey() == key ){
				result = item.getValue();
			}
		});

		if ( result.length == 0 ){
			result.push( this.getItem( key ) );
		}

		return result;
	}

	public getParam ( key:string ):string{
		let result:string = null;

		this.params.forEach(param => {
			if ( param.getKey() == key ){
				result = param.getValue();
			}
		});

		return result;
	}

	public getAllParameter ():Array<Pair<string, string>>{
		return this.params;
	}

}