import { Pair } from "./Pair";
import { Time } from "./Time";
import { Globals } from "./../classes/Globals";

export class Times {
    public static getMillis ( input:string ):number{
        let result:number = null;
    
        if ( input != null ){
    
            let timePair:Pair<string, number> = splitTimeString( input );
    
            if ( timePair != null ){
                let time:Time = findTime( timePair.getKey() );
                if ( time != null ){
    
                    result = time.multiply( timePair.getValue() );
    
                }
            }
    
        }
    
        return result;
    }

    public static getTimeString ( millis:number, unit:string ){
        var time:Time = findTime( unit );
        if ( time != null ){
            return time.divide( millis ) + unit;
        } else {
            return "";
        }
    }

    public static printTimestamp():string{
        var date:Date = new Date()
		return date.getDate() + "." + date.getMonth() + "." + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }

    public static sleep( milliseconds:number, callback:() => {} ){
        var time:any= null;
        
        clearTimeout( time );

        time = setTimeout( callback, milliseconds);
    }
}

function splitTimeString ( input:string ):Pair<string, number>{
    let result:Pair<string, number> = null;

    const regex:RegExp = /([0-9]{1,5})([a-z]{1,3})/g;
    const string:string = input;
    
    let match:RegExpExecArray = regex.exec( string );

    if ( match != null ){
        let number:number = Number( match[1] );
        let key:string = match[2];

        if ( !isNaN( number ) && key != null ){
            result = new Pair( key, number );
        }
    }

    return result;
}

function findTime ( key:string ):Time{
    let result:Time = null;

    Globals.times.forEach(time => {
        if ( time.getKey() == key ){
            result = time;
        } 
    });

    return result;
}