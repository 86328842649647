import { Controller } from "./../../classes/mvc/Controller";
import { ModelCookieDirective } from "./ModelCookieDirective";
import { Globals } from "./../../classes/Globals";
import { Cookies } from "./../../libs/Cookies";
import { IOverlay } from "./../../classes/IOverlay";
import { Pair } from "./../../libs/Pair";
import { Strings } from "./../../libs/Strings";
import { Elements } from "./../../libs/Elements";
import { modules } from "./../../main";
import jQuery = require( "jquery" );
import { Times } from "../../libs/Times";

export class ControllerCookieDirective extends Controller<ModelCookieDirective> implements IOverlay{
    private static COOKIE_TYPES:Array<Pair<string, () => any>>

    private static COOKIE_KEY:string;
    private static OPTION_INPUT_KEY:string;

    private static MESSAGE_SETTINGS_SAVED:string;
    private static MESSAGE_ALLOW_COOKIES:string;

    /**
     * 
     * GUI Elements
     */
    private static HEADLINE:JQuery<HTMLElement>;
    private static MESSAGE:JQuery<HTMLElement>;

    private element:JQuery<HTMLElement>;

    public constructor ( accessName:string, accessID:number ){
        super( new ModelCookieDirective(), accessName, accessID );
    }

    public initGlobals ():void{
        ControllerCookieDirective.COOKIE_TYPES = new Array();

        ControllerCookieDirective.COOKIE_TYPES.push( new Pair( "all", this.selectAll.bind(this) ) );
        ControllerCookieDirective.COOKIE_TYPES.push( new Pair( "nothing", this.selectNothing.bind(this) ) );

        ControllerCookieDirective.COOKIE_KEY = "ez_cookie_status";
        ControllerCookieDirective.OPTION_INPUT_KEY = "cookie_directive";

        ControllerCookieDirective.HEADLINE = jQuery( "<h3>COOKIE-EINSTELLUNGEN</h3>" );
        ControllerCookieDirective.MESSAGE = jQuery( "<div></div>" );

        ControllerCookieDirective.MESSAGE_SETTINGS_SAVED = "";
        ControllerCookieDirective.MESSAGE_ALLOW_COOKIES = "";

        var settingsSaved = this.getModule().getLabel( "message_settings_saved" );
        if ( settingsSaved != null ){
            ControllerCookieDirective.MESSAGE_SETTINGS_SAVED = settingsSaved;
        }

        var allowCookies = this.getModule().getLabel( "message_allow_cookies" );
        if ( allowCookies != null ){
            ControllerCookieDirective.MESSAGE_ALLOW_COOKIES = allowCookies;
        }

        var message = this.getModule().getComponent( "message" );
        if ( message != null ){
            ControllerCookieDirective.MESSAGE = jQuery( message );
        }

        var structure:string = this.getModule().getComponent( "structure" );
        var option:string = this.getModule().getComponent( "option" );
        var headline:string = this.getModule().getComponent( "headline" );

        if ( structure != "" && option != "" && headline != "" ){
            this.getModule().addView( "structure", structure );
            this.getModule().addView( "option", option );

            ControllerCookieDirective.HEADLINE = jQuery( headline );
            ControllerCookieDirective.HEADLINE.addClass( "headline" );
        }

        ControllerCookieDirective.MESSAGE.addClass( "message" );
    }

    public run ():void{
        var cookie:string = Cookies.instance.get( ControllerCookieDirective.COOKIE_KEY );
        if ( cookie == null ){

            this.start();
            
        } else {
            var cookieItem:Pair<string, () => any> = this.getCookieItem( cookie );
            if( cookieItem != null ){
                this.executeEventFunction( cookie );
            }
        }
    }

    private start ():void{
        var options:string = "";
         
        for ( var i = 0; i < ControllerCookieDirective.COOKIE_TYPES.length; i++ ){
            var modelID:number = this.getModel().new();
            var inputID:string = ControllerCookieDirective.OPTION_INPUT_KEY + "_" + ControllerCookieDirective.COOKIE_TYPES[i].getKey();

            this.getModel().add( modelID, "input_id", inputID );
            this.getModel().add( modelID, "input", "<input id='"+ inputID +"' type='radio' name='"+ ControllerCookieDirective.OPTION_INPUT_KEY +"' value='"+ ControllerCookieDirective.COOKIE_TYPES[i].getKey() +"'>" );
            this.getModel().add( modelID, "text", this.getModule().getLabel( "option_" + ControllerCookieDirective.COOKIE_TYPES[i].getKey() ) );

            options += this.process( modelID, "option" );
        }
        
        if ( options != "" ){
            options = "<ul class='options'>" + options + "</ul>";
        }

        var result:string = this.processOne( "structure", "options", options );
        if ( result != "" ){
            this.element = jQuery( result );
            
            if( this.element.length ){
                jQuery( "body" ).prepend( this.element );

                this.element.prepend( ControllerCookieDirective.HEADLINE );
                this.element.append( ControllerCookieDirective.MESSAGE );
                
                this.getModule().addModuleAttribute( this.element );

                var inputs:any = this.element.find( "input[name='" + ControllerCookieDirective.OPTION_INPUT_KEY + "']" );
                for (let i = 0; i < inputs.length; i++) {
                    jQuery( inputs[i] ).change( this.change.bind(this) );
                }
    
                this.openPopup();
            } else {
                this.getModule().error( Globals.MODULE_LOADING_ERROR + " die HTML Struktur aus der Konfiguratiosndatei fehlerhaft ist" );
            }
        }
    }

    private change( e:React.FormEvent<HTMLInputElement> ):void{
        var selectedKey:string = jQuery( e.target ).attr( "value" );

        if ( selectedKey != "nothing" ){
            Cookies.instance.setWildcard( ControllerCookieDirective.COOKIE_KEY, selectedKey, "1y" );
        }

        var cookieItem:Pair<string, () => any> = this.getCookieItem( selectedKey );
        if( cookieItem != null ){
            this.executeEventFunction( selectedKey );
            cookieItem.getValue()();
        }
    }

    private getCookieItem ( selectedKey:string ):Pair<string, () => any>{
        var result:Pair<string, () => any> = null;

        for (let i = 0; i < ControllerCookieDirective.COOKIE_TYPES.length; i++) {
            if ( ControllerCookieDirective.COOKIE_TYPES[i].getKey() == selectedKey ){
                result = ControllerCookieDirective.COOKIE_TYPES[i];
                break;
            }
        }

        return result;
    }

    private executeEventFunction ( cookieKey:string ){
        this.getModule().callGlobalModuleEventFunction( "execute" + Strings.beginWithUppercase( cookieKey ) );
        if ( cookieKey != "nothing" ){
            modules.allowCookies();
        }
    }

    /**
     * 
     * Show messages
     */
    private showMessageSettingsSaves ():void{
        ControllerCookieDirective.MESSAGE.html( ControllerCookieDirective.MESSAGE_SETTINGS_SAVED );
        ControllerCookieDirective.MESSAGE.attr( Globals.ATTRIBUTE_PREFIX + "message", "settings_saved" );

        this.element.find( ".options" ).addClass( "selected" );

        Times.sleep( 1000, function (){
            this.closePopup();
        }.bind(this));
    }

    private showMessageAllowCookies ():void{
        ControllerCookieDirective.MESSAGE.html( ControllerCookieDirective.MESSAGE_ALLOW_COOKIES );
        ControllerCookieDirective.MESSAGE.attr( Globals.ATTRIBUTE_PREFIX + "message", "allow_cookies" );
    }

    /**
     * 
     * Selection methods
     */
    private selectAll():void{
        this.showMessageSettingsSaves();
    }

    private selectNoAnalytics():void{
        this.showMessageSettingsSaves();
    }

    private selectNothing():void{
        this.showMessageAllowCookies();
    }

    /**
     * 
     * @abstract
     */
    public openPopup(): void {
        Globals.OVERLAY.openOverlay( this.getModule().getName() );
        Elements.showElement( this.element, "slide-down" );
    }
    public closePopup(): void {
        Globals.OVERLAY.closeOverlay( this.getModule().getName(), "fade-out" );
        Elements.hideElement( this.element, "slide-up" );
    }

}