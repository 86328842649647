import { Globals } from "./../../classes/Globals";
import { IComparator } from "./../../libs/IComparator";
import { Item } from "./../../libs/Item";

export enum ItemType {
    PICTURE = "image",
    VIDEO = "video"
}

class ComparatorAscending  implements IComparator<LightboxItem>{

    public compare ( itemOne:LightboxItem, itemTwo:LightboxItem ):number{
        if ( itemOne.getID() == itemTwo.getID() ){
            return 0;
        } else if ( itemOne.getID() > itemTwo.getID() ){
            return 1;
        } else {
            return -1;
        }
    }

}

class ComparatorDescending  implements IComparator<LightboxItem>{

    public compare ( itemOne:LightboxItem, itemTwo:LightboxItem ):number{
        if ( itemOne.getID() == itemTwo.getID() ){
            return 0;
        } else if ( itemOne.getID() > itemTwo.getID() ){
            return -1;
        } else {
            return 1;
        }
    }

}

export class LightboxItem extends Item{

    private type:string;
    private id:number;

    private openItem:JQuery<HTMLElement>;
    private item:JQuery<HTMLElement>;
    private thumb:JQuery<HTMLElement>;
    
    public static comparatorAscending:ComparatorAscending = new ComparatorAscending();
    public static comparatorDescending:ComparatorAscending = new ComparatorDescending();

    public constructor ( type:string, id:number, openItem:JQuery<HTMLElement>, item:JQuery<HTMLElement>, thumb:JQuery<HTMLElement> ){
        super();
        
        this.type = type;
        this.id = id;

        this.openItem = openItem;
        this.item = item;
        this.thumb = thumb;

        if ( this.type == ItemType.VIDEO ){
            this.item.attr( "width", this.getHeight() );
        }

        // this.setValid( this.openItem.length > 0 && this.item.length > 0 && this.thumb.length > 0 );
    }

    public getType ():string{
        return this.type;
    }

    public getID():number {
        return this.id;
    }

    public getItem ():JQuery<HTMLElement>{
        return this.item;
    }

    public getOpenItem ():JQuery<HTMLElement>{
        return this.openItem;
    }

    public getThumb ():JQuery<HTMLElement>{
        return this.thumb;
    }

    public getWidth():number {
        if ( this.type == ItemType.VIDEO ){
            var width = Number( this.openItem.attr( Globals.PARAMETER_ATTRIBUTE_KEY + "width" ) );
            if ( !isNaN( width ) ){
                return width;
            } else {
                return 0;
            }
        } else {
            var image = this.openItem.get(0) as HTMLImageElement;
            return image.naturalWidth;
        }
        
    }

    public getHeight():number {
        if ( this.type == ItemType.VIDEO ){
            var width = Number( this.openItem.attr( Globals.PARAMETER_ATTRIBUTE_KEY + "height" ) );
            if ( !isNaN( width ) ){
                return width;
            } else {
                return 0;
            }
        } else {
            var image = this.openItem.get(0) as HTMLImageElement;
            return image.naturalHeight;
        }
    }

}