import { Option } from "./Option";
import { Arrays } from "../../libs/Arrays";
import { Pair } from "../../libs/Pair";
import { HTMLModule } from "../../classes/mvc/HTML/HTMLModule";
import { modules } from "../../main";

export class Category {

    private key: any;
    private options: Array<Option>;

    private outputElement: JQuery<HTMLElement>;

    /**
     * 
     * First: moduleName
     * Second: module element ID
     */
    private onChange: Pair<string, string>;

    public constructor(key: any, outputElement: JQuery<HTMLElement>, onChange: Pair<string, string>) {
        this.key = key;
        this.options = new Array();

        this.outputElement = outputElement;

        this.onChange = onChange;
    }

    public optionExists(key: string): boolean {
        var exists: boolean = false;

        for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].getKey() == key) {
                exists = true;
                break;
            }
        }

        return exists;
    }

    public getSelected(): Option {
        var option: Option = null;

        for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].isSelected()) {
                option = this.options[i];
                break;
            }
        }

        return option;
    }

    public deactivateAllOptions(): void {
        for (let i = 0; i < this.options.length; i++) {
            this.options[i].deselect();
        }
    }

    public getSplitKey(escaping: Array<string>): string {
        if (this.options.length > 0) {
            var splitKey = "";

            for (let i = 0; i < escaping.length; i++) {
                var splittedItems = this.options[0].getKey().split(escaping[i]);
                if (splittedItems.length == 2) {
                    splitKey = escaping[i];
                    break;
                }
            }
            return splitKey;
        } else {
            return "";
        }
    }

    public orderOptions(order: Array<string>, escaping: Array<string>) {
        if (this.options.length > 0) {
            if (order == null) {

                var splitKey = this.getSplitKey( escaping );

                var sortNumeric = !isNaN(Number(this.options[0].getKey().split(splitKey)[0]));

                if (sortNumeric) {
                    this.options.sort(function (optionOne: Option, optionTwo: Option) {
                        var numberOptionOne: number = Number(optionOne.getKey().split(splitKey)[0]);
                        var numberOptionTwo: number = Number(optionTwo.getKey().split(splitKey)[0]);

                        if (numberOptionOne < numberOptionTwo) {
                            return -1;
                        }
                        if (numberOptionTwo < numberOptionOne) {
                            return 1;
                        }

                        return 0;
                    });
                } else {
                    this.options.sort(function (optionOne: Option, optionTwo: Option) {
                        if (optionOne.getKey() < optionTwo.getKey()) {
                            return -1;
                        }
                        if (optionTwo.getKey() < optionOne.getKey()) {
                            return 1;
                        }

                        return 0;
                    });
                }
            } else {
                var optionsNewOrder: Array<Option> = new Array;

                /**
                 * 
                 * Sort the defined options
                 */
                for (let i = 0; i < order.length; i++) {
                    for (let j = 0; j < this.options.length; j++) {
                        if (order[i] == this.options[j].getKey()) {
                            optionsNewOrder[i] = this.options[j];
                            Arrays.removeByIndex(this.options, j);
                        }
                    }
                }

                /**
                 * 
                 * Add the leftover options to the new array
                 */
                for (let i = 0; i < this.options.length; i++) {
                    optionsNewOrder.push(this.options[i]);
                }

                this.options = optionsNewOrder;
            }
        }
    }

    public changed(): void {
        if (this.onChange != null) {
            var methodName: string = null;
            var value: any = null;

            var element = jQuery("#" + this.onChange.getValue());

            switch (this.onChange.getKey()) {
                case "Slider":
                    methodName = "jumpTo";

                    var selectedItem = this.getSelected();
                    if (selectedItem != null) {
                        value = selectedItem.getKey();
                    }
                    break;
            }

            if (element.length) {
                if (methodName != null && value !== null) {
                    var elementID = HTMLModule.getControllerID(element);
                    modules.callMethod(this.onChange.getKey(), methodName, elementID, value);
                }
            }
        }
    }

    public setOutputElement(outputElement: JQuery<HTMLElement>): void {
        this.outputElement = outputElement;
    }

    public getKey(): any {
        return this.key;
    }

    public getOptions(): Array<Option> {
        return this.options;
    }

    public getOutputElement(): JQuery<HTMLElement> {
        return this.outputElement;
    }
}