import { ControllerSelectionFilter } from "./ControllerSelectionFilter";
import { Category } from "./Category";

export class Option {

    private readonly key:string;
    private readonly name:string;

    public constructor ( key:string, name:string ){
        this.key = key;
        this.name = name;
    }

    public build ( controller:ControllerSelectionFilter, categoryKey:string ):string {
        var modelID = controller.getModel().new();

        controller.getModel().add(modelID, "selector", Category.OPTION_SELECTOR + "='" + categoryKey + "_" + this.key + "'" );
        controller.getModel().add(modelID, "key", this.key);
        controller.getModel().add(modelID, "name", this.name);
        controller.getModel().add(modelID, "category_key", categoryKey);

        return controller.process(modelID, "option_container");
    }

    public getKey ():string { return this.key; }
    public getName ():string { return this.name; }
}