import { Pair } from "./Pair";

export class Strings {

	public static startsWith(string: string, start: string): boolean {
		return string.indexOf(start) == 0;
	}
	public static endsWith(string: string, end: string): boolean {
		return string.indexOf(end) == (string.length - end.length);
	}

	public static stripSpecialChars(string: string): string {
		return string.replace(/[\<\>\{\}\[\]\'\"]/g, "");
	}

	public static replaceMultiple(string: string, replace: string, replacement: string): string {
		return string.replace(new RegExp("\\" + replace, "g"), replacement);
	}

	public static beginWithUppercase(string: string): string {
		return string[0].toUpperCase() + string.substring(1);
	}

	public static beginWithLowecase(string: string): string {
		return string[0].toLowerCase() + string.substring(1);
	}

	public static toArray(string: string, allowEmpty: boolean = true): Array<string> {
		string = string.replace(new RegExp(", ", "g"), ",");

		if (!allowEmpty) {
			string = string.replace(new RegExp(",{2,}", "g"), ",");
		}

		var result: Array<string> = new Array;

		if (string == "," || string.indexOf(",") == -1) {
			result.push(string);
		} else {
			result = string.split(",");
		}

		return result;
	}

	public static toPair(string: string): Pair<string, any> {
		var result: Pair<string, any> = null;
		var array: Array<string> = string.split(":");

		if (array.length == 2) {
			if (array[0] !== undefined && array[1] !== undefined) {
				result = new Pair(array[0], array[1]);
			}
		} else if (array.length > 2) {
			var key = array[0];
			array.splice(0, 1);
			var value = array.join(":");

			result = new Pair(key, value);
		}

		return result;
	}

	public static toArrayPair(string: string, allowEmpty: boolean = true): Array<Pair<string, any>> {
		var result: Array<Pair<string, any>> = new Array();
		var array: Array<string> = this.toArray(string, allowEmpty);

		for (let i = 0; i < array.length; i++) {
			var pair: Pair<string, any> = this.toPair(array[i]);
			if (pair != null) {
				result.push(pair);
			}
		}

		return result;
	}

	public static toPairWithIndex(string: string, allowEmpty: boolean = true):Pair<any, Pair<string, any>> {
		var result: Pair<any, Pair<string, any>> = null;

		var item = string;
		var itemPieces = item.split(":");

		for (let i = 0; i < itemPieces.length; i++) {
			if ( itemPieces.length == 3 ){
				result = new Pair( itemPieces[0], new Pair( itemPieces[1], itemPieces[2] ) );
			}
		}

		return result;
	}
}