import { Controller } from "./../../classes/mvc/Controller";
import { ModelToTop } from "./ModelToTop";
import { Times } from "./../../libs/Times";
import jQuery = require( "jquery" );

export class ControllerToTop extends Controller<ModelToTop> {

    private static ANIMATION_TIME:number;
    private static DISPLAY_BUTTON_DISTANCE:number;

    private element:JQuery<HTMLElement>;

    public constructor ( accessName:string, accessID:number ){
        super( new ModelToTop(), accessName, accessID );
    }

    public initGlobals ():void{
        ControllerToTop.ANIMATION_TIME = Times.getMillis( this.getModule().getConfig( "animation_time" ) );
        ControllerToTop.DISPLAY_BUTTON_DISTANCE = this.getModule().getConfig( "display_button_distance" );
    }

    public run ():void{        
        if ( ControllerToTop.ANIMATION_TIME == null ) { ControllerToTop.ANIMATION_TIME = 1000; }
        if ( ControllerToTop.DISPLAY_BUTTON_DISTANCE == null ) { ControllerToTop.DISPLAY_BUTTON_DISTANCE = 200; }

        if ( ( this.element = this.createStructure() ) != null ){

            jQuery( window ).bind( "scroll", this.checkDifference.bind( this ) );

            this.element.click(function (){
                jQuery( "body, html" ).animate( { scrollTop: 0 }, ControllerToTop.ANIMATION_TIME );
            }.bind(this));

        } 
    }

    private checkDifference():void{
        if ( jQuery( window ).scrollTop() > ControllerToTop.DISPLAY_BUTTON_DISTANCE ) {
			this.element.fadeIn();
		} else {
			this.element.fadeOut();
		}
    }

    private createStructure ():JQuery<HTMLElement>{
        let structure:JQuery<HTMLElement> = jQuery( this.getModule().getComponent( "structure" ) );
        this.getModule().addModuleAttribute( structure );

        if ( structure.length ) {
			jQuery( "body" ).append( structure );
            return structure;
		} else {
			return null;
		}
    }

}