import { Controller } from "../../classes/mvc/Controller";
import { ModelDatalist } from "./ModelDatalist";
import { Datalist } from "./classes/ClassDatalist";

declare let currentPageSite: any;
export class ControllerDatalist extends Controller<ModelDatalist> {

    private static multiplier: number = 10;

    private counter: number = 0;
    private counterNextPage: number = 1;
    
    public constructor(accessName: string, accessID: number) {
        super( new ModelDatalist(), accessName, accessID );
    }

    public initGlobals(): void {
        let defaultMultiplier = Number( this.getModule().getConfig( "default_multiplicator" ) );

        if ( !isNaN( defaultMultiplier ) ) { ControllerDatalist.multiplier = defaultMultiplier }
    }

    public run(): void{
        this.datalistPreInit();
    }

    public datalistPreInit():void {
        let datalists: any = document.querySelectorAll(".datalist");
        this.counter = datalists.length;
        this.datalistInit(datalists);
        if ( currentPageSite == "caspardo.htm" ) {
            this.nextPageInit();
        }
    }

    public datalistInit(datalists:any):void {
        if (datalists) {
            for (let i = 0; i < datalists.length; i++) {
                let container = datalists[i];
                let input = container.children[0];
                let list = container.children[2];
                let vpe = container.getAttribute('data-ez-datalist-vpe');

                let data = [];

                for (let j = 0; j <= ControllerDatalist.multiplier; j++) {
                    let ergebnis = j * vpe;
                    let obj;
                    /**
                     * VPE CHECK
                     * Wenn VPE = 1, wird der Typ 1 gesetzt, falls VPE größer 1 ist wird der Typ auf 0 gesetzt
                     * Typ 1 = *RIEGEL* Hier wird der Text "Probeflasche" entfernt bei Artikeln deren VPE 1 ist. 
                     */
                    if (j === 0) {
                        if ( vpe == 1 ) {
                            obj = {
                                value: j,
                                text: 1,
                                vpeType: 1
                            }
                        } else {
                            obj = {
                                value: j,
                                text: 1
                            }
                        }
                    } else {
                        if ( vpe == 1 ) {
                            obj = {
                                value: j,
                                text: ergebnis,
                                vpeType: 1
                            }
                        } else {
                            obj = {
                                value: j,
                                text: ergebnis
                            }
                        }
                        
                    }

                    data.push(obj);
                }
                let lastVal: any;
                let resData = data.filter( function( o:any ) {
                    let arrFilter: any;

                    if (lastVal != o.text) {
                        arrFilter = o;
                    } else if ( lastVal == o.text ) {
                        return;
                    }

                    lastVal = o.text;
                    return arrFilter;
                } );
                this.getModule().addView("vpe_wrong_amount_text", this.getModule().getLabel("vpe_wrong_amount_text"));
                let modelID = this.getModel().new();
                this.getModel().add(modelID, "vpe", vpe);
                let text = this.process(modelID, 'vpe_wrong_amount_text');

                let datalist = new Datalist( container, input, list, resData, text );
                datalist.create();
                datalist.addListeners(datalist);
            }
        }
    }

    public nextPageInit():void {
        let nextButton = document.querySelector("#next_button");
        nextButton.addEventListener("click", function() {
            let datalists = Array.from(document.getElementsByClassName("datalist"));
            let removeFromArr: number = this.counter * this.counterNextPage;
            datalists.splice(0, removeFromArr);
            this.counterNextPage++;
            this.datalistInit(datalists);
        }.bind(this));
    }
}