import { Controller } from "./../../classes/mvc/Controller";
import jQuery = require("jquery");
import { ModelRatings } from "./ModelRatings";
import { Json } from "../../libs/Json";

declare let ezentrum_session_vars: any;
export class ControllerRatings extends Controller<ModelRatings> {

    private static OUTPUT_OVERVIEW: string = "#ratings_overview";
    private static OUTPUT_OVERVIEW_BARS: string = "#ratings_overview_bars";
    private static OUTPUT_LISTING: string = "#ratings_listing";

    public constructor(accessName: string, accessID: number) {
        super(new ModelRatings(), accessName, accessID);
    }

    public initGlobals(): void {
        var outputOverview = this.getModule().getConfig("output_overview");
        if (outputOverview != null) {
            ControllerRatings.OUTPUT_OVERVIEW = outputOverview;
        }

        var outputOverviewBars = this.getModule().getConfig("output_overview_bars");
        if (outputOverviewBars != null) {
            ControllerRatings.OUTPUT_OVERVIEW_BARS = outputOverviewBars;
        }

        var outputListing = this.getModule().getConfig("output_listing");
        if (outputListing != null) {
            ControllerRatings.OUTPUT_LISTING = outputListing;
        }

        this.getModule().addView("overview", this.getModule().getComponent("overview"));
        this.getModule().addView("overview_bars", this.getModule().getComponent("overview_bars"));
        this.getModule().addView("listing", this.getModule().getComponent("listing"));
    }

    public run(): void {

        if ( ezentrum_session_vars.response_page == "artikel_direkt.htm" ) {
            var data: object = this.getModule().getData();
            var ratings: Array<object> = Json.getSubobject(data, "ratings");

            for (let i = 0; i < ratings.length; i++) {
                let ratingsPoints: any = Json.getSubobject(ratings[i], "rating_points");
                let ratingsPointsCSS: number = ratingsPoints.toFixed(1) * 10;
                let ratingsPointsOutput: number = ratingsPoints.toFixed(1);

                let date: any = new Date(Json.getSubobject(ratings[i], "rating_date"));
                let dateString = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();

                Json.setSubobject(ratings[i], "rating_date", dateString);
                Json.setSubobject(ratings[i], "rating_points", ratingsPointsOutput);
                Json.setSubobject(ratings[i], "rating_points_css", ratingsPointsCSS);
            }

            Json.setSubobject(data, "ratings", ratings);

            var levels: Array<any> = new Array();
            for (let i = 1; i <= 5; i++) {
                let ratingCount: number = 0;

                for (let j = 0; j < ratings.length; j++) {
                    let ratingsPoints: number = parseInt(Json.getSubobject(ratings[j], "rating_points"));
                    if (ratingsPoints == i) {
                        ratingCount++;
                    }
                }

                levels.push({
                    level: i,
                    count: ratingCount,
                    percentage_css: (ratingCount / ratings.length * 100),
                    percentage: Math.round(ratingCount / ratings.length * 100)
                });
            }

            levels.reverse();

            this.build(ControllerRatings.OUTPUT_OVERVIEW, "overview", data);

            if(ratings.length !== 0) {
                this.build(ControllerRatings.OUTPUT_OVERVIEW_BARS, "overview_bars", levels);
            }

            this.build(ControllerRatings.OUTPUT_LISTING, "listing", data);
        }
    }

    private build(selector: string, view: string, data: any) {
        var element: JQuery<HTMLElement> = jQuery(selector);
        if (element.length) {
            element.append(this.processOne(view, "data", data));
        }
    }
}