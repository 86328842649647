export class Tools {
    public static replaceAll(searchIn:string, searchFor:string, replaceWith:string) {
        return searchIn.replace(new RegExp(searchFor, "g"), replaceWith);
    }

    public static replace_quotes(searchIn:string) {
        return this.replaceAll(searchIn, "\"", "\"");
    }

    public static isDefinedVar(sKontaktID:string, sKontaktKEY:string, sTICKCOUNT:string): Boolean {
        if(sKontaktID && sKontaktKEY && sTICKCOUNT) {
            return true;
        }
    }
}