import { Strings } from "../../libs/Strings";
import { Pair } from "../../libs/Pair";

export class Validation{

    private key:string;
    private value:string;

    private level:number;

    private validationValid:boolean;

    public constructor ( key:string, value:string ){
        var splitValue:Pair<string, any> = Strings.toPair( value );

        if ( splitValue != null && !isNaN( Number( splitValue.getKey() ) ) ){
            this.level = Number( splitValue.getKey() );
            this.value = splitValue.getValue();
        } else {
            this.level = 0;
            this.value = value;
        }
        
        this.key = key;
        this.validationValid = false;
    }

    public getKey():string{
        return this.key;
    }

    public getValue():string{
        return this.value;
    }

    public getLevel():number{
        return this.level;
    }

    public isValidationValid ():boolean{
        return this.validationValid;
    }

    public setValidationValid ( valid:boolean ){
        this.validationValid = valid;
    }
}