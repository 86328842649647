import { Module } from "./../../mvc/Module";
import { Model } from "./../../mvc/Model";
import { HTMLController } from "./HTMLController";
import { Globals } from "./../../Globals";

import jQuery = require( "jquery" );
import { modules } from "../../../main";
import { Controller } from "../Controller";

export abstract class HTMLModule<ModelType extends Model> extends Module<ModelType>{

	public constructor ( name:string,  configuration:Object, flagUseCookies:boolean = false ){
		super( name, configuration, flagUseCookies );
	}

	public reInit ( prototype:HTMLController<ModelType> = null ):void {
		if ( typeof prototype !== undefined && prototype != null ){
			this.initAll( prototype );
			this.runAllControllers();
		}
	}

    /**
	 * 
	 * Init all modules
	*/
	public initAll ( prototype:HTMLController<ModelType> ):void {
		let elements:any = this.getAllNotInitializedModuleElements();
		
		for( let i = 0; i < elements.length; i++ ){
            this.init( prototype, jQuery( elements[i] ) );
        }
	}
	
	public initOne ( prototype:HTMLController<ModelType> ):void {
		let elements:any = this.getAllNotInitializedModuleElements();
		
		if ( elements.length > 0 ){
			this.init( prototype, jQuery( elements[0] ) );
		}
	}

	public findController ( id:string ):Controller<ModelType>{
		var result:Controller<ModelType> = null;

		for (let i = 0; i < this.getControllers().length; i++) {
			if( this.getControllers()[i].getID() == id ){
				result = this.getControllers()[i];

				break;
			}
		}

		return result;
	}

	private getAllNotInitializedModuleElements ():any{
		return jQuery( "[" + Globals.MODULE_ATTRIBUTE_KEY + this.getName().toLowerCase() +"]:not(["+ Globals.MODULE_INITIALIZED +"])" );
	}

	private init ( prototype:HTMLController<ModelType>, element:JQuery<HTMLElement> ):HTMLController<ModelType> {
		var result:HTMLController<ModelType> = null;

		if ( prototype instanceof HTMLController ){
			var instance:HTMLController<ModelType> = this.createControllerInstance<HTMLController<ModelType>>( prototype, this.getName(), this.getAccessID(), element );
			
			if ( instance != null ){
				result = instance;
				this.addController( instance );

				element.attr( Globals.MODULE_INITIALIZED, "" );
			}
		}

		return result;
	}

	private createControllerInstance<T>( prototype:HTMLController<ModelType>, accessName:string, accessID:number, element:JQuery<HTMLElement> ) : T {
		try {
			var instance = Object.create( prototype );
		
			instance.constructor.call( instance, accessName, accessID, element );
			
			return <T> instance;
		} catch (e){
			modules.error("Das folgende Module konnte nicht Instanziiert werden: " + accessName);
			return null;
		}
        
	}

	public static findControllerID ( element:JQuery<HTMLElement>, moduleName:string ):string{
		var result:string = "";

		var moduleElement:JQuery<HTMLElement> = element.find( "[" + Globals.MODULE_ATTRIBUTE_KEY + moduleName.toLowerCase() +"]" );
		if ( moduleElement.length ){
			result = this.getControllerID( moduleElement );
		}

		return result;
	}

	public static getControllerID ( element:JQuery<HTMLElement> ):string{
		var result:string = "";

		if ( element.length ){
			var controllerID:string = element.attr( Globals.MODULE_ID_KEY );

			if ( controllerID !== undefined  ){
				result = controllerID 
			}
		}

		return result;
	}
}