import { Modules } from "./Modules";

/**
 * @description Start des Modulsystems hallo welt
 * @mermaid
 * graph TD
 * title[<u><b>Start main.ts</b></u>]
 * title --> A(modules.preInit)
 * A --> B{modules.checkRequirements}
 * B -->|No| C[Exit]
 * B -->|Yes| D[weiter auf &lt;a href&#61;&#39;../classes/&#95;modules&#95;.modules.html&#39;&gt;Modules.ts&lt;/a&gt;]
 */
export var modules:Modules = new Modules();

modules.preInit();
if ( modules.checkRequirements() ){
    document.addEventListener( "DOMContentLoaded", function (){
        modules.init();
    });
}
//To add a function into the global scope
declare var window: any;
window.ezmodules = modules;