import { IOverlay } from "./IOverlay";
import { Globals } from "./Globals";
import { Elements } from "../libs/Elements";

export class Overlay {

    private element:JQuery<HTMLElement>;
    private blockedBy:string;

    public constructor (){
        this.element = jQuery( "<div " + Globals.ATTRIBUTE_PREFIX + "overlay></div>" );
        this.blockedBy = null;
    }

    public create ( selector:string ){
        jQuery( selector ).prepend( this.element );
        Elements.hideElement( this.element );
    }

    public openOverlay ( modulename:string = null, animation:string = "" ):void{
        if ( modulename != null ){
            this.blockedBy = modulename;
            Elements.clearAnimation( this.element );
            Elements.showElement( this.element, animation );
        }
    }

    public closeOverlay ( modulename:string = null, animation:string = "" ):void{
        if ( modulename != null && modulename == this.blockedBy ){
            this.blockedBy = null;
            Elements.hideElement( this.element, animation );
        }
    }

    public getElement():JQuery<HTMLElement>{
        return this.element;
    }

}