import { ModelPasswordToggle } from "./ModelPasswordToggle";
import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import jQuery = require( "jquery" );
import { findIcon } from "../../libs/Icons";
import { Elements } from "../../libs/Elements";

export class ControllerPasswordToggle extends HTMLController<ModelPasswordToggle> {

    private static CHECKBOX_ELEMENT:JQuery<HTMLElement>;
    private static TOGGLE_IN_ELEMENT:JQuery<HTMLElement>;
    private static TOGGLE_OUT_ELEMENT:JQuery<HTMLElement>;

    private checkboxElement:JQuery<HTMLElement>;
    
    private toggleInElement:JQuery<HTMLElement>;
    private toggleOutElement:JQuery<HTMLElement>;

    private inputElement:JQuery<HTMLElement>;

    public constructor ( accessName:string, accessID:number, element:JQuery<HTMLElement>) {
        super( new ModelPasswordToggle(), accessName, accessID, element );
    }

    public initGlobals ():void{
        var svgEyeIn = findIcon( "eye_in" );
        var svgEyeOut = findIcon( "eye_out" );

        ControllerPasswordToggle.CHECKBOX_ELEMENT = jQuery( "<input class='input' type='checkbox' hidden>" );
        ControllerPasswordToggle.TOGGLE_IN_ELEMENT = jQuery( "<label class='output in'>"+ svgEyeIn +"</label>" );
        ControllerPasswordToggle.TOGGLE_OUT_ELEMENT = jQuery( "<label class='output out'>"+ svgEyeOut +"</label>" );
    }

    public run ():void{
        var inputID:string = this.getParam( "input" );
        var inputElement:JQuery<HTMLElement> = jQuery( "#" + inputID );

        if ( inputElement.length ){
            this.inputElement = inputElement;

            this.checkboxElement = ControllerPasswordToggle.CHECKBOX_ELEMENT.clone();
            this.toggleInElement = ControllerPasswordToggle.TOGGLE_IN_ELEMENT.clone();
            this.toggleOutElement = ControllerPasswordToggle.TOGGLE_OUT_ELEMENT.clone();

            this.checkboxElement.attr( "id", this.getID() + "_" + inputID );
            this.toggleInElement.attr( "for", this.getID() + "_" + inputID );
            this.toggleOutElement.attr( "for", this.getID() + "_" + inputID );

            this.getElement().append( this.checkboxElement );
            this.getElement().append( this.toggleInElement );
            this.getElement().append( this.toggleOutElement );

            Elements.showElement( this.toggleInElement );
            Elements.hideElement( this.toggleOutElement );

            this.checkboxElement.change( function (){
                if ( this.checkboxElement.is( ":checked" ) ){
                    this.inputElement.attr( "type", "text" );
                    Elements.hideElement( this.toggleInElement );
                    Elements.showElement( this.toggleOutElement );
                } else {
                    this.inputElement.attr( "type", "password" );
                    Elements.showElement( this.toggleInElement );
                    Elements.hideElement( this.toggleOutElement );
                }
            }.bind(this));
        } else {
            this.getModule().error( "Das Modul konnte nicht gestarten werden da kein Element mit folgender ID gefunden wurde: " + inputID );
        }
    }

}