import { Globals } from "./Globals";

export class ClickEvent {
  private static ATTRIBUTE: string = Globals.ATTRIBUTE_PREFIX + "on-click";

  public static init(): void {
    /**
     * Click events
     */
    var elements = jQuery("[" + ClickEvent.ATTRIBUTE + "]");
    for (var i = 0; i < elements.length; i++) {
      var element: JQuery<HTMLElement> = jQuery(elements[i]);

      element.on("click", this.setLink.bind(this, element));
    }
  }

  private static setLink(item: JQuery<HTMLElement>): void {
    var link = item.attr(ClickEvent.ATTRIBUTE);
    window.location.href = link;
  }
}