import { ControllerBasket } from "./ControllerBasket";
import { ModelBasket } from "./ModelBasket";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";
import { Module } from "../../classes/mvc/Module";

@ModuleConfig({
    moduleName: "Basket",
    style: "basket.scss"
})

export class ModuleBasket extends Module<ModelBasket> {

    public constructor ( configuration:Object ){
        super( "Basket", configuration );
        this.addController( new ControllerBasket ( this.getName(), this.getAccessID() ) );
    }

    public run (){
        this.runAllControllers(); 
    }

    public onControllersInitialized ():void {

    }

}