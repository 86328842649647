import { Model } from "./../../classes/mvc/Model";
import { AjaxRequest } from "./../../libs/AjaxRequest";

export class ModelMessageBox extends Model{

    public constructor (){
        super();
    }

    public getConfFile ( path:string ):Object{
        return AjaxRequest.getJsObject(path);
    }
    
}