import { Item } from "./../../libs/Item";

export class SliderItem extends Item{

    private content:JQuery<HTMLElement>;
    private title:string;
    private link:string;

    private keyName: string;

    public constructor ( content:JQuery<HTMLElement>, title:string, link:string, keyName:string ){
        super();

        this.content = null;
        this.title = "";
        this.link = "";

        this.keyName = "";

        if ( typeof content !== "undefined" ){ this.content = content; }
        if ( typeof title !== "undefined" ){ this.title = title; }
        if ( typeof link !== "undefined" ){ this.link = link; }

        if ( typeof keyName !== "undefined" ){ this.keyName = keyName; }

        // this.setValid( this.content.length > 0 );
    }

    public static duplicate ( sliderItem:SliderItem ):SliderItem{
        var result:SliderItem = new SliderItem( null, sliderItem.getTitle(), sliderItem.getLink(), sliderItem.getKeyName() );
        var newContent = sliderItem.getContent().clone();

        sliderItem.getContent().parent().append( newContent );
        result.setContent( newContent );

        return result;
    }

    public setContent (content:JQuery<HTMLElement>):void {
        this.content = content;
    }

    public getContent ():JQuery<HTMLElement> {
        return this.content;
    }

    public getTitle ():string {
        return this.title;
    }

    public getLink ():string {
        return this.link;
    }

    public getKeyName ():string {
        return this.keyName;
    }
}