import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";
import { ControllerFormValidator } from "./ControllerFormValidator";
import { ModelFormValidator } from "./ModelFormValidator";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";

@ModuleConfig({
    moduleName: "FormValidator",
    style: "form-validator.scss"
})
export class ModuleFormValidator extends HTMLModule<ModelFormValidator> {

    public constructor ( configuration:Object ){
        super( "FormValidator", configuration );

        this.initAll( ControllerFormValidator.prototype );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
       
    }

}